import React from 'react'
import { Payment } from '../data/models'
import * as R from 'ramda'

import Restaurant from './restaurant'
import {
  consume,
  consume2,
  provide,
  provideIf,
  toAllMoney,
  formatTime2,
  Transformer,
} from './utils'

import { createContext, useContext } from './context'

export const PaymentsCtx = createContext('PaymentsCtx')
export const PaymentsItemsCtx = createContext<Payment | null>('PaymentsItemsCtx')
export const PaymentsItemsType = createContext('PaymentsItemsType')

export default {
  Context: PaymentsCtx,
  Status: consume(['status'], PaymentsCtx),
  Iterate: provide(['payments'], PaymentsCtx, PaymentsItemsCtx),
  Last: provide(['payments'], PaymentsCtx, PaymentsItemsCtx, R.compose(R.of, R.last) as any),
  Charges: provide(
    ['payments'],
    PaymentsCtx,
    PaymentsItemsCtx,
    R.filter(R.propEq('type', 'charge')) as Transformer<Payment[]>
  ),
  LastCharge: provide(
    ['payments'],
    PaymentsCtx,
    PaymentsItemsCtx,
    (value: Payment[]): Payment[] => {
      const charges = value.filter(p => p.type == 'charge')
      return charges.length > 0 ? [charges[charges.length - 1]] : []
    }
  ),
  LastRefund: provide(
    ['payments'],
    PaymentsCtx,
    PaymentsItemsCtx,
    (value: Payment[]): Payment[] => {
      const charges = value.filter(p => p.type == 'refund')
      return charges.length > 0 ? [charges[charges.length - 1]] : []
    }
  ),
  Item: {
    Id: consume(['id'], PaymentsItemsCtx),
    Type: consume(['type'], PaymentsItemsCtx),
    Status: consume(['status'], PaymentsItemsCtx),
    Label: consume(['tenderLabel'], PaymentsItemsCtx),
    HouseAccount: consume(['account_id'], PaymentsItemsCtx),
    Note: consume(['note'], PaymentsItemsCtx),
    Amount: consume(['amount'], PaymentsItemsCtx, value => toAllMoney(Math.abs(value), null, null)),
    ExternalTransactionId: consume(['externalTransactionId'], PaymentsItemsCtx),
    ExternalAuthorizationId: consume(['externalAuthorizationId'], PaymentsItemsCtx),
    Time: consume2(['executedAt'], PaymentsItemsCtx, ['timezone'], Restaurant.Context, formatTime2),
    Card: {
      Owner: consume(['details', 'cardHolderName'], PaymentsItemsCtx),
      Digits: consume(['details', 'cardLastDigits'], PaymentsItemsCtx),
      Type: consume(['details', 'cardType'], PaymentsItemsCtx),
    },
    IsCreditCard: provideIf(
      ['tenderLabel'],
      PaymentsItemsCtx,
      PaymentsItemsType,
      ['tenderType'],
      R.compose(R.not, R.equals('CASH'))
    ),
    IsCash: (params: React.PropsWithChildren<{}>) => {
      const payment = useContext(PaymentsItemsCtx)
      if (
        payment &&
        payment.tenderType == 'CASH' &&
        (!payment.account_id || payment.account_id.trim().length == 0)
      ) {
        return <React.Fragment>{params.children}</React.Fragment>
      } else {
        return <></>
      }
    },
    IsHouseAccount: (params: React.PropsWithChildren<{}>) => {
      const payment = useContext(PaymentsItemsCtx)
      if (
        payment &&
        payment.tenderType == 'CASH' &&
        payment.account_id &&
        payment.account_id.trim().length > 0
      ) {
        return <React.Fragment>{params.children}</React.Fragment>
      } else {
        return <></>
      }
    },
  },
}
