import React, { useMemo, useState } from 'react'
import { Outlet, Link, useParams, useNavigate } from 'react-router-dom'
import { TextTemplateType } from '../models'

import useDebounce from '../../utils/use-debounce'
import { EditTemplateCategories, ShowTemplateCategories } from './categories'

interface ListingProps {
  templateTypes: TextTemplateType[]
  refresh: () => Promise<number>
  setSearch: (search: string) => void
}

export function Listing(props: ListingProps): JSX.Element {
  const navigate = useNavigate()
  const [editingCategories, setEditingCategories] = useState(false)
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 400)
  useMemo(() => props.setSearch(debouncedSearch), [debouncedSearch])
  const { type } = useParams()

  return (
    <div className='row-fluid'>
      <div
        className='span3'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 60px)',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}>
        <button className='btn btn-block' onClick={() => navigate('/newType')}>
          Create New Template Type
        </button>
        <button
          className='btn btn-primary btn-block'
          onClick={() => {
            setEditingCategories(!editingCategories)
            if (editingCategories) {
              props.refresh()
            }
          }}>
          {editingCategories ? 'Done' : 'Edit Template Categories'}
        </button>
        <div
          style={{
            position: 'sticky',
            top: '0',
            padding: '0 0 10px',
            background:
              'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 100%)',
          }}>
          <div style={{ marginTop: '10px' }}>
            <input
              className='input-block-level'
              type='text'
              placeholder='search type/name...'
              autoFocus={true}
              inputMode='search'
              onChange={e => setSearch(e.target.value)}
            />
          </div>
        </div>
        <table className=' table table-hover table-striped table-condensed'>
          <tbody>
            {props.templateTypes.map(tg => {
              return (
                <tr key={tg.type}>
                  <td>
                    <TemplateType
                      tg={tg}
                      isCurrent={type == tg.type}
                      editingCategories={editingCategories}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className='span9'>
        {!type && (
          <div className='jumbotron' style={{ backgroundColor: '#eee', padding: '30px' }}>
            <h1>Text Templates Manager</h1>
            <p>
              Our text template system organizes templates by type, facilitating efficient
              navigation and retrieval of your desired templates. Follow the instructions below to
              navigate and find templates:
            </p>
            <p>
              Using the Navigation Menu <br />
              <em>Access by Type:</em> To browse templates categorized by their types, utilize the
              navigation menu located on the left side of the interface. This menu allows you to
              directly navigate to different template types, streamlining your search process.
            </p>
            <p>
              Utilizing Search Functionality
              <br />
              <em>Search by Type or Name:</em> In addition to category-based navigation, the system
              offers a search functionality. This feature enables you to quickly find templates by
              entering either the type of the template or its specific name. The search function is
              designed to help you locate your desired template with minimal effort.
            </p>
            <p>
              By combining these navigational tools, you can easily access and manage your text
              templates, enhancing your productivity and workflow efficiency.
            </p>
            <div style={{ marginTop: '20px' }}>
              <span>Search templates: </span>
              <input
                className='input'
                type='text'
                placeholder='search text templates type/name...'
                autoFocus={true}
                inputMode='search'
                onChange={e => setSearch(e.target.value)}
              />
            </div>
          </div>
        )}
        <div style={{ marginLeft: '20px' }}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

function TemplateType(props: {
  tg: TextTemplateType
  editingCategories: boolean
  isCurrent: boolean
}): JSX.Element {
  if (props.editingCategories) {
    return (
      <>
        <Link to={props.tg.type}>
          {props.isCurrent && <i className='icon icon-arrow-right'></i>} {props.tg.type}
        </Link>
        <div>
          <EditTemplateCategories templateType={props.tg} />
        </div>
      </>
    )
  } else {
    return (
      <>
        <span className='pull-right'>
          <ShowTemplateCategories category={props.tg.category} />
        </span>
        <Link to={props.tg.type}>
          {props.isCurrent && <i className='icon icon-arrow-right'></i>} {props.tg.type}
        </Link>
      </>
    )
  }
}
