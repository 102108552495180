import { useState, useEffect } from 'react'

export default function usePersistentState<T>(
  key: string,
  initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(initialValue)

  useEffect(() => {
    const storage = localStorage.getItem(key)
    if (storage) {
      setValue(JSON.parse(storage))
    }
  }, [])

  useEffect(() => {
    if (value && value != initialValue) {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }, [value])

  return [value, setValue]
}
