import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { AuditService, AUDIT_APP_ID, AUDIT_SVC } from '../audit/svc'
import { AppPropsCtx } from '../utils/request'
import { Listing } from './listing'
import { AddNewTemplateForm, CreateTemplateTypeForm } from './listing/create'
import { EditPage } from './listing/edit'
import { ListTypeTemplates } from './listing/main'
import Migration from './migrate'
import { AppProps, TextTemplateType } from './models'
import { TextTemplateSvc, useTextTemplatesSvc } from './svc'

function App(props: AppProps): JSX.Element {
  return (
    <AppPropsCtx.Provider value={props}>
      <AuditService
        auth_token={props.auth_token}
        svc={AUDIT_SVC}
        appId={AUDIT_APP_ID}
        agent={props.agent_info}>
        <TextTemplateSvc {...props}>
          <AppImpl />
        </TextTemplateSvc>
      </AuditService>
    </AppPropsCtx.Provider>
  )
}

function AppImpl(): JSX.Element {
  const api = useTextTemplatesSvc()
  const [templateTypes, settemplateTypes] = useState<TextTemplateType[]>([])
  const [search, setSearch] = useState('')
  const [toggle, setToggle] = useState(0)

  const refresh = (): Promise<number> => {
    setToggle(toggle + 1)
    return Promise.resolve(toggle)
  }

  useEffect(() => {
    api.searchTemplateTypes(search).then(settemplateTypes)
  }, [toggle, search])

  return (
    <HashRouter>
      <Routes>
        <Route
          path=''
          element={
            <Listing templateTypes={templateTypes} refresh={refresh} setSearch={setSearch} />
          }>
          <Route path=':type' element={<ListTypeTemplates refresh={refresh} search={search} />} />
          <Route
            path=':type/new'
            element={<AddNewTemplateForm refresh={refresh} templateTypes={templateTypes} />}
          />
          <Route path='/newType' element={<CreateTemplateTypeForm refresh={refresh} />} />
        </Route>
        <Route path=':type/:id/edit' element={<EditPage refresh={refresh} />} />
        <Route path='migrate' element={<Migration />} />
      </Routes>
    </HashRouter>
  )
}

export function runApp(
  domId: string,
  svcUrl: string,
  auth_token: string,
  agent_info: string
): void {
  console.log('TextEngine Config', process.env, svcUrl)
  ReactDOM.render(<App {...{ svcUrl, auth_token, agent_info }} />, document.getElementById(domId))
}
