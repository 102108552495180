import React from 'react'
import { useAuditResourceService, LogEntry, useAuditLogs, Pagination } from './svc'
import moment from 'moment'

export function LogPanels(): JSX.Element {
  const api = useAuditResourceService()
  const { logs, pagination, nextPage, prevPage, refresh } = useAuditLogs(10)

  return (
    <div className='row-fluid'>
      {logs.map((l: LogEntry) => (
        <LogEntryPanel key={l.id} {...l} />
      ))}
      <PaginationSelector
        pagination={pagination}
        nextPage={nextPage}
        prevPage={prevPage}
        refresh={refresh}
      />
    </div>
  )
}
// import sanitizeHtml from 'sanitize-html'
import striptags from 'striptags'

function sanitizeHtml(text: string): string {
  return striptags(text, ['span', 'del', 'ins', 'br'], '')
}

function LogEntryPanel(props: LogEntry): JSX.Element {
  return (
    <div className='panel panel-info'>
      <div className='panel-heading'>
        {props.action}
        <div className='agent pull-right'>
          {moment(props.inserted_at).format('lll')} {props.agent}
        </div>
      </div>
      <div
        className='panel-body'
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(props.description.replace(/&para;/g, '')),
        }}></div>
    </div>
  )
}

export function LogTable(): JSX.Element {
  const { logs, pagination, nextPage, prevPage, refresh, error } = useAuditLogs(20)

  return (
    <>
      {error && <p className='text-error'>{error}</p>}
      <table className='table table-condensed'>
        <tbody>
          {logs.map((l: LogEntry) => (
            <LogEntryRow key={l.id} {...l} />
          ))}
        </tbody>
      </table>
      <PaginationSelector
        pagination={pagination}
        nextPage={nextPage}
        prevPage={prevPage}
        refresh={refresh}
      />
    </>
  )
}

function LogEntryRow(props: LogEntry): JSX.Element {
  return (
    <tr>
      <td>{props.action}</td>
      <td>{props.description}</td>
      <td>{moment(props.inserted_at).format('lll')}</td>
      <td>{props.agent}</td>
    </tr>
  )
}

function PaginationSelector(props: {
  pagination: Pagination
  nextPage: () => void
  prevPage: () => void
  refresh: () => void
}): JSX.Element {
  if (props.pagination.pages > 1) {
    return (
      <>
        <div className='text-info'>
          Total {props.pagination.total} records. Showing {props.pagination.per_page} per page
        </div>
        <div className='toolbar btn-group btn-toolbar'>
          <button className='btn' onClick={props.refresh}>
            Refresh
          </button>
          <button className='btn' onClick={props.prevPage}>
            Prev
          </button>
          <button className='btn'>
            {props.pagination.page} of {props.pagination.pages}
          </button>
          <button className='btn' onClick={props.nextPage}>
            Next
          </button>
        </div>
      </>
    )
  } else return <></>
}
