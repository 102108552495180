import * as R from 'ramda'

import { consume, provide, toAllMoney, formatDate } from './utils'
import { createContext, useContext } from './context'
import { Invoice, InvoiceSummary } from '../data/invoice'

export const InvoiceCtx = createContext<Invoice | null>('InvoiceCtx')
const TotalsCtx = createContext<InvoiceSummaryEntry[] | null>('InvoiceTotalsCtx')

interface InvoiceSummaryEntry extends InvoiceSummary {
  name: string
}

const summaryToArray = (summary: { [key: string]: InvoiceSummary }): InvoiceSummaryEntry[] => {
  return R.toPairs(summary).map(([key, value]: [string, InvoiceSummary]) => ({
    ...value,
    name: key,
  }))
}

const formatStatementLabel = (amount: number) =>
  amount >= 0 ? 'Amount to Receive' : 'Amount to Pay'

export default {
  Context: InvoiceCtx,
  Id: consume(['id'], InvoiceCtx),
  Transactions: {
    Orders: consume(['billables_count'], InvoiceCtx),
    Count: consume(['transactions_count'], InvoiceCtx),
  },
  StartDate: consume(['start_time'], InvoiceCtx, formatDate),
  EndDate: consume(['end_time'], InvoiceCtx, formatDate),
  Invoiceable: {
    Name: consume(['invoiceable_name'], InvoiceCtx),
    Type: consume(['invoiceable_type'], InvoiceCtx),
  },
  Status: consume(['status'], InvoiceCtx),
  FailedReason: consume(['failed_reason'], InvoiceCtx),
  Amount: {
    Label: consume(['total'], InvoiceCtx, formatStatementLabel),
    Total: consume(['total'], InvoiceCtx, toAllMoney),
    Revenue: consume(['revenue'], InvoiceCtx, toAllMoney),
    RevenueReceived: consume(['revenue_received'], InvoiceCtx, toAllMoney),
    TaxesAndFees: consume(['taxes_and_fees'], InvoiceCtx, toAllMoney),
    Services: consume(['services_sum'], InvoiceCtx, toAllMoney),
    Taxes: consume(['taxes_sum'], InvoiceCtx, toAllMoney),
    Subtotals: consume(['subtotals_sum'], InvoiceCtx, toAllMoney),
    Tips: consume(['tips_sum'], InvoiceCtx, toAllMoney),
    Totals: consume(['totals_sum'], InvoiceCtx, toAllMoney),
    Deliveries: consume(['deliveries_sum'], InvoiceCtx, toAllMoney),
  },
  BillingRules: provide(
    ['summary', 'group_by_billing_rules'],
    InvoiceCtx,
    TotalsCtx,
    summaryToArray
  ),
  Payloads: provide(['summary', 'group_by_payload_type'], InvoiceCtx, TotalsCtx, summaryToArray),
  Summary: {
    Name: consume(['name'], TotalsCtx),
    Count: consume(['count'], TotalsCtx),
    Totals: {
      Credit: consume(['totals', 'credit'], TotalsCtx, toAllMoney),
      Debit: consume(['totals', 'debit'], TotalsCtx, toAllMoney),
      Balance: consume(['totals', 'total'], TotalsCtx, toAllMoney),
    },
  },
}
