import React from 'react'
import * as R from 'ramda'
import moment, { knownTimezones } from '../utils/zuppler-moment'
import { createContext, useContext } from './context'

// import url from 'url'
import querystring from 'querystring'

export const templateCtx = createContext('TemplateCtx')
export function useTemplateFormat() {
  const templateInfo = useContext(templateCtx)
  return templateInfo
}

export const consume = (path, fromCtx, withFormatter) => params => {
  const templateFormat = useTemplateFormat()
  const data = useContext(fromCtx)
  const value = R.path(path, data)
  const formatter = withFormatter || R.identity
  const display = R.isNil(value) ? '' : formatter(value, params)
  if (!!params.children && typeof params.children === 'function') {
    return !!display ? params.children(display) : null
  }
  return templateFormat == 'thermal' ? escapeThermal(display) : display
}

export const consumeAsTag = (path, fromCtx, withFormatter) => params => {
  const templateFormat = useTemplateFormat()
  const data = useContext(fromCtx)
  const value = R.path(path, data)
  const formatter = withFormatter || R.identity
  const display = value ? formatter(value, params, data) : ''
  if (!!params.children && typeof params.children === 'function') {
    return !!display ? params.children(display) : null
  }
  return display
}

export const consume2 = (path, fromCtx, path2, addCtx, withFormatter2) => params => {
  const data = useContext(fromCtx)
  const data2 = useContext(addCtx)
  const templateFormat = useTemplateFormat()
  const value = R.path(path, data)
  const value2 = R.path(path2, data2)
  const formatter = withFormatter2 || R.identity
  const display = R.isNil(value) ? '' : formatter(value, value2, params, data, data2)
  if (!!params.children && typeof params.children === 'function') {
    return !!display ? params.children(display) : null
  }
  return templateFormat == 'thermal' ? escapeThermal(display) : display
}

export const consume3 = (
  path,
  fromCtx,
  path2,
  addCtx2,
  path3,
  addCtx3,
  withFormatter2
) => params => {
  const data = useContext(fromCtx)
  const data2 = useContext(addCtx2)
  const data3 = useContext(addCtx3)
  const templateFormat = useTemplateFormat()
  const value = R.path(path, data)
  const value2 = R.path(path2, data2)
  const value3 = R.path(path3, data3)
  const formatter = withFormatter2 || R.identity
  const display = R.isNil(value) ? '' : formatter(value, value2, value3, params, data, data2, data3)
  if (!!params.children && typeof params.children === 'function') {
    return !!display ? params.children(display) : null
  }
  return templateFormat == 'thermal' ? escapeThermal(display) : display
}
function currentSeparator(n, total, sep0, sepN) {
  if (n == total - 2) {
    return sepN
  } else if (n < total - 2) {
    return sep0
  } else {
    return <></>
  }
}

export const provide = (path, fromCtx, toCtx, dataTransformer) => ({
  separator,
  separatorLast,
  children,
}) => {
  const data = useContext(fromCtx)
  const value = R.path(path, data)
  if (!R.isNil(value)) {
    const transformedValue = (dataTransformer || R.identity)(value)
    let content = transformedValue

    let sep0 = separator || <></>
    let sepN = separatorLast || separator || <></>

    if (R.is(Array, transformedValue)) {
      content = transformedValue.map((v, i, arry) => {
        return (
          <toCtx.Provider key={i} value={v}>
            {children}
            {currentSeparator(i, arry.length, sep0, sepN)}
          </toCtx.Provider>
        )
      })
    }
    return content
  } else {
    return <></>
  }
}

export const provideIf = (path, fromCtx, toCtx, condPath, testFn, dataTransformer) => ({
  children,
}) => {
  const data = useContext(fromCtx)
  const condData = R.path(condPath, data)
  const condValue = condData ? (dataTransformer || R.identity)(condData) : null
  if (testFn(condValue)) {
    const value = R.path(path, data)
    return <toCtx.Provider value={value}>{children}</toCtx.Provider>
  } else {
    return <></>
  }
}

export const provideFromJSON = (path, fromCtx, toCtx, dataTransformer = R.identity) => ({
  children,
}) => {
  const data = useContext(fromCtx)
  const value = R.path(path, data)
  if (!R.isNil(value)) {
    const transformedValue = (dataTransformer || R.identity)(JSON.parse(value))
    let content = transformedValue
    if (R.is(Array, transformedValue)) {
      content = transformedValue.map((v, i) => {
        return (
          <toCtx.Provider key={i} value={v}>
            {children}
          </toCtx.Provider>
        )
      })
    }
    return content
  } else {
    return <></>
  }
}
export const formatTime = value => {
  const format = 'LLL'
  return moment(value).format(format)
  // return strftime(params && params.format ? params.format : '%c', new Date(v))
}

export const formatTime2 = (value, { name, offset }, params) => {
  const { format = 'LLL' } = params
  if (knownTimezones[name]) {
    return moment(value)
      .tz(name)
      .format(format)
  } else {
    return moment(value)
      .utcOffset(offset)
      .format(format)
  }
  // return strftime(params && params.format ? params.format : '%c', new Date(v))
}

export const formatDate = (value, params) => {
  const { format = 'LL' } = params
  return moment(value).format(format)
}

export const formatCardInfo = value =>
  value && value.type && value.card_last_digits
    ? `${value.type} ending ${value.card_last_digits}`
    : ''

const buildGroups = (sum, [id, { group, label, value, priority, name }]) => [
  ...sum,
  { id, group, name, label, priority, value },
]
const buildFields = (sum, [group, fields]) => [
  ...sum,
  { group, fields: R.sortBy(R.prop('priority'), fields) },
]
const sortGroups = ([_group, fields]) => R.head(fields).priority

export const toExtraFields = R.compose(
  R.reduce(buildFields, []),
  R.sortBy(sortGroups),
  R.toPairs,
  R.groupBy(R.prop('group')),
  R.reduce(buildGroups, []),
  R.toPairs,
  JSON.parse.bind(JSON)
)

export const toImage = (value, params) => {
  const src = typeof value === 'string' ? value : value[params.size || 'medium']
  return <img src={src} {...params} />
}
export const toMoney = (value, params) =>
  value ? `${params.refund ? '-' : ''}$${((value + (params.adjust || 0)) / 100).toFixed(2)}` : ''
export const toCount = (value, _params) => (value && value.length ? String(value.length) : '0')
export const toItemQuantity = (value, _params) =>
  value && value.length ? value.map(i => i.quantity).reduce((a, b) => a + b, 0) : 0
export const toAllMoney = value => `$${(value / 100).toFixed(2)}`
export const toShortId = value => R.head(value.split(/-/))
export const toCeil = value => Math.ceil(value / 100)
export const toLink = (value, params, _data) => (
  <a href={value} {...R.omit('children', params)}>
    {params.children || <></>}
  </a>
)

export function escapeThermal(s) {
  return s.replaceAll(/([\[\]\\])/g, '\\$1')
}

export function addParamsToUrl(existingUrl, params) {
  if (!existingUrl) {
    console.error('addParamsToUrl invalid url', existingUrl, params)
    return existingUrl
  }
  const parsedUrl = new URL(existingUrl)
  const parsedQuery = querystring.parse(parsedUrl.search.substr(1))

  // Encode new parameters and merge them with existing ones
  Object.keys(params)
    .filter(key => !!params[key])
    .forEach(key => {
      // parsedQuery[key] = encodeURIComponent(params[key])
      parsedQuery[key] = params[key] // Prevent double encoding for params?
    })

  // Convert the query object back to a string
  const newQueryString = querystring.stringify(parsedQuery)

  // Reconstruct the URL with the new query string
  parsedUrl.search = newQueryString

  return parsedUrl.toString()
}
