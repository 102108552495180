import restaurantDetails from './restaurant-info'
import { restaurantsApiGqlCall } from './utils'

export const loadRestaurantByID = restaurant_id =>
  restaurantsApiGqlCall('restaurant', restaurantDetails, { restaurant_id: Number(restaurant_id) })

export async function loadRestaurantFromRequest(req, _deps) {
  const restaurant_id = req.body['restaurant_id']
  if (restaurant_id) {
    // console.info(`Loading restaurant ${restaurant_id}...`)
    req.templateParams.restaurant = await loadRestaurantByID(restaurant_id)
    // console.info(`...loaded restaurant ${req.templateParams.restaurant.name}`)
    return req.templateParams.restaurant
  }
}

export default (req, res, next) => {
  const restaurant_id = req.body['restaurant_id']
  if (restaurant_id) {
    console.info(`Loading restaurant ${restaurant_id}...`)
    loadRestaurantByID(restaurant_id)
      .then(data => {
        console.info(`...loaded restaurant ${data.name}`)
        req.templateParams.restaurant = data
        next()
      })
      .catch(error => {
        console.error(`Load restaurant ${restaurant_id} error`, error)
        next(JSON.stringify(error, null, 2))
      })
  } else {
    next()
  }
}
