export type Maybe<T> = T | null

export type ReportOp = 'in.date' | 'in' | 'between'
export enum ReportType {
  order = 'order',
  order_items = 'order_items',
  loyalty_order = 'loyalty_order',
  loyalty_review = 'loyalty_review',
  loyalty_redeem = 'loyalty_redeem',
  discount_commits = 'discount_commits',
  discounts2_promos_generation = 'discounts2_promos_generation',
  discounts2_promos_commits = 'discounts2_promos_commits',
  feedback_session = 'feedback_session',
  feedback_rating = 'feedback_rating',
  cart_closed = 'cart_closed',
  cart_checkout = 'cart_checkout',
  cart_ordering = 'cart_ordering',
  cart_created = 'cart_created',
  email_event = 'email_event',
  growth_services_charges = 'growth_services_charges',
  text_engine = 'text_engine',
}

interface ReportDataPayload {
  limit?: number
  filters: { key: string; op: ReportOp; value: any[] }[]
  sort?: {
    dir: 'asc' | 'desc'
    key: string
  }[]
}

export function getReport<RT>(
  report: ReportType,
  bucket: string,
  payload: ReportDataPayload
): Promise<RT[]> {
  return fetch(`${process.env.REPORTS_SVC}/rquery/${report}/${bucket}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Zuppler-Authorization': `${process.env.REPORTS_ACCESS_TOKEN}`,
    },
    body: JSON.stringify(payload),
  }).then(res => res.json())
}
