module.exports = `menus(restaurantId: $restaurant_id) {
  id
  name
  group
  image { active thumb medium }
  categories {
    items {
      id
      image { active thumb medium }
    }
  }
}`
