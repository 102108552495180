import { Channel, ThemeColors } from './models'

const defTheme = {
  'colors-background': '#ffffff',
  'colors-highContrast': '#000000',
  'colors-midContrast': '#333333',
  'colors-lowContrast': '#999999',
  'colors-brand': '#0070e1',
  'colors-heroBackground': '#9e5375',
  'colors-heroContrast': '#FFFFFF',
  'fonts-heading': 'Open Sans Condensed, helvetica, sans-serif',
  'fonts-body': 'Raleway, helvetica, sans-serif',
  'fonts-deco': 'Montserrat, helvetica, sans-serif',
}

export const themeFromChannelConfig = (channel: Channel): ThemeColors => {
  const web5Settings = JSON.parse(channel.settings_json) || defTheme

  const colors = [
    'background',
    'highContrast',
    'midContrast',
    'lowContrast',
    'brand',
    'heroBackground',
    'heroContrast',
  ].reduce((c: any, colorId: string) => {
    if (web5Settings[`colors-${colorId}`]) {
      c[colorId] = web5Settings[`colors-${colorId}`]
    }
    return c
  }, {})
  const fonts: any = {}
  if (web5Settings['fonts-heading']) {
    fonts.title = web5Settings['fonts-heading']
  }
  if (web5Settings['fonts-body']) {
    fonts.body = web5Settings['fonts-body']
  }
  if (web5Settings['fonts-deco']) {
    fonts.deco = web5Settings['fonts-deco']
  }

  return { colors, fonts }
}

