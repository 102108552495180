import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { Box, Text, Heading } from './primitives'
import { DynamicTheme } from './default-theme'
import { space, color, layout, border, typography } from 'styled-system'
import Params from '../params'
import { templateCtx } from '../../text/utils'
import { useContext } from '../../text/context'

const Container = props => <Box bg='background' color='highContrast' p={2} {...props} />

const LargeContainer = props => (
  <Box bg='background' color='highContrast' px={3} py={5} {...props} />
)

const Block = props => <Box maxWidth={5} mx='auto' {...props} />

const Template = ({ format, colors, fonts, children, ...rest }) => {
  const params = useContext(Params.Context)
  if (format && params.onConfig) params.onConfig({ format })

  if (format === 'print' || format === 'thermal' || !!colors || !!fonts) {
    return (
      <DynamicTheme colors={colors} fonts={fonts} print={format === 'print'}>
        <templateCtx.Provider value={format || 'html'}>{children}</templateCtx.Provider>
      </DynamicTheme>
    )
  }
  return <templateCtx.Provider value={format || 'html'}>{children}</templateCtx.Provider>
}

const Link = styled.a`
  ${color}
  ${space}
  ${typography}
  ${border}
`
Link.defaultProps = {
  color: 'brand',
}

const Button = styled(Link)`
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
`
Button.defaultProps = {
  px: 3,
  py: 2,
  fontFamily: 'body',
  bg: 'highContrast',
  color: 'background',
  borderRadius: 2,
  m: 2,
}

const ButtonImportant = props => <Button bg='brand' color='background' {...props} />

const toComma = () => <p>, </p>
const CommaSeparated = props => {
  const children = React.Children.toArray(props.children)
  return R.intersperse(toComma, children)
}

export {
  Template,
  LargeContainer,
  Container,
  Link,
  Block,
  Button,
  ButtonImportant,
  Box,
  CommaSeparated,
}
