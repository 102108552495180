import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { getReport, ReportType } from '../reports/service'

export interface ReportingAPI {
  hasReports: () => boolean
  getTemplateData: (templateName: string) => TextEngineByTemplateTypeDetailsMonth[]
  getSendgridData: () => EmailEventSendgridStatisticsOverallMonth[]
  getRenderData: () => TextEngineByTemplateTypeMonth[]
  getSalesData: () => OrderCampaignTotalsMonth[]
}

export interface EmailEventSendgridStatisticsOverallMonth {
  month: string
  type: string
  event: 'delivered' | 'open' | 'processed'
  count: number
}

export interface TextEngineByTemplateTypeMonth {
  month: string
  template_type: string
  count: number
  success: number
  failure: number
}
export interface TextEngineByTemplateTypeDetailsMonth {
  month: string
  template_type: string
  template_name: string
  count: number
  success: number
  failure: number
}

export interface OrderCampaignTotalsMonth {
  month: string
  campaign_id: string
  count: number
  total: number
  subtotal: number
}

export function useReporting(type?: string): ReportingAPI {
  const [emailEvents, setEmailEvents] = useState<EmailEventSendgridStatisticsOverallMonth[]>([])
  const [renders, setRenders] = useState<TextEngineByTemplateTypeMonth[]>([])
  const [rendersIndividual, setRendersIndividual] = useState<
    TextEngineByTemplateTypeDetailsMonth[]
  >([])
  const [sales, setSales] = useState<OrderCampaignTotalsMonth[]>([])

  useEffect(() => {
    if (type) {
      getTemplateTypeReport(type).then(([a, b, c, d]) => {
        setEmailEvents(a)
        setRenders(b)
        setRendersIndividual(c)
        setSales(d)
      })
    }
  }, [type])

  const individualRendersCache: {
    [key: string]: TextEngineByTemplateTypeDetailsMonth[]
  } = useMemo(() => {
    const cache = rendersIndividual.reduce(
      (
        s: { [key: string]: TextEngineByTemplateTypeDetailsMonth[] },
        e: TextEngineByTemplateTypeDetailsMonth
      ) => {
        if (!s[e.template_name]) {
          s[e.template_name] = []
        }
        s[e.template_name].push(e)
        return s
      },
      {}
    )
    return Object.fromEntries(
      Object.entries(cache).map(([k, s]) => [
        k,
        s.sort((a, b) => moment(a.month).diff(moment(b.month), 'month')),
      ])
    )
  }, [rendersIndividual])

  return {
    hasReports: () => emailEvents.length + renders.length + sales.length > 0,
    getTemplateData: (templateName: string) => individualRendersCache[templateName] || [],
    getSendgridData: () => emailEvents,
    getRenderData: () => renders,
    getSalesData: () => sales,
  }
}

export function TemplateReport(props: { reporting: ReportingAPI; name: string }): JSX.Element {
  return (
    <div style={{ paddingTop: '.5ex', paddingLeft: '1ex' }} title='Template usage report'>
      {props.reporting.getTemplateData(props.name).map(data => (
        <div key={data.month}>
          {moment(data.month).format('MMMM YYYY')}:{' '}
          <span className='badge badge-info' title='Number of template render requests'>
            {data.count}
          </span>{' '}
          /
          <span
            className='badge badge-success'
            title='Number of template render success completions'>
            {data.success}
          </span>{' '}
          /
          <span className='badge badge-warning' title='Number of template render failures'>
            {data.failure}
          </span>
        </div>
      ))}
    </div>
  )
}

export function TextEngineRenderings(props: { reporting: ReportingAPI }): JSX.Element {
  if (props.reporting.getRenderData().length > 0) {
    return (
      <>
        <div className='row-fluid'>
          <div className='span12'>
            <h5>Text Engine Renderings</h5>
          </div>
        </div>
        <div className='row-fluid'>
          <table className='table table-striped table-condensed'>
            {props.reporting.getRenderData().map(data => (
              <tr key={data.month}>
                <td>{moment(data.month).format('MMMM YYYY')}</td>
                <td className='text-info'>{data.count}</td>
                <td className='text-success'>{data.success}</td>
                <td className='text-error'>{data.failure}</td>
              </tr>
            ))}
          </table>
        </div>
      </>
    )
  } else {
    return <></>
  }
}

export function SendgridTemplateReporting(props: { reporting: ReportingAPI }): JSX.Element {
  if (props.reporting.getSendgridData().length > 0) {
    return (
      <>
        <div className='row-fluid'>
          <div className='span12'>
            <h5>Sendgrid Delivery Report</h5>
          </div>
        </div>
        <div className='row-fluid'>
          <table className='table table-striped table-condensed'>
            {props.reporting.getSendgridData().map(data => (
              <tr key={data.month}>
                <td>{moment(data.month).format('MMMM YYYY')}</td>
                <td>{data.count}</td>
              </tr>
            ))}
          </table>
        </div>
      </>
    )
  } else {
    return <></>
  }
}

export function CampaignReporting(props: { reporting: ReportingAPI }): JSX.Element {
  if (props.reporting.getSalesData().length) {
    return (
      <>
        <div className='row-fluid'>
          <div className='span12'>
            <h5>Template Conversions</h5>
          </div>
        </div>
        <div className='row-fluid'>
          <table className='table table-striped table-condensed'>
            <tr>
              <th>Month</th>
              <th>Orders</th>
              <th>Subtotal</th>
              <th>Total</th>
            </tr>
            {props.reporting.getSalesData().map(data => (
              <tr key={data.month}>
                <td>{moment(data.month).format('MMMM YYYY')}</td>
                <td>{data.count}</td>
                <td>{Math.round(data.subtotal / 100)}</td>
                <td>{Math.round(data.total / 100)}</td>
              </tr>
            ))}
          </table>
        </div>
      </>
    )
  } else {
    return <></>
  }
}

function lastMonths(count = 3) {
  return [count, -1].map(offset => {
    return moment()
      .utc()
      .startOf('month')
      .subtract(offset, 'month')
      .format('YYYY-MM-DD')
  })
}

export async function getTemplateTypeReport(
  templateType: string
): Promise<
  [
    EmailEventSendgridStatisticsOverallMonth[],
    TextEngineByTemplateTypeMonth[],
    TextEngineByTemplateTypeDetailsMonth[],
    OrderCampaignTotalsMonth[]
  ]
> {
  return await Promise.all([
    getReport<EmailEventSendgridStatisticsOverallMonth>(
      ReportType.email_event,
      'sendgrid_statistics_overall',
      {
        filters: [
          { key: 'month', op: 'between', value: lastMonths() },
          { key: 'type', op: 'in', value: [templateType] },
          { key: 'event', op: 'in', value: ['delivered'] },
        ],
        sort: [{ dir: 'asc', key: 'month' }],
        limit: 10,
      }
    ),
    getReport<TextEngineByTemplateTypeMonth>(ReportType.text_engine, 'text_engine_by_template', {
      filters: [
        { key: 'month', op: 'between', value: lastMonths() },
        { key: 'template_type', op: 'in', value: [templateType] },
      ],
      sort: [{ dir: 'asc', key: 'month' }],
      limit: 10,
    }),
    getReport<TextEngineByTemplateTypeDetailsMonth>(
      ReportType.text_engine,
      'text_engine_by_template_details',
      {
        filters: [
          { key: 'month', op: 'between', value: lastMonths(1) },
          { key: 'template_type', op: 'in', value: [templateType] },
        ],
        sort: [{ dir: 'asc', key: 'month' }],
        limit: 10,
      }
    ),
    getReport<OrderCampaignTotalsMonth>(ReportType.order, 'campaign_totals', {
      filters: [
        { key: 'month', op: 'between', value: lastMonths() },
        { key: 'campaign', op: 'in', value: [templateType] },
      ],
      sort: [{ dir: 'asc', key: 'month' }],
      limit: 10,
    }),
  ])
}
