import { consume, consumeAsTag, provide, toImage } from '../text/utils'
import { createContext, useContext } from './context'
import { toThermalImage } from './ui/thermal'

const RestaurantCtx = createContext('RestaurantCtx')
const ServiceCtx = createContext('ServiceCtx')

export default {
  Context: RestaurantCtx,
  Name: consume(['name'], RestaurantCtx),
  Logo: consume(['logo'], RestaurantCtx, toImage),
  ThermalLogo: consumeAsTag(['logo'], RestaurantCtx, toThermalImage),
  Services: provide(['services'], RestaurantCtx, ServiceCtx),
  Service: {
    Id: consume(['id'], ServiceCtx),
    Phone: consume(['contact', 'phone'], ServiceCtx),
  },
  Workflow: {
    Id: consume(['workflow_uuid'], RestaurantCtx),
  },
}
