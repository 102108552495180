import * as Theme from './text/ui/default-theme'
import * as Grid from './text/ui/grid'
import * as Primitives from './text/ui/primitives'
import * as Molecules from './text/ui/molecules'
import * as Macros from './text/ui/macros'
import * as OrderComponents from './text/order'
import * as PaymentComponents from './text/payments'
import * as RestaurantComponents from './text/restaurant'
import * as MenusComponents from './text/menus'
import * as InvoiceComponents from './text/invoice'
import * as LoyaltyComponents from './text/loyalty'
import * as CampaignComponents from './text/campaign'
import * as Th from './text/ui/thermal'
import * as ReportsComponents from './text/reports'

export default function moduleResolver(id) {
  switch (id) {
    case 'react':
      return React
    case 'theme':
      return Theme
    case 'primitives':
      return Primitives
    case 'thermal':
      return Th
    case 'molecules':
      return Molecules
    case 'grid':
      return Grid
    case 'macros':
      return Macros
    case 'order':
      return OrderComponents
    case 'payments':
      return PaymentComponents
    case 'restaurant':
      return RestaurantComponents
    case 'menus':
      return MenusComponents
    case 'invoice':
      return InvoiceComponents
    case 'loyalty':
      return LoyaltyComponents
    case 'reports':
      return ReportsComponents
    case 'campaign':
      return CampaignComponents
    default:
      return null
  }
}
