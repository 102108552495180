import { consume, toImage } from './utils'
import { createContext, useContext } from './context'

export const ChannelCtx = createContext('ChannelCtx')

export default {
  Context: ChannelCtx,
  Name: consume(['name'], ChannelCtx),
  Url: consume(['url'], ChannelCtx),
  Permalink: consume(['permalink'], ChannelCtx),
  Contact: {
    Name: consume(['contact', 'name'], ChannelCtx),
    Email: consume(['contact', 'email'], ChannelCtx),
    Phone: consume(['contact', 'phone'], ChannelCtx),
  },
  Logo: consume(['logo'], ChannelCtx, toImage),
}
