import React from 'react'
import * as R from 'ramda'

import { consume, consume2, provideIf, toMoney, formatDate, toAllMoney } from './utils'

import { createContext, useContext } from './context'

export interface Reports {
  last: ReportsData
  current: ReportsData
}

export interface ReportsData {
  order: {
    month: string
    restaurant: number
    count: number
    total: number
    new_users: number
    registered_users: number
  }
  discounts: any
  loyalty_redeem: any
  loyalty_order: any
}

const Ctx = createContext<Reports | null>('ReportsCtx')
const TrendsCtx = createContext<any>('ReportsTrendsCtx')
const ConditionCtx = createContext('ReportsConditionCtx')

const HasTrends: React.FC = ({ children }) => {
  const data = useContext<Reports | null>(Ctx)

  if (!(data && data.current.order && data.last.order)) return null

  const countDiff = data.current.order.count - data.last.order.count
  const totalDiff = data.current.order.total - data.last.order.total

  const _trends = {
    count: {
      value: Math.abs(countDiff),
      trend: countDiff >= 0 ? 'positive' : 'negative',
    },
    total: {
      value: Math.abs(totalDiff),
      trend: totalDiff >= 0 ? 'positive' : 'negative',
    },
  }
  return <TrendsCtx.ctx.Provider value={_trends}>{children}</TrendsCtx.ctx.Provider>
}

const showReport = (data: Reports): boolean => {
  if (!data) {
    return false
  }

  // We only show the reports if we have orders for a given month.
  return !!data.current.order
}

const cartSectionActive = (report: any) => {
  if (report) {
    return !!(
      report.carts_created ||
      report.carts_ordering ||
      report.carts_checkout ||
      report.carts_closed
    )
  }
  return false
}
const report = (month: string) => {
  return {
    Order: {
      Active: provideIf(
        [month, 'order'],
        Ctx,
        ConditionCtx,
        [month, 'order'],
        R.compose(R.not, R.isNil)
      ),
      Count: consume([month, 'order', 'count'], Ctx),
      Total: consume([month, 'order', 'total'], Ctx, toMoney),
      NewUsers: consume([month, 'order', 'new_users'], Ctx),
      RegisteredUsers: consume([month, 'order', 'registered_users'], Ctx),
      Month: consume([month, 'order', 'month'], Ctx, formatDate),
      Average: consume2(
        [month, 'order', 'total'],
        Ctx,
        [month, 'order', 'count'],
        Ctx,
        R.compose(toAllMoney as () => string, R.divide)
      ),
    },
    Carts: {
      Active: provideIf([month, 'carts'], Ctx, ConditionCtx, [month], cartSectionActive),
      Created: consume([month, 'carts_created', 'count'], Ctx),
      Ordering: consume([month, 'carts_ordering', 'count'], Ctx),
      Checkout: consume([month, 'carts_checkout', 'count'], Ctx),
      Closed: consume([month, 'carts_closed', 'count'], Ctx),
    },
    Discounts: {
      Active: provideIf(
        [month, 'discounts'],
        Ctx,
        ConditionCtx,
        [month, 'discounts'],
        R.compose(R.not, R.isNil)
      ),
      Count: consume([month, 'discounts', 'count'], Ctx),
      Total: consume([month, 'discounts', 'discount_total'], Ctx, toMoney),
      OrderTotal: consume([month, 'discounts', 'order_total'], Ctx, toMoney),
    },
    LoyaltyOrder: {
      Active: provideIf(
        [month, 'loyalty_order'],
        Ctx,
        ConditionCtx,
        [month, 'loyalty_order'],
        R.compose(R.not, R.isNil)
      ),
      Count: consume([month, 'loyalty_order', 'count'], Ctx),
      Points: consume([month, 'loyalty_order', 'points'], Ctx),
      Total: consume([month, 'loyalty_order', 'total'], Ctx, toMoney),
    },
    LoyaltyRedeem: {
      Active: provideIf(
        [month, 'loyalty_redeem'],
        Ctx,
        ConditionCtx,
        [month, 'loyalty_redeem'],
        R.compose(R.not, R.isNil)
      ),
      Count: consume([month, 'loyalty_redeem', 'count'], Ctx),
      Points: consume([month, 'loyalty_redeem', 'points'], Ctx),
      Total: consume([month, 'loyalty_redeem', 'total'], Ctx, toMoney),
      OrderTotal: consume([month, 'loyalty_redeem', 'order_total'], Ctx, toMoney),
    },
    Reviews: {
      Count: consume([month, 'reviews'], Ctx),
    },
  }
}

export default {
  Context: Ctx,
  Last: report('last'),
  Current: report('current'),
  Report: {
    Available: provideIf([], Ctx, ConditionCtx, [], showReport),
    NotAvailable: provideIf([], Ctx, ConditionCtx, [], (data: Reports) => {
      return !showReport(data)
    }),
  },
  HasTrends,
  Trends: {
    Count: {
      Value: consume(['count', 'value'], TrendsCtx),
      IsPositive: provideIf([], TrendsCtx, ConditionCtx, ['count', 'trend'], R.equals('positive')),
      IsNegative: provideIf([], TrendsCtx, ConditionCtx, ['count', 'trend'], R.equals('negative')),
    },
    Total: {
      Value: consume(['total', 'value'], TrendsCtx, toMoney),
      IsPositive: provideIf([], TrendsCtx, ConditionCtx, ['total', 'trend'], R.equals('positive')),
      IsNegative: provideIf([], TrendsCtx, ConditionCtx, ['total', 'trend'], R.equals('negative')),
    },
  },
}
