module.exports = `restaurant(id: $restaurant_id) {
  id
  name
  logo { medium }
  services { id contact { phone } }
  timezone { name offset}
  workflow_uuid
  workflow {
    id
    name
    author
    description
    uuid
    content {
      nodes {
        id
        name
        description
      }
    }
  }
}`
