module.exports = {
  byID: `
  channel(id: $channel_id) {
    id
    name
    permalink
    url
    logo {
      medium
      thumb
    }
    contact {
      name
      email
      phone
    }
    menuMarkupHidden
    settings_json(channelType: $settingsType)
  }
  `,
  byIDAndIntegration: `
  channel(id: $channel_id) {
    id
    name
    permalink
    url
    logo {
      medium
      thumb
    }
    contact {
      name
      email
      phone
    }
    menuMarkupHidden
    integrations(id: $integration_id) {
      restaurant_id
      restaurant_location_id
      remote_id
      settings
    }
    settings_json(channelType: $settingsType)
  }
  `,
}
