import React from 'react'
import styled from 'styled-components'
import { space, typography, border, color, layout } from 'styled-system'
import { alignment, shortColors } from './utils'

const unit = s => (typeof s === 'number' ? `${s}px` : s)

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  ${typography}
`
Table.defaultProps = {
  fontFamily: 'body',
  lineHeight: 'copy',
}
const Grid = ({ children, ...rest }) => (
  <Table {...rest}>
    <tbody>{children}</tbody>
  </Table>
)

const Row = ({ children, sizes = [] }) => {
  let i = 0
  const content = () => {
    return React.Children.map(children, child =>
      React.cloneElement(child, {
        _size: sizes[i++],
      })
    )
  }
  return <tr>{content()}</tr>
}

const Cell = styled.td.attrs(({ span = 1 }) => ({
  colSpan: span,
}))`
	${({ _size }) => _size && `width: ${unit(_size)};`}
	${border}
	${space}
	${color}
	${typography}
	${alignment}
	${layout}
	${shortColors}
`

export { Grid, Row, Cell }
