import menuDetails from './menus-info'
import { menusApiGqlCall } from './utils'

export const loadMenusByRestaurantID = restaurant_id =>
  menusApiGqlCall('GetRestaurantMenus', menuDetails, { restaurant_id })

export async function loadMenusFromRequest(req, _deps) {
  const restaurant_id = req.body['restaurant_id']
  if (restaurant_id) {
    // console.info(`Loading restaurant menus ${restaurant_id}...`)
    req.templateParams.menus = await loadMenusByRestaurantID(restaurant_id)
    // console.info(`...loaded restaurant menus`)
    return req.templateParams.menus
  }
}

export default (req, _res, next) => {
  const restaurant_id = req.body['restaurant_id']
  if (restaurant_id) {
    console.info(`Loading restaurant menus ${restaurant_id}...`)
    loadMenusByRestaurantID(restaurant_id)
      .then(data => {
        console.info(`...loaded restaurant menus`)
        req.templateParams.menus = data
        next()
      })
      .catch(error => {
        console.error(`Load restaurant menus ${restaurant_id} error`, error)
        next(JSON.stringify(error, null, 2))
      })
  } else {
    next()
  }
}
