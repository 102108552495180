export const theme = {
  colors: {
    background: "#fff",
    highContrast: "#000",
    midContrast: "#555",
    lowContrast: "#999",
    brand: "#0070e1",
    brandContrast: "#fff"
  },
  fonts: {
    body: `'Helvetica Neue', Helvetica, Arial, sans-serif`,
    title: `'Helvetica Neue', Helvetica, Arial, sans-serif`,
  },
  fontSizes: ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '36px'],
  fontWeights: [300, 400, 700, 900],
  lineHeights: {
    solid: '1em',
    title: '1.1em',
    copy: '1.25em',
  },
  breakpoints: [32, 48, 64],
  space: [0, '4px', '8px', '16px', '32px', '64px', '128px', '256px', '512px'],
  borders: [0, '1px solid', '2px solid', '4px solid', '8px solid'],
  radii: [0, '3px', '5px', '8px'],
  sizes: ['16px', '32px', '64px', '128px', '256px', '512px', '768px', '1024px', '1536px'],
}

export const printColors = {
  background: "#fff",
  highContrast: "#000",
  midContrast: "#555",
  lowContrast: "#999",
  brand: "#333"
}