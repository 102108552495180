import { space, color, layout, border, typography, background } from 'styled-system'
import styled from 'styled-components'
import { alignment, shortColors } from './utils'

export const Box = styled.div`
  ${color}
  ${space}
  ${layout}
  ${border}
  ${alignment}
  ${typography}
  ${background}
`

export const Text = styled.p`
  ${typography}
  ${color}
  ${space}
  ${layout}
  ${alignment}
`
Text.defaultProps = {
  m: 0,
  // fontSize: 3,
  lineHeight: 'copy'
}

export const Span = styled.span`
  ${typography}
  ${color}
  ${shortColors}
`

export const Heading = styled.p`
  ${typography}
  ${color}
  ${space}
  ${layout}
  ${alignment}
`
Heading.defaultProps = {
  p: 0,
  m: 0,
  fontFamily: 'title',
  fontSize: 5,
  fontWeight: 3,
}
