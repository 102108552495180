import fetch from 'node-fetch'
export function loadLoyaltyBalance(url) {
  return fetch(url, { method: 'GET', headers: { 'content-type': 'application/json' } }).then(res =>
    res.ok ? res.json() : Promise.resolve(null)
  )
}

export function getLoyaltyBalanceUrl(customerId, restaurantId, channelId) {
  const restaurantQueryParam = restaurantId ? `&restaurant_id=${restaurantId}` : ''
  return `${process.env.CARTS_SVC}/v5/customers/${customerId}/loyalty/balance?channel_id=${channelId}&${restaurantQueryParam}`
}
