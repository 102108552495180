import { paymentsApiGqlCall } from './utils'

export const loadOrderByID = order_uuid =>
  paymentsApiGqlCall('order_payments', query, { order_uuid })

export default (req, res, next) => {
  const orderUUID = req.body['order_uuid']
  if (orderUUID) {
    console.info(`Loading payments order ${orderUUID}...`)
    loadOrderByID(orderUUID)
      .then(paymentsOrder => {
        console.info(`...loaded payments order ${paymentsOrder.id} ${paymentsOrder.status}`)
        req.templateParams.payments = paymentsOrder
        next()
      })
      .catch(error => {
        console.error(`Load payments order error for ${orderUUID}`, error)
        next(JSON.stringify(error, null, 2))
      })
  } else {
    next()
  }
}

const query = `order(id: $order_uuid) {
  id
  status
  payments {
    id
    type
    customerName
    tenderId          
    tenderType      
    tenderLabel
    amount  
    account_id
    note
    errorMessage
    details {
      cardHolderName
      cardLastDigits
      cardType
    }
    externalTransactionId
    executedAt
    parentPaymentId
    status
  }
}`
