import React from 'react'
import * as R from 'ramda'
import { ThemeProvider } from 'styled-components'
import { theme as baseTheme, printColors } from './theme'
import { colorAtDepth } from './utils'

// const theme = {
//   ...baseTheme,
//   colors: {
//     background: '#212121',
//     highContrast: '#ffe5b4',
//     midContrast: '#a68f6f',
//     lowContrast: '#615a51',
//     brand: '#ff9103',
//   },
// }

export const Theme = ({ channelTheme, children }) => {
  let t = R.mergeDeepRight(baseTheme, channelTheme || {})
  let c = t.colors
  c.background1 = colorAtDepth(c.background, -1)
  c.background2 = colorAtDepth(c.background, -2)
  t = R.assoc('colors', c, t)

  return (
    <ThemeProvider theme={t}>{children}</ThemeProvider>
  )
}

const reverseTheme = ({ colors, ...rest }) => ({
  ...rest,
  colors: {
    background: colors.highContrast,
    highContrast: colors.background,
    midContrast: colors.lowContrast,
    lowContrast: colors.midContrast,
    brand: colors.brand,
  },
})

const createTheme = (newColors, newFonts) => ({ colors, fonts, ...rest }) =>({
  ...rest,
  colors: {
    ...colors,
    ...newColors
  },
  fonts: {
    ...fonts,
    ...newFonts
  }
})

export const ReverseTheme = ({ children }) => <ThemeProvider theme={reverseTheme}>{children}</ThemeProvider>

export const DynamicTheme = ({ children, colors = {}, fonts={}, print = false }) => {
  const customColors = print ? { ...printColors, ...colors } : colors
  return <ThemeProvider theme={createTheme(customColors, fonts)}>{children}</ThemeProvider>
}
