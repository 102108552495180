import React, { useRef, useState, useEffect } from 'react'
import { createEditor, Transformer } from './transformer'
import { ControlledEditor as MonacoEditor } from '@monaco-editor/react'
import useDebounce from './utils/use-debounce'
import { Resizable, ResizeCallbackData, ResizableBoxState } from 'react-resizable'

import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api'

import moduleResolver from './resolver'

import { TemplateParams, Maybe } from './data/models'

interface EditorProps {
  content: string
  onChange: (content: string, compiled_content: string) => void
  size: 'small' | 'large'
  templateParams: TemplateParams
  templateType: string
  contentType: string
}

export default function Editor(props: EditorProps): JSX.Element {
  const previewRef = useRef<HTMLDivElement>(null)
  const [editor, setEditor] = useState<Maybe<Transformer>>(null)
  const [code, setCode] = useState<string | undefined>(props.content)
  const editorCode = useDebounce(code, 1000)
  const [editorSize, setEditorSize] = useState<ResizableBoxState>({ width: 800, height: 500 })
  const [monaco, setMonaco] = useState<Maybe<monacoEditor.editor.IEditor>>(null)

  useEffect(() => {
    if (previewRef.current) {
      setEditor(createEditor(previewRef.current, moduleResolver))
    }
  }, [previewRef])

  useEffect(() => {
    try {
      if (editor && editorCode.length) {
        const compiled = editor.compile(editorCode)
        editor.execute(compiled, {
          ...props.templateParams,
          actions: {
            accept: 'http://customer-service.zuppler.com',
            reject: 'http://customer-service.zuppler.com',
          },
          template_type: props.templateType,
        })
        props.onChange(editorCode, compiled)
      }
    } catch (ex) {
      console.error(ex)
    }
  }, [editor, editorCode, props.templateParams])

  const onResize = (event: React.SyntheticEvent, { size }: ResizeCallbackData) => {
    setEditorSize(size)
    if (!!monaco) {
      setTimeout(() => monaco.layout(), 0)
    }
  }

  const editorDidMount = (_: () => void, ed: monacoEditor.editor.IStandaloneCodeEditor) => {
    setMonaco(ed)
  }

  const options: monacoEditor.editor.IEditorConstructionOptions = {
    selectOnLineNumbers: true,
    codeLens: true,
    minimap: { enabled: false },
    wordWrap: 'on',
  }
  const renderMonacoEditor = (size: 'small' | 'large'): JSX.Element => (
    <Resizable
      height={editorSize.height}
      width={editorSize.width}
      onResize={onResize}
      resizeHandles={['e']}
      axis='x'
      handle={h => (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '5px',
            bottom: 0,
            backgroundColor: '#aaa',
            cursor: 'ew-resize',
          }}
        />
      )}
      handleSize={[8, 8]}>
      <div
        style={{
          width: editorSize.width + 'px',
          height: 'calc(100vh - 190px)',
          position: 'relative',
          paddingRight: '5px',
        }}>
        <MonacoEditor
          editorDidMount={editorDidMount}
          language='javascript'
          theme='vs-dark'
          value={code}
          onChange={(_ev, val) => setCode(val)}
          options={options}
          // editorDidMount={e => e.focus()}
        />
      </div>
    </Resizable>
  )

  const renderPreview = () => (
    <div
      className='preview'
      style={{ flex: 1, height: 'calc(100vh - 190px)', overflowX: 'hidden', overflowY: 'auto' }}
      ref={previewRef}
    />
  )

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {renderMonacoEditor(props.size)}
        {props.contentType == 'thermal' ? <pre>{renderPreview()}</pre> : renderPreview()}
      </div>
      {props.contentType == 'thermal' && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          Test generated markup on{' '}
          <a
            href='https://star-document-markup-designer.smcs.site/main/index#designer'
            rel='noreferrer noopener'
            target='_blank'>
            StarPrinter Designer
          </a>
        </div>
      )}
    </>
  )
}
