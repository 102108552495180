import React, { PropsWithChildren, useContext } from 'react'
import { makeAuthRequest } from '../utils/request'
import { AppProps, TextTemplate, TextTemplateInfo, TextTemplateType } from './models'

export interface TextTemplateAPI {
  searchTemplates: (type?: string) => Promise<TextTemplateInfo[]>
  searchTemplateTypes: (search_term?: string) => Promise<TextTemplateType[]>
  saveTemplate: (template: Partial<TextTemplate>) => Promise<TextTemplate>
  loadTemplateDetails: (template: TextTemplateInfo) => Promise<TextTemplate>
}

const Ctx = React.createContext<null | TextTemplateAPI>(null)
export function TextTemplateSvc(props: PropsWithChildren<AppProps>): JSX.Element {
  const makePayload = makeAuthRequest(props.auth_token)
  const api = {
    searchTemplates: (type?: string): Promise<TextTemplateInfo[]> =>
      fetch(props.svcUrl + '/search', makePayload('POST', type && { type })).then(
        processJsonStatus
      ),
    searchTemplateTypes: (search_term?: string): Promise<TextTemplateType[]> =>
      fetch(
        props.svcUrl + '/search_type',
        makePayload('POST', search_term && { search_term })
      ).then(processJsonStatus),
    saveTemplate: (text_template: Partial<TextTemplate>): Promise<TextTemplate> =>
      text_template.id && text_template.url
        ? fetch(text_template.url, makePayload('PUT', { text_template })).then(processJsonStatus)
        : fetch(props.svcUrl, makePayload('POST', { text_template })).then(processJsonStatus),
    loadTemplateDetails: (template: TextTemplateInfo): Promise<TextTemplate> =>
      fetch(template.url, makePayload('GET')).then(processJsonStatus),
  }
  return <Ctx.Provider value={api}>{props.children}</Ctx.Provider>
}

export function useTextTemplatesSvc(): TextTemplateAPI {
  const api = useContext(Ctx)
  return api as TextTemplateAPI
}

export const processJsonStatus = (res: Response): Promise<any> =>
  res.ok ? res.json() : Promise.reject(res.status)
