import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'

interface ServerPreviewProps {
  params: any
  parts: string[]
  compiled_parts: string[]
  active: boolean
}
export default function ServerPreview(props: ServerPreviewProps): JSX.Element {
  const [renderedParts, setRenderedParts] = useState<string[]>([])
  const dataRef = useRef<HTMLSpanElement>(null)

  useLayoutEffect(() => {
    if (props.active) {
      const params = {
        parts: props.parts,
        compiled_parts: props.compiled_parts,
        ...props.params,
        actions: {
          accept: 'http://customer-service.zuppler.com',
          reject: 'http://customer-service.zuppler.com',
        },
      }
      fetch(`${process.env.TEXT_ENGINE_SVC}/render`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Client': 'TextEngineEditor',
        }),
        body: JSON.stringify(params),
      })
        .then(res => res.json())
        .then(({ parts }: { parts?: string[] }) => {
          setRenderedParts(parts || [])
        })
        .catch(errors =>
          setRenderedParts([
            `<h1>Error rendering templates:</h1><br/>Errors: ${JSON.stringify(errors, null, 2)}`,
          ])
        )
    }
  }, [props.active])

  useEffect(() => {
    if (dataRef.current) {
      dataRef.current.innerHTML = renderedParts.join('<hr/>')
    }
  }, [dataRef.current, renderedParts])

  return (
    <div>
      {renderedParts.length == 0 ? 'Loading data' : ''}
      <span ref={dataRef}>Loading data</span>
    </div>
  )
}
