import React, { useState, ChangeEvent, useContext } from 'react'
import * as R from 'ramda'

import { loadOrderByID } from '../data/order'
import { loadOrderByID as loadPaymentsOrder } from '../data/payments'
import { loadRestaurantByID } from '../data/restaurant'
import { loadMenusByRestaurantID } from '../data/menus'
import { loadChannelByIDAndPermalink } from '../data/channel'
import { themeFromChannelConfig } from '../data/theme'
import { getLoyaltyBalanceUrl, loadLoyaltyBalance } from '../data/loyalty'

import {
  Restaurant,
  Order,
  Channel,
  TemplateParams,
  Payment,
  Menu,
  LoyaltyBalance,
  LoyaltyStatus,
  LoyaltyRewards,
  LoyaltyBonus,
  UserSignup,
} from '../data/models'
import { TemplateReducerParam } from './params'
import { Invoice } from '../data/invoice'
import { loadInvoiceByID } from '../data/utils'
import { AppPropsCtx } from '../utils/request'
import { AppProps } from './models'
import { loadReport, Report } from '../data/report'
import moment from 'moment'
import { GiftCardsPayload } from '../data/gift_card'

export type TemplateEditorContextType =
  | 'order'
  | 'restaurant'
  | 'channel'
  | 'string'
  | 'invoice'
  | 'promo-discount'
  | 'reports_restaurant'
  | 'reports_channel'
  | 'gift_cards'
  | 'loyalty_status'
  | 'loyalty_rewards'
  | 'loyalty_bonus'
  | 'user-signup'

interface ContextStringParam {
  name: string
  value: string
}
export type ContextValue =
  | string
  | ContextStringParam
  | TemplateParams['discount']
  | TemplateParams['gift_cards']
  | LoyaltyStatus
  | LoyaltyRewards
  | LoyaltyBonus
  | UserSignup

export interface TemplateEditorContext {
  id: number
  type: TemplateEditorContextType
  value: ContextValue | null
}

let nextContextId = 1
export function createContext(type: TemplateEditorContextType): TemplateEditorContext {
  return { id: nextContextId++, type, value: null }
}

export interface ContextEditor extends TemplateEditorContext {
  dispatch: (ev: TemplateReducerParam) => void
  templateParams: TemplateParams
  saveContext: (ctx: TemplateEditorContext) => void
  onRemoveContext: (id: number) => void
}

interface ContextParams {
  [key: string]: any
}

export function contextsToParams(contexts: TemplateEditorContext[]): ContextParams {
  const contextToParam = (sum: ContextParams, context: TemplateEditorContext): ContextParams => {
    switch (context.type) {
      case 'order':
        return { ...sum, order_uuid: context.value as string }
      case 'restaurant':
        return { ...sum, restaurant_id: context.value as string }
      case 'reports_restaurant':
        return { ...sum, reports_restaurant_id: context.value }
      case 'channel':
        return { ...sum, channel_id: context.value as string }
      case 'reports_channel':
        return { ...sum, reports_channel_id: context.value }
      case 'invoice':
        return { ...sum, invoice_id: context.value as string }
      case 'string':
        const p = context.value as ContextStringParam
        return R.assoc(p.name, p.value, sum)
      case 'promo-discount':
        return { ...sum, discount: context.value }
      case 'gift_cards':
        return { ...sum, gift_cards: context.value }
      case 'loyalty_status':
        return { ...sum, campaign: { loyalty_status: context.value } }
      case 'loyalty_bonus':
        return { ...sum, campaign: { loyalty_bonus: context.value } }
      case 'loyalty_rewards':
        return { ...sum, campaign: { loyalty_rewards: context.value } }
      case 'user-signup':
        const { name, email, confirmation_code, channel_id } = context.value as UserSignup
        return {
          ...sum,
          account: { name, email },
          confirmation_code,
          channel_id,
        }
    }
  }
  return contexts.reduce(contextToParam, {})
}

function OrderContextEditor(props: ContextEditor): JSX.Element {
  const [value, setValue] = useState<string>((props.value as string) || '')
  const [loaded, setLoaded] = useState<boolean>(!!props.templateParams.order)
  const [error, setError] = useState<string | null>(null)

  const onUpdateValue = (ev: ChangeEvent<HTMLInputElement>): void => {
    setLoaded(false)
    setError(null)
    setValue(ev.target.value)
  }

  const onLoadData = (): void => {
    if (value) {
      loadOrderByID(value)
        .then((order: Order) =>
          Promise.all([
            loadRestaurantByID(order.carts.restaurantId),
            loadMenusByRestaurantID(order.carts.restaurantId),
            loadChannelByIDAndPermalink(order.carts.channelId, order.carts.integrationId),
            loadPaymentsOrder(order.uuid),
            loadLoyaltyBalance(
              getLoyaltyBalanceUrl(
                order.carts.customer.uuid,
                order.carts.restaurantId,
                order.carts.channelId
              )
            ),
          ]).then(
            ([restaurant, menus, channel, payments, loyaltyBalance]: [
              Restaurant,
              Menu[],
              Channel,
              Payment,
              LoyaltyBalance
            ]) => {
              props.dispatch({ type: 'save_order', order })
              props.dispatch({ type: 'save_restaurant', restaurant })
              props.dispatch({ type: 'save_menus', menus })
              props.dispatch({ type: 'save_channel', channel })
              props.dispatch({ type: 'set_theme', theme: themeFromChannelConfig(channel) })
              props.dispatch({ type: 'set_payment', payments })
              props.dispatch({ type: 'set_loyalty_balance', loyaltyBalance })
              setLoaded(true)
              props.saveContext({ id: props.id, type: props.type, value: value })
            }
          )
        )
        .catch(R.compose(setError, R.path(['message'])))
    }
  }

  return (
    <div className='input-group'>
      <span className='input-group-addon'>Order</span>
      <input
        type='text'
        id={`${props.type}-${props.id}`}
        value={value}
        onChange={onUpdateValue}
        className='form-control'
        placeholder='order uuid'
        aria-describedby='basic-addon1'
      />
      <span className='input-group-addon'>
        {loaded && props.templateParams.order && <i className='icon-check' />}
        {!loaded && (
          <button className='btn btn-primary' onClick={onLoadData}>
            Apply
          </button>
        )}
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
        {error && <span style={{ color: 'red' }}>{error}</span>}
      </span>
    </div>
  )
}

function InvoiceContextEditor(props: ContextEditor): JSX.Element {
  const appParams = useContext(AppPropsCtx) as AppProps
  const [value, setValue] = useState<string>((props.value as string) || '')
  const [loaded, setLoaded] = useState<boolean>(!!props.templateParams.order)
  const [error, setError] = useState<string | null>(null)

  const onUpdateValue = (ev: ChangeEvent<HTMLInputElement>): void => {
    setLoaded(false)
    setError(null)
    setValue(ev.target.value)
  }

  const onLoadData = (): void => {
    if (value && !isNaN(parseInt(value))) {
      loadInvoiceByID(parseInt(value), appParams.auth_token)
        .then((invoice: Invoice) => {
          props.dispatch({ type: 'save_invoice', invoice })
          setLoaded(true)
          props.saveContext({ id: props.id, type: props.type, value: value })
        })
        .catch(R.compose(setError, R.path(['message'])))
    }
  }

  return (
    <div className='input-group'>
      <span className='input-group-addon'>Invoice</span>
      <input
        type='text'
        id={`${props.type}-${props.id}`}
        value={value}
        onChange={onUpdateValue}
        className='form-control'
        placeholder='invoice id'
        aria-describedby='basic-addon1'
      />
      <span className='input-group-addon'>
        {loaded && props.templateParams.order && <i className='icon-check' />}
        {!loaded && (
          <button className='btn btn-primary' onClick={onLoadData}>
            Apply
          </button>
        )}
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
        {error && <span style={{ color: 'red' }}>{error}</span>}
      </span>
    </div>
  )
}
function RestaurantContextEditor(props: ContextEditor): JSX.Element {
  const [value, setValue] = useState<string>((props.value as string) || '')
  const [loaded, setLoaded] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const onUpdateValue = (ev: ChangeEvent<HTMLInputElement>): void => {
    setLoaded(false)
    setError(null)
    setValue(ev.target.value)
  }

  const onLoadData = (): void => {
    if (value) {
      loadRestaurantByID(value)
        .then((restaurant: Restaurant) => {
          props.dispatch({ type: 'save_restaurant', restaurant })
          setLoaded(true)
          props.saveContext({ id: props.id, type: props.type, value: value })
        })
        .catch(R.compose(setError, R.path(['message'])))
    }
  }

  return (
    <div className='input-group'>
      <span id={`${props.type}-${props.id}`} className='input-group-addon'>
        Restaurant
      </span>
      <input
        id={`${props.type}-${props.id}`}
        type='text'
        placeholder='restaurant id or permalink'
        value={value}
        onChange={onUpdateValue}
        aria-describedby={`${props.type}-${props.id}`}
      />
      <span className='input-group-addon'>
        {!loaded && (
          <button className='btn btn-primary btn-small' onClick={onLoadData}>
            Apply
          </button>
        )}
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
        {error && <span style={{ color: 'red' }}>{error}</span>}
        {loaded && props.templateParams.restaurant && <i className='icon-check' />}
      </span>
    </div>
  )
}

function ReportsRestaurantContextEditor(props: ContextEditor): JSX.Element {
  const [value, setValue] = useState<string>((props.value as string) || '')
  const [loaded, setLoaded] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const onUpdateValue = (ev: ChangeEvent<HTMLInputElement>): void => {
    setLoaded(false)
    setError(null)
    setValue(ev.target.value)
  }

  const onLoadData = (): void => {
    if (value && !isNaN(parseInt(value))) {
      loadReport(
        parseInt(value),
        'restaurant',
        moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      )
        .then((report: Report) => {
          props.dispatch({ type: 'save_reports_restaurant', report })
          setLoaded(true)
          props.saveContext({ id: props.id, type: props.type, value: value })
        })
        .catch(R.compose(setError, R.path(['message'])))
    }
  }

  return (
    <div className='input-group'>
      <span id={`${props.type}-${props.id}`} className='input-group-addon'>
        Report for Restaurant
      </span>
      <input
        id={`${props.type}-${props.id}`}
        type='text'
        placeholder='restaurant id'
        value={value}
        onChange={onUpdateValue}
        aria-describedby={`${props.type}-${props.id}`}
      />
      <span className='input-group-addon'>
        {!loaded && (
          <button className='btn btn-primary btn-small' onClick={onLoadData}>
            Apply
          </button>
        )}
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
        {error && <span style={{ color: 'red' }}>{error}</span>}
        {loaded && props.templateParams.restaurant && <i className='icon-check' />}
      </span>
    </div>
  )
}

function ReportsChannelContextEditor(props: ContextEditor): JSX.Element {
  const [value, setValue] = useState<string>((props.value as string) || '')
  const [loaded, setLoaded] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const onUpdateValue = (ev: ChangeEvent<HTMLInputElement>): void => {
    setLoaded(false)
    setError(null)
    setValue(ev.target.value)
  }

  const onLoadData = (): void => {
    if (value && !isNaN(parseInt(value))) {
      loadReport(
        parseInt(value),
        'channel',
        moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      )
        .then((report: Report) => {
          props.dispatch({ type: 'save_reports_channel', report })
          setLoaded(true)
          props.saveContext({ id: props.id, type: props.type, value: value })
        })
        .catch(R.compose(setError, R.path(['message'])))
    }
  }

  return (
    <div className='input-group'>
      <span id={`${props.type}-${props.id}`} className='input-group-addon'>
        Report for Channel
      </span>
      <input
        id={`${props.type}-${props.id}`}
        type='text'
        placeholder='channel id'
        value={value}
        onChange={onUpdateValue}
        aria-describedby={`${props.type}-${props.id}`}
      />
      <span className='input-group-addon'>
        {!loaded && (
          <button className='btn btn-primary btn-small' onClick={onLoadData}>
            Apply
          </button>
        )}
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
        {error && <span style={{ color: 'red' }}>{error}</span>}
        {loaded && props.templateParams.restaurant && <i className='icon-check' />}
      </span>
    </div>
  )
}

function ChannelContextEditor(props: ContextEditor): JSX.Element {
  const [value, setValue] = useState<string>((props.value as string) || '')
  const [loaded, setLoaded] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const onUpdateValue = (ev: ChangeEvent<HTMLInputElement>): void => {
    setLoaded(false)
    setError(null)
    setValue(ev.target.value)
  }

  const onLoadData = (): void => {
    if (value) {
      loadChannelByIDAndPermalink(value, 0)
        .then((channel: Channel) => {
          props.dispatch({ type: 'save_channel', channel })
          props.dispatch({ type: 'set_theme', theme: themeFromChannelConfig(channel) })
          setLoaded(true)
          props.saveContext({ id: props.id, type: props.type, value: value })
        })
        .catch(R.compose(setError, R.path(['message'])))
    }
  }

  return (
    <div className='input-group'>
      <span id={`${props.type}-${props.id}`} className='input-group-addon'>
        Channel
      </span>
      <input
        id={`${props.type}-${props.id}`}
        type='text'
        placeholder='channel id or permalink'
        value={value}
        onChange={onUpdateValue}
        aria-describedby={`${props.type}-${props.id}`}
      />
      <span className='input-group-addon'>
        {!loaded && (
          <button className='btn btn-primary btn-small' onClick={onLoadData}>
            Apply
          </button>
        )}
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
        {error && <span style={{ color: 'red' }}>{error}</span>}
        {loaded && props.templateParams.channel && <i className='icon-check' />}
      </span>
    </div>
  )
}

function CustomContextEditor(props: ContextEditor): JSX.Element {
  const [name, setName] = useState<string>(R.defaultTo('', R.path(['value', 'name'], props)))
  const [value, setValue] = useState<string>(R.defaultTo('', R.path(['value', 'value'], props)))
  const [loaded, setLoaded] = useState<boolean>(false)

  const onLoadData = (): void => {
    if (name && value && name.length && value.length) {
      setLoaded(true)
      props.dispatch({ type: 'set_custom', name, value })
      props.saveContext({ id: props.id, type: props.type, value: { name, value } })
    }
  }

  return (
    <div className='input-group'>
      <span id={`${props.type}-${props.id}`} className='input-group-addon'>
        Custom Param
      </span>
      <input
        id={`${props.type}-${props.id}`}
        type='text'
        value={name}
        onChange={ev => {
          setLoaded(false)
          setName(ev.target.value)
        }}
        placeholder='param name'
      />
      =
      <input
        id={`${props.type}-${props.id}`}
        type='text'
        placeholder='param value'
        value={value}
        onChange={ev => {
          setLoaded(false)
          setValue(ev.target.value)
        }}
        aria-describedby={`${props.type}-${props.id}`}
      />
      <span className='input-group-addon'>
        {!loaded && (
          <button className='btn btn-primary btn-small' onClick={onLoadData}>
            Apply
          </button>
        )}
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
        {loaded && <i className='icon-check' />}
      </span>
    </div>
  )
}

function PromoDiscountContextEditor(props: ContextEditor): JSX.Element {
  const [name, setName] = useState<string>(R.defaultTo('', R.path(['value', 'name'], props)))
  const [value, setValue] = useState<string>(R.defaultTo('', R.path(['value', 'value'], props)))
  const [loaded, setLoaded] = useState<boolean>(false)

  const onLoadData = (): void => {
    const discount = {
      applies_to: 'subtotal',
      discount_id: 100,
      end_time: '2022-03-25T02:35:23',
      start_time: '2022-03-24T02:35:23',
      code: 'TestPromoCode',
      code_id: 1001,
      badge: '$5',
      title: 'of the order',
    }
    props.dispatch({
      type: 'set_discount',
      value: discount,
    })
    props.saveContext({ id: props.id, type: props.type, value: discount })
  }

  return (
    <div className='input-group'>
      <span id={`${props.type}-${props.id}`} className='input-group-addon'>
        Promo Discount Params
      </span>
      <span>Mock data will be generated</span>
      <span className='input-group-addon'>
        {!loaded && (
          <button className='btn btn-primary btn-small' onClick={onLoadData}>
            Apply
          </button>
        )}
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
        {loaded && <i className='icon-check' />}
      </span>
    </div>
  )
}

function GiftCardsContextEditor(props: ContextEditor): JSX.Element {
  const payload: GiftCardsPayload = {
    cards: [
      {
        balance: 10000,
        customer_email: 'john.doe@gmail.com',
        customer_name: 'John Doe',
        customer_phone: '+14433444444',
        description: 'Merry Christmas my friend',
        expires_at: '2023-04-14',
        logo_image:
          'https://dbgcbnch6yz43.cloudfront.net/images/pictures/files/000/049/870/medium/GC Image 2.png?63851615103',
        name: '$50 Gift Card for only $45',
        unique_code: 'YZBEHR28G9OL701',
        price: 8000,
      },
      {
        balance: 5000,
        customer_email: null,
        customer_name: null,
        customer_phone: null,
        description: 'Gift Card description',
        expires_at: '2023-04-14',
        logo_image:
          'https://dbgcbnch6yz43.cloudfront.net/images/pictures/files/000/049/870/medium/GC Image 2.png?63851615103',
        name: 'Second Gift Card',
        unique_code: 'YZBEHR28G9OL701',
        price: 8000,
      },
    ],
    definition: {
      name: 'Example Store Name',
      description: 'Example Store Description',
      logo_image:
        'https://dbgcbnch6yz43.cloudfront.net/images/pictures/files/000/049/871/medium/GD Definition Logo.png',
      channels: [
        { name: 'Preview Channel', url: 'http://restaurants.biznttechnologies.com' },
        { name: 'Pizzeria Aroma', url: 'http://order.biznttechnologies.com' },
      ],
    },
  }
  const onLoadData = (): void => {
    props.dispatch({
      type: 'set_gift_cards',
      value: payload,
    })
    props.saveContext({ id: props.id, type: props.type, value: payload })
  }

  return (
    <div className='input-group'>
      <span id={`${props.type}-${props.id}`} className='input-group-addon'>
        Gift Cards Example Data:
      </span>
      <div>
        <pre style={{ maxHeight: '180px', overflowY: 'auto' }}>
          {JSON.stringify(payload, null, 2)}
        </pre>
      </div>
      <span className='input-group-addon'>
        <button className='btn btn-primary btn-small' onClick={onLoadData}>
          Apply
        </button>
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
      </span>
    </div>
  )
}

function LoyaltyStatusContextEditor(props: ContextEditor): JSX.Element {
  const payload: LoyaltyStatus = {
    logo_url:
      'https://cdn.zuppler.com/images/pictures/files/000/050/264/thumb/Name%20Logo.png?v=63865481876',
    message: 'test',
    user: {
      dashboard_url: 'https://order.biznettechnologies.com/portal.html/#/account/loyalty/dashboard',
      email: 'caitlyn@zuppler.com',
      lifetime_savings: 0,
      name: 'Caitlyn',
      total_orders_last_month: 1,
      total_points_earned_last_month: 87,
      total_points_spent_last_month: 0,
      total_points_to_spend: 87,
    },
  }

  const onLoadData = (): void => {
    props.dispatch({
      type: 'set_campaign_loyalty_status',
      value: payload,
    })
    props.saveContext({ id: props.id, type: props.type, value: payload })
  }

  return (
    <div className='input-group'>
      <span id={`${props.type}-${props.id}`} className='input-group-addon'>
        Campaigns Loyalty Status:
        <textarea rows={2}>{JSON.stringify(payload, null, 2)}</textarea>
      </span>
      <span className='input-group-addon'>
        <button className='btn btn-primary btn-small' onClick={onLoadData}>
          Apply
        </button>
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
      </span>
    </div>
  )
}

function LoyaltyRewardsContextEditor(props: ContextEditor): JSX.Element {
  const payload: LoyaltyRewards = {
    logo_url: 'https://cdn.zuppler.com/images/pictures/files/000/037/276/thumb/Green-Goodess.jpg',
    message: 'test',
    user: {
      email: 'caitlyn@zuppler.com',
      highest_value_reward: { amount: 300, id: 'c1', points: 75 },
      integration_url: 'https://order.biznettechnologies.com/portal.html#/',
      name: 'Caitlyn',
      rewards: [
        { amount: 300, id: 'c1', points: 75 },
        { amount: 500, id: 'c2', points: 100 },
        { amount: 700, id: 'c3', points: 150 },
      ],
    },
  }

  const onLoadData = (): void => {
    props.dispatch({
      type: 'set_campaign_loyalty_rewards',
      value: payload,
    })
    props.saveContext({ id: props.id, type: props.type, value: payload })
  }

  return (
    <div className='input-group'>
      <span id={`${props.type}-${props.id}`} className='input-group-addon'>
        Campaigns Loyalty Rewards:
        <textarea rows={2}>{JSON.stringify(payload, null, 2)}</textarea>
      </span>
      <span className='input-group-addon'>
        <button className='btn btn-primary btn-small' onClick={onLoadData}>
          Apply
        </button>
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
      </span>
    </div>
  )
}

function LoyaltyBonusContextEditor(props: ContextEditor): JSX.Element {
  const payload: LoyaltyBonus = {
    logo_url:
      'https://cdn.zuppler.com/images/pictures/files/000/050/264/thumb/Name%20Logo.png?v=63865481876',
    message: 'testtttt',
    user: {
      email: 'caitlyn@zuppler.com',
      integration_url: 'https://order.biznettechnologies.com/portal.html#/',
      name: 'Caitlyn',
      bonus: '1.5X points on Tue',
    },
  }
  const onLoadData = (): void => {
    props.dispatch({
      type: 'set_campaign_loyalty_bonus',
      value: payload,
    })
    props.saveContext({ id: props.id, type: props.type, value: payload })
  }

  return (
    <div className='input-group'>
      <span id={`${props.type}-${props.id}`} className='input-group-addon'>
        Campaigns Loyalty Bonus:
        <textarea rows={2}>{JSON.stringify(payload, null, 2)}</textarea>
      </span>
      <span className='input-group-addon'>
        <button className='btn btn-primary btn-small' onClick={onLoadData}>
          Apply
        </button>
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
      </span>
    </div>
  )
}

function UserSignupContextEditor(props: ContextEditor): JSX.Element {
  const payload = {
    email: 'test@zuppler.com',
    name: 'Test User',
    channel_id: 1,
    confirmation_code: '123abc',
  }
  const onLoadData = (): void => {
    props.dispatch({
      type: 'set_user_signup',
      value: payload,
    })
    props.saveContext({ id: props.id, type: props.type, value: payload })
  }

  return (
    <div className='input-group'>
      <span id={`${props.type}-${props.id}`} className='input-group-addon'>
        User Signup:
        <textarea rows={2}>{JSON.stringify(payload, null, 2)}</textarea>
      </span>
      <span className='input-group-addon'>
        <button className='btn btn-primary btn-small' onClick={onLoadData}>
          Apply
        </button>
        <button className='btn btn-danger' onClick={() => props.onRemoveContext(props.id)}>
          <i className='icon-remove' />
        </button>
      </span>
    </div>
  )
}
export const editorForType = (
  type: TemplateEditorContextType
): ((props: ContextEditor) => JSX.Element) => {
  switch (type) {
    case 'order':
      return OrderContextEditor
    case 'restaurant':
      return RestaurantContextEditor
    case 'channel':
      return ChannelContextEditor
    case 'invoice':
      return InvoiceContextEditor
    case 'string':
      return CustomContextEditor
    case 'promo-discount':
      return PromoDiscountContextEditor
    case 'reports_channel':
      return ReportsChannelContextEditor
    case 'reports_restaurant':
      return ReportsRestaurantContextEditor
    case 'gift_cards':
      return GiftCardsContextEditor
    case 'loyalty_status':
      return LoyaltyStatusContextEditor
    case 'loyalty_rewards':
      return LoyaltyRewardsContextEditor
    case 'loyalty_bonus':
      return LoyaltyBonusContextEditor
    case 'user-signup':
      return UserSignupContextEditor
  }
}
