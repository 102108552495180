import * as R from 'ramda'
import React from 'react'
import { Text, Span, Heading } from './primitives'
import { Grid, Row, Cell } from './grid'
import moment from 'moment'
import { useContext } from '../../text/context'
import { Link, Button, ButtonImportant, Box, CommaSeparated } from './molecules'
/**

<UI.Template format="text">

<UI.OrderConfirmationButton>Confirmation Button</UI.OrderConfirmationButton>
<UI.OrderConfirmationLink>Confirmation Button</UI.OrderConfirmationLink>

<UI.PromoCodeLink>Promo Code Link</UI.PromoCodeLink>
<UI.PromoCodeUrl>Promo Code Url</UI.PromoCodeUrl>
<UI.PromoCodeButton>Promo Code Button</UI.PromoCodeButton>

<UI.OrderDashboardButton>Dashboard Button</UI.OrderDashboardButton>
<UI.IntegrationButton>Integration Button</UI.IntegrationButton>
<UI.IntegrationLink>Integration Link</UI.IntegrationLink>
<UI.FeedbackLink>Feedback Link</UI.FeedbackLink>
<UI.UnsubscribeLink>UnsubscribeLink Link</UI.UnsubscribeLink>
<UI.CustomerServiceButton>CS Button</UI.CustomerServiceButton>
<UI.CustomerServiceLink>CS Link</UI.CustomerServiceLink>


<UI.InvoiceLink>Invoice Link</UI.InvoiceLink>
</UI.Template>
*/
import Order, {
  OrderCtx,
  OptionCtx,
  toDashboardUrl,
  toConfirmationUrl,
  toIntegrationUrl,
  toCustomerServiceUrl,
  toInvoiceUrl,
  toApplyPromoDiscount,
  toUnsubscribeUrl,
  toFeedbackUrl,
} from '../order'

import {
  ShortenOrderCtx,
  ShortenPromoOrderCtx,
  ShortenDashboardOrderCtx,
  ShortenOrderReceiptCtx,
  ShortenIntegrationOrderCtx,
  ShortenOrderFeedbackCtx,
  ShortenUnsubscribeOrderCtx,
  ShortenCSUrlCtx,
  ShortenInvoiceCtx,
} from '../order'

import Params from '../params'
import Channel from '../channel'
import Restaurant from '../restaurant'
import Menus from '../menus'
import { InvoiceCtx } from '../invoice'
import { toLoyaltyDashboardUrl } from '../loyalty'
import { GiftCardsCtx, GiftCardCtx } from '../gift_card'

import { toMoney, useTemplateFormat } from '../utils'

export const CellItem = ({
  itemColor,
  itemBgColor,
  itemBorderColor,
  color = 'highContrast',
  cellProps = {},
  ...props
}) => (
  <Cell
    p={2}
    borderBottom={1}
    color={itemColor}
    bg={itemBgColor}
    borderBottomColor={itemBorderColor}
    verticalAlign='top'
    {...cellProps}
    {...props}
  />
)

export const CellItemHeader = ({
  headerColor = 'lowContast',
  headerBgColor = 'background',
  headerBorderColor = 'background2',
  ...props
}) => (
  <Cell
    p={2}
    color={headerColor}
    bg={headerBgColor}
    fontSize={2}
    fontWeight={2}
    borderBottom={2}
    borderBottomColor={headerBorderColor}
    {...props}
  />
)

export const HeroBox = props => (
  <Box p={3} bg='brand' color='background' borderRadius={3} center {...props} />
)

export const OrderDashboardButton = props => {
  const orderCtx = useContext(OrderCtx)
  const paramsCtx = useContext(Params.Context)
  // DO NOT REMOVE NEXT LINE
  const dashboardUrlCtx = useContext(ShortenDashboardOrderCtx)
  const { children, ...rest } = props
  return (
    <Button
      {...rest}
      href={
        paramsCtx.shorten_dashboard_order_url || toDashboardUrl(orderCtx, paramsCtx.template_type)
      }>
      {children}
    </Button>
  )
}

export const OrderConfirmationButton = props => {
  const orderCtx = useContext(OrderCtx)
  const shortenCtx = useContext(ShortenOrderCtx)
  const paramsCtx = useContext(Params.Context)
  const receiptCtx = useContext(ShortenOrderReceiptCtx)
  return (
    <Button
      href={
        paramsCtx.shorten_order_receipt_url || toConfirmationUrl(orderCtx, paramsCtx, shortenCtx)
      }
      {...props}
    />
  )
}

export const OrderConfirmationLink = props => {
  const orderCtx = useContext(OrderCtx)
  const shortenCtx = useContext(ShortenOrderCtx)
  const paramsCtx = useContext(Params.Context)
  const receiptCtx = useContext(ShortenOrderReceiptCtx)
  return (
    <Link
      href={
        paramsCtx.shorten_order_receipt_url || toConfirmationUrl(orderCtx, paramsCtx, shortenCtx)
      }
      {...props}
    />
  )
}

export const IntegrationButton = props => {
  const orderCtx = useContext(OrderCtx)
  const channelCtx = useContext(Channel.Context)
  const paramsCtx = useContext(Params.Context)
  const receiptCtx = useContext(ShortenIntegrationOrderCtx)
  return (
    <Button
      href={
        paramsCtx.shorten_order_integration_url ||
        toIntegrationUrl(orderCtx, channelCtx, paramsCtx.template_type)
      }
      {...props}
    />
  )
}

export const ExternalLink = props => {
  const orderCtx = useContext(OrderCtx)
  const channelCtx = useContext(Channel.Context)
  const restaurantCtx = useContext(Restaurant.Context)
  const { href, ...rest } = props
  if (orderCtx && channelCtx) {
    return <Link {...rest} href={interpolateUrl(href, channelCtx, orderCtx, restaurantCtx)} />
  } else return <Link {...rest} href={href} />
}

export const ExternalButton = props => {
  const orderCtx = useContext(OrderCtx)
  const channelCtx = useContext(Channel.Context)
  const restaurantCtx = useContext(Restaurant.Context)
  const { href, ...rest } = props
  if (orderCtx && channelCtx) {
    return <Button {...rest} href={interpolateUrl(href, channelCtx, orderCtx, restaurantCtx)} />
  } else return <Button {...rest} href={href} />
}

function interpolateUrl(href, channelCtx, orderCtx, restaurantCtx) {
  const now = restaurantCtx ? moment().utcOffset(restaurantCtx.timezone.offset) : moment()
  return href
    .replace(/\{MID\}/, channelCtx.integrations[0].remote_id)
    .replace(/\{DATE\}/, now.format('MMDDYY'))
    .replace(/\{TIME\}/, now.format('HHmmss'))
    .replace(/\{ORDERID\}/, orderCtx.id)
    .replace(/\{ORDERUUID\}/, orderCtx.uuid)
}

export const IntegrationLink = props => {
  const orderCtx = useContext(OrderCtx)
  const channelCtx = useContext(Channel.Context)
  const paramsCtx = useContext(Params.Context)
  const shortenCtc = useContext(ShortenIntegrationOrderCtx)
  return (
    <Link
      href={
        paramsCtx.shorten_order_integration_url ||
        toIntegrationUrl(orderCtx, channelCtx, paramsCtx.template_type)
      }
      {...props}
    />
  )
}

export const FeedbackLink = props => {
  const orderCtx = useContext(OrderCtx)
  const paramsCtx = useContext(Params.Context)
  const shortenCtc = useContext(ShortenOrderFeedbackCtx)
  return (
    <Link
      href={
        paramsCtx.shorten_order_feedback_url || toFeedbackUrl(orderCtx, paramsCtx.template_type)
      }
      {...props}
    />
  )
}

export const UnsubscribeLink = props => {
  const orderCtx = useContext(OrderCtx)
  const paramsCtx = useContext(Params.Context)
  const shortenCtc = useContext(ShortenUnsubscribeOrderCtx)
  return paramsCtx.template_type ? (
    <Link
      href={paramsCtx.shorten_order_unsubscribe_url || toUnsubscribeUrl(orderCtx, paramsCtx)}
      {...props}
    />
  ) : (
    <></>
  )
}

export const CustomerServiceButton = props => {
  const orderCtx = useContext(OrderCtx)
  const paramsCtx = useContext(Params.Context)
  const shortenCtx = useContext(ShortenCSUrlCtx)
  return (
    <Button href={paramsCtx.shorten_order_cs_url || toCustomerServiceUrl(orderCtx)} {...props} />
  )
}

export const CustomerServiceLink = props => {
  const orderCtx = useContext(OrderCtx)
  const paramsCtx = useContext(Params.Context)
  const shortenCtx = useContext(ShortenCSUrlCtx)
  return <Link href={paramsCtx.shorten_order_cs_url || toCustomerServiceUrl(orderCtx)} {...props} />
}

export const InvoiceLink = props => {
  const invoiceCtx = useContext(InvoiceCtx)
  console.log('InvoiceCtx', invoiceCtx)
  const paramsCtx = useContext(Params.Context)
  console.log('InvoiceCtx paramsCtx', paramsCtx.shorte_invoice_url)
  const shortenCtx = useContext(ShortenInvoiceCtx)
  return <Link href={paramsCtx.shorten_invoice_url || toInvoiceUrl(invoiceCtx)} {...props} />
}

export const WorkflowAction = ({ color, bg, space, typography, border, children, outcome }) => {
  const params = useContext(Params.Context)
  if (params.actions && params.actions[outcome]) {
    return (
      <ButtonImportant {...{ color, bg, space, typography, border }} href={params.actions[outcome]}>
        {children}
      </ButtonImportant>
    )
  } else return null
}

export const ActionButton = ({ outcome, children, ...props }) => {
  const params = useContext(Params.Context)
  if (params.actions && params.actions[outcome]) {
    return (
      <Button {...props} href={params.actions[outcome]}>
        {children}
      </Button>
    )
  } else return null
}

export const ActionLink = ({ outcome, children, ...props }) => {
  const params = useContext(Params.Context)
  if (params.actions && params.actions[outcome]) {
    return (
      <Link {...props} href={params.actions[outcome]}>
        {children}
      </Link>
    )
  } else return null
}

// <UI.PromoCode/>
export const PromoCode = () => {
  const params = useContext(Params.Context)
  if (params.discount && params.discount.code) {
    return <>{params.discount.code}</>
  } else return null
}

export const ConfirmationCode = () => {
  const params = useContext(Params.Context)
  if (params.confirmation_code && params.confirmation_code) {
    return <>{params.confirmation_code}</>
  } else return null
}
// <UI.PromoCodeLink>Link to promo code <UI.PromoCode/></UI.PromoCodeLink>
export const PromoCodeUrl = () => {
  const params = useContext(Params.Context)
  const order = useContext(OrderCtx)
  const promo = useContext(ShortenPromoOrderCtx)

  if (params && params.discount && params.discount.code) {
    return <>{params.shorten_order_integration_promo_url || toApplyPromoDiscount(order, params)}</>
  } else return null
}

// <UI.PromoCodeLink>Link to promo code <UI.PromoCode/></UI.PromoCodeLink>
export const PromoCodeLink = ({ children, ...props }) => {
  const params = useContext(Params.Context)
  const order = useContext(OrderCtx)
  const promo = useContext(ShortenPromoOrderCtx)

  if (params && params.discount && params.discount.code) {
    return (
      <Link
        {...props}
        href={params.shorten_order_integration_promo_url || toApplyPromoDiscount(order, params)}>
        {children}
      </Link>
    )
  } else return null
}

// <UI.PromoCodeLink>Link to promo code <UI.PromoCode/></UI.PromoCodeLink>
export const PromoCodeButton = ({ children, ...props }) => {
  const params = useContext(Params.Context)
  const order = useContext(OrderCtx)
  const promo = useContext(ShortenPromoOrderCtx)

  if (params && params.discount && params.discount.code) {
    return (
      <Button
        {...props}
        href={params.shorten_order_integration_promo_url || toApplyPromoDiscount(order, params)}>
        {children}
      </Button>
    )
  } else return null
}

export const LastPaymentInfo = props => (
  <Grid>
    <Row>
      <Cell>Payment</Cell>
      <Cell>Amount</Cell>
      <Cell>Time</Cell>
      <Cell>Status</Cell>
      <Cell>Notes</Cell>
      <Cell>Card Owner</Cell>
      <Cell>Card Ending</Cell>
      <Cell>Card Type</Cell>
    </Row>
    <Order.Payment.Last>
      <Row>
        <Cell>
          <Order.Payment.Item.Label />
        </Cell>
        <Cell>
          <Order.Payment.Item.Amount />
        </Cell>
        <Cell>
          <Order.Payment.Item.Time />
        </Cell>
        <Cell>
          <Order.Payment.Item.Status />
        </Cell>
        <Cell>
          <Order.Payment.Item.Note />
        </Cell>
        <Cell>
          <Order.Payment.Item.Card.Owner />
        </Cell>
        <Cell>
          <Order.Payment.Item.Card.Digits />
        </Cell>
        <Cell>
          <Order.Payment.Item.Card.Type />
        </Cell>
      </Row>
    </Order.Payment.Last>
  </Grid>
)

const TotalRow = ({
  type,
  label,
  description,
  children,
  totalsColor = 'highContrast',
  totalsBg = 'background',
  labelCell = {},
  priceCell = {},
}) => {
  const orderCtx = useContext(OrderCtx)
  if (!orderCtx) return null

  const discountInfo =
    R.path(['carts', 0, 'discounts', 0], orderCtx) || R.path(['carts', 'discounts', 0], orderCtx)

  if (
    type === 'discount' &&
    !!discountInfo &&
    !!discountInfo.applies_to &&
    discountInfo.applies_to !== 'item'
  )
    return null
  return (
    <React.Fragment>
      <Row sizes={[null, 70]}>
        <Cell p={1} right bg={totalsBg} color={totalsColor} {...labelCell}>
          {label}
          <br />
          <Text style={{ fontSize: 12, fontWeight: 'light' }}>{description}</Text>
        </Cell>
        <Cell p={1} pr={2} right fontWeight={3} bg={totalsBg} color={totalsColor} {...priceCell}>
          {children}
        </Cell>
      </Row>
      {type !== 'discount' && !!discountInfo && discountInfo.applies_to === type && (
        <Row sizes={[null, 70]}>
          <Cell p={1} right bg={totalsBg} color={totalsColor} {...labelCell}>
            {discountInfo.badge} {discountInfo.title}
          </Cell>
          <Cell p={1} pr={2} right fontWeight={3} bg={totalsBg} color={totalsColor} {...priceCell}>
            -<Order.Totals.Discount />
          </Cell>
        </Row>
      )}
    </React.Fragment>
  )
}

export const OrderTotals = ({ mainTotal = {}, showMainTotal = true, ...props }) => {
  // labelCell, priceCell
  const orderCtx = useContext(OrderCtx)
  const channelCtx = useContext(Channel.Context)
  const cartCfg = useContext(CartConfig)

  if (!orderCtx || !channelCtx) return null
  let {
    delivery,
    discount,
    hidden,
    service,
    subtotal,
    tax,
    included_tax,
    tip,
    total,
    total_markup,
    tender_discount,
  } = orderCtx.totals

  let convFee = hidden

  if (cartCfg.markup) {
    // Customer Cart
    subtotal += total_markup || 0
    service -= total_markup || 0
  } else if (channelCtx.menuMarkupHidden && !cartCfg.markup) {
    total -= total_markup || 0
    service -= total_markup || 0
    convFee += total_markup || 0
  }

  const convFeeLabel =
    orderCtx.totals.detailedCharges
      .filter(c => c.type == 'convenience_fee')
      .map(c => c.name)
      .join(', ') || 'Conv. fee:'

  const convFeeDescription = orderCtx.totals.detailedCharges
    .filter(c => c.type == 'convenience_fee')
    .map(c => c.description)
    .join('. ')

  return (
    <Grid>
      {!!subtotal && (
        <TotalRow label='Subtotal:' {...props} type='subtotal'>
          <Order.Totals.Subtotal />
        </TotalRow>
      )}
      {!!delivery && (
        <TotalRow label='Delivery:' {...props} type='delivery'>
          <Order.Totals.Delivery />
        </TotalRow>
      )}
      {!!discount && (
        <TotalRow label='Discount:' {...props} type='discount'>
          -<Order.Totals.Discount />
        </TotalRow>
      )}
      {!!service && (
        <TotalRow label='Service:' {...props} type='service'>
          <Order.Totals.Service />
        </TotalRow>
      )}
      {!!tax && (
        <TotalRow label='Tax:' {...props} type='tax'>
          <Order.Totals.Tax />
        </TotalRow>
      )}
      {!!included_tax && (
        <TotalRow label='VAT:' {...props} type='included_tax'>
          <Order.Totals.IncludedTax />
        </TotalRow>
      )}
      {!!tip && (
        <TotalRow label='Tip:' {...props} type='tip'>
          <Order.Totals.Tip />
        </TotalRow>
      )}
      {!!tender_discount && (
        <TotalRow
          label={`${orderCtx.carts.settings.tender.label} Discount:`}
          {...props}
          type='tender_discount'>
          -<Order.Totals.TenderDiscount />
        </TotalRow>
      )}
      {!!total && showMainTotal && (
        <TotalRow label='Total:' {...props} {...mainTotal} type='total'>
          <Order.Totals.Total />
        </TotalRow>
      )}
      {!!cartCfg.conv_fee && convFee > 0 && (
        <TotalRow
          label={convFeeLabel}
          {...props}
          type='convenience_fee'
          description={convFeeDescription}>
          <Order.Totals.ConvFee />
        </TotalRow>
      )}
    </Grid>
  )
}

const ModifierOption = ({ hideOptionPrice }) => {
  const option = useContext(OptionCtx)
  const cartCfg = useContext(CartConfig)
  const templateFormat = useTemplateFormat()
  if (!option) return null

  let { total } = option.total
  if (cartCfg.markup) {
    total += option.markup_for_total || 0
  }

  return (
    <>
      {templateFormat == 'print' ? '+ ' : '• '}
      {option.quantity > 1 && (
        <Span>
          <Order.Option.Quantity /> x{' '}
        </Span>
      )}
      <Order.Option.Name />{' '}
      {total > 0 && !hideOptionPrice && (
        <Span>
          {' '}
          - <Order.Option.Total />
        </Span>
      )}{' '}
    </>
  )
}

export const OrderItems = ({
  refund = false,
  hideCategory = false,
  hideMenu = true,
  hideModifier = false,
  hideOptionPrice = false,

  itemColor = 'highContrast',
  itemBgColor = 'background',
  itemBorderColor = 'lowContrast',

  modifierColor = 'midContrast',
  optionsColor = 'midContrast',

  itemCell = {},
  qtyCell = {},
  priceCell = {},
  itemText = {},
  modifierText = {},
  optionText = {},

  ...rest
}) => {
  let cartCfg = useContext(CartConfig)
  return (
    <Grid>
      <Row sizes={[null, 30, 70]}>
        <CellItemHeader {...rest}>Name</CellItemHeader>
        <CellItemHeader center {...rest}>
          Qty
        </CellItemHeader>
        <CellItemHeader right {...rest}>
          Price
        </CellItemHeader>
      </Row>
      <Order.Items>
        <Row>
          <CellItem {...{ itemColor, itemBgColor, itemBorderColor }} {...itemCell}>
            <Order.Item.WithDeleted>
              <Text fontWeight={2} fontSize={2} color={itemColor} {...itemText}>
                {!hideMenu && (
                  <>
                    <Order.Item.Menu /> -{' '}
                  </>
                )}
                {!hideCategory && (
                  <>
                    <Order.Item.Category /> -{' '}
                  </>
                )}
                <Order.Item.Name />
              </Text>
            </Order.Item.WithDeleted>
            <Order.Item.Modifiers>
              <Box mt={2}>
                <Order.Item.WithDeleted>
                  <Text color={modifierColor} fontSize={1}>
                    {!hideModifier && (
                      <>
                        <Span fontWeight={3} {...modifierText}>
                          <Order.Modifier.Name />
                        </Span>
                        {' - '}
                      </>
                    )}

                    <Span color={optionsColor} {...optionText}>
                      <Order.Modifier.Options>
                        <CommaSeparated>
                          <ModifierOption {...{ hideOptionPrice }} />
                        </CommaSeparated>
                      </Order.Modifier.Options>
                    </Span>
                  </Text>
                </Order.Item.WithDeleted>
              </Box>
            </Order.Item.Modifiers>
            <Order.Item.Comments>
              {val => (
                <Box mt={2}>
                  <Order.Item.WithDeleted>
                    <Text high fontSize={1}>
                      {val}
                    </Text>
                  </Order.Item.WithDeleted>
                </Box>
              )}
            </Order.Item.Comments>
          </CellItem>
          <CellItem center {...{ itemColor, itemBgColor, itemBorderColor }} cellProps={qtyCell}>
            <Order.Item.WithDeleted>
              <Order.Item.Quantity />
            </Order.Item.WithDeleted>
          </CellItem>
          <CellItem right {...{ itemColor, itemBgColor, itemBorderColor }} cellProps={priceCell}>
            <Order.Item.WithDeleted>
              <Order.Item.Total refund={refund} markup={cartCfg.markup} />
            </Order.Item.WithDeleted>
          </CellItem>
        </Row>
      </Order.Items>
    </Grid>
  )
}

import { CartConfig } from '../order'

export const RestaurantCartContents = props => {
  return (
    <CartConfig.Provider
      value={{ markup: false, conv_fee: false, item_totals: !props.hide_totals }}>
      <CartContents {...props} />
    </CartConfig.Provider>
  )
}

export const StandardCartContents = props => {
  return (
    <CartConfig.Provider value={{ markup: true, conv_fee: true }}>
      <CartContents {...props} />
    </CartConfig.Provider>
  )
}

const CartContents = props => (
  /*
    headerColor
    headerBgColor
    headerBorderColor

    itemColor
    itemBgColor
    itemBorderColor

    modifierColor
    optionsColor

    totalsColor
    totalsBg

    itemCell = {}
    qtyCell = {}
    priceCell = {}
    itemText = {}
    modifierText = {}
  */
  <>
    <OrderItems {...props} />
    <OrderTotals {...props} />
  </>
)

export const GroupedOrderItems = ({
  refund = false,
  hideCategory = false,
  hideMenu = true,
  hideModifier = false,
  hideOptionPrice = false,

  itemColor = 'highContrast',
  itemBgColor = 'background',
  itemBorderColor = 'lowContrast',

  modifierColor = 'midContrast',
  optionsColor = 'midContrast',

  itemCell = {},
  qtyCell = {},
  priceCell = {},
  itemText = {},
  modifierText = {},
  optionText = {},
  menuImage = {},

  ...rest
}) => {
  let cartCfg = useContext(CartConfig)
  return (
    <Menus.MenusWithItems>
      <Box>
        <Menus.Menu.ImageOrName width={130} {...menuImage} />
        <Menus.Menu.Items>
          <Grid>
            <Row sizes={[null, 30, 70]}>
              <CellItem {...{ itemColor, itemBgColor, itemBorderColor }} pb={3} {...itemCell}>
                <Text fontWeight={2} fontSize={2} color={itemColor} {...itemText}>
                  {!hideMenu && (
                    <>
                      <Order.Item.Menu /> -{' '}
                    </>
                  )}
                  {!hideCategory && (
                    <>
                      <Order.Item.Category /> -{' '}
                    </>
                  )}
                  <Order.Item.Name />
                </Text>
                <Order.Item.Modifiers>
                  <Box mt={2}>
                    <Text color={modifierColor} fontSize={1}>
                      {!hideModifier && (
                        <>
                          <Span fontWeight={3} {...modifierText}>
                            <Order.Modifier.Name />
                          </Span>
                          {' - '}
                        </>
                      )}
                      <Span color={optionsColor} {...optionText}>
                        <Order.Modifier.Options>
                          <CommaSeparated>
                            <ModifierOption {...{ hideOptionPrice }} />
                          </CommaSeparated>
                        </Order.Modifier.Options>
                      </Span>
                    </Text>
                  </Box>
                </Order.Item.Modifiers>
                <Order.Item.Comments>
                  {val => (
                    <Box mt={2}>
                      <Text high fontSize={1}>
                        {val}
                      </Text>
                    </Box>
                  )}
                </Order.Item.Comments>
              </CellItem>
              <CellItem
                center
                {...{ itemColor, itemBgColor, itemBorderColor }}
                pb={3}
                cellProps={qtyCell}>
                <Order.Item.Quantity />
              </CellItem>
              <CellItem
                right
                {...{ itemColor, itemBgColor, itemBorderColor }}
                pb={3}
                cellProps={priceCell}>
                <Order.Item.Total refund={refund} markup={cartCfg.markup} />
              </CellItem>
            </Row>
          </Grid>
        </Menus.Menu.Items>
      </Box>
    </Menus.MenusWithItems>
  )
}

export const StandardExtraFields = props => (
  <Order.EF.Groups>
    <Grid>
      <Order.EF.Group.Name>
        {val => (
          <Row>
            <Cell brand p={2} span={2} borderBottom={1} borderColor='background2'>
              <Text fontWeight={3}>{val}</Text>
            </Cell>
          </Row>
        )}
      </Order.EF.Group.Name>
      <Order.EF.Group.Fields>
        <Row>
          <Cell px={2} py={1} borderBottom={1} borderColor='background1' mid>
            <Order.EF.Field.Label />
          </Cell>
          <Cell px={2} borderBottom={1} borderColor='background1' fontWeight={3} right>
            <Order.EF.Field.Value />
          </Cell>
        </Row>
      </Order.EF.Group.Fields>
    </Grid>
  </Order.EF.Groups>
)

export const CellHeading = props => (
  <Cell
    p={2}
    borderBottom={1}
    borderBottomColor='midContrast'
    bg='background1'
    verticalAlign='top'
    fontWeight={3}
    color='midContrast'
    {...props}
  />
)

export const CellTotal = props => (
  <Cell p={2} borderBottom={1} borderBottomColor='background2' verticalAlign='top' {...props} />
)

export const BasicLoyaltyInfo = ({ color = 'highContrast', ...props }) => {
  const order = useContext(OrderCtx)
  const channel = useContext(Channel.Context)
  const loyalty = R.prop('zuppler_loyalty_info', order)

  if (!loyalty || !loyalty.current_tier) return null

  const { current_tier, points_earned, points_spent, reward_amount } = loyalty

  return (
    <Box color={color} {...props}>
      <Grid>
        <Row sizes={[100, null]}>
          <Cell style={{ verticalAlign: 'top' }}>
            <Text fontSize={5} fontWeight={3} opacity={0.7}>
              Loyalty
            </Text>
          </Cell>
          <Cell textAlign='right'>
            <Text fontSize={4} fontWeight={2} lineHeight='1.5em'>
              Congrats! You earned: {points_earned} points
            </Text>
            {points_spent > 0 && (
              <Text lineHeight='1.5em'>
                and saved ${reward_amount / 100} by redeeming {points_spent} points
              </Text>
            )}
            <Text lineHeight='1.5em'>Your Tier Status: {current_tier}</Text>
          </Cell>
        </Row>
        <Row>
          <Cell span={2} pt={3} textAlign='right'>
            <Text fontSize={2} fontStyle='italic' lineHeight='1.5em'>
              An update on your loyalty status will be sent to you shortly!
            </Text>
            <Link
              color={color}
              lineHeight='1.5em'
              fontSize={2}
              href={toLoyaltyDashboardUrl(order, channel, params.template_type)}>
              View your dashboard
            </Link>
          </Cell>
        </Row>
      </Grid>
    </Box>
  )
}

export const LoyaltyDashboardButton = ({ as = 'button', tab = '', ...props }) => {
  // const order = useContext(OrderCtx)
  // const channel = useContext(Channel.Context)
  const params = useContext(Params.Context)
  const _shortenDashboardCtx = useContext(ShortenDashboardOrderCtx)
  const Comp = as === 'link' ? Link : Button

  // return <Comp href={toLoyaltyDashboardUrl(order, channel, params.template_type, tab)} {...props} />
  return <Comp href={params.shorten_dashboard_order_url} {...props} />
}

export const ChannelWebsiteButton = ({ as = 'button', tab = '', ...props }) => {
  const channel = useContext(Channel.Context)
  if (!channel) return null
  const Comp = as === 'link' ? Link : Button

  return <Comp href={channel.url} {...props} />
}

const giftCardsUrl = path => process.env.GIFTCARDS_SVC + '/' + path

function cardUrl(code) {
  return `${giftCardsUrl('generate_qrcode')}/${code}.png`
}

export const GiftCardDisplay = props => {
  const params = useContext(GiftCardsCtx)
  const card = useContext(GiftCardCtx)
  // console.log("BSDBG: cards + card", params, card)

  if (!params || !params.gift_cards || !card) return null

  const cards = params.gift_cards
  const inStore = props.inStore || false

  return (
    <Box
      width={320}
      height={189}
      bg='brand'
      style={{
        position: 'relative',
        borderRadius: '16px',
        overflow: 'hidden',
        backgroundImage: `url("${card.logo_image}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
      <Box bg='white' borderRadius={2} p='8px' mx='24px' mt='32px'>
        <Text fontWeight={2} fontSize={2} fontStyle='italic' textAlign='center' letterSpacing='1px'>
          {card.unique_code}
        </Text>
      </Box>
      <Box style={{ textAlign: 'right' }} mx='24px' mt='10px' height='56px'>
        {!inStore && !!cards.definition && !!cards.definition.logo_image && (
          <img src={cards.definition.logo_image} style={{ height: '56px', width: 'auto' }} />
        )}
        {inStore && (
          <img src={cardUrl(card.unique_code)} style={{ height: '56px', width: 'auto' }} />
        )}
      </Box>
      <Box mx='24px' mt='10px'>
        <Text
          textAlign='right'
          fontSize={5}
          fontWeight={2}
          color='brandContrast'
          letterSpacing='1px'>
          {toMoney(card.balance, {})}
        </Text>
      </Box>
    </Box>
  )
}
