import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { TextTemplateInfo } from '../models'

import { useTextTemplatesSvc } from '../svc'
import {
  CampaignReporting,
  SendgridTemplateReporting,
  TextEngineRenderings,
  useReporting,
} from '../reports'

import { ReportingAPI } from '../reports'
import { TemplateReport } from '../reports'

export function ListTypeTemplates(props: { search: string; refresh: () => void }): JSX.Element {
  const [templates, setTemplates] = useState<TextTemplateInfo[]>([])
  const { type } = useParams()
  const navigate = useNavigate()
  const api = useTextTemplatesSvc()
  const [refresh, setRefresh] = useState(0)
  const onRefresh = () => setRefresh(refresh + 1)

  const reporting = useReporting(type)

  useEffect(() => {
    api.searchTemplates(type as string).then(setTemplates)
  }, [type, refresh])

  const templateSvc = useTextTemplatesSvc()

  const setDefaultTemplate = (template: TextTemplateInfo) => {
    const oldDefault = templates.find(tt => tt.default)
    if (oldDefault) {
      templateSvc
        .saveTemplate({ ...oldDefault, default: false })
        .then(() => templateSvc.saveTemplate({ ...template, default: true }))
        .then(onRefresh)
    } else {
      templateSvc.saveTemplate({ ...template, default: true }).then(props.refresh)
    }
  }

  const defaultTemplate = useMemo(() => templates.find(t => t.default), [templates, type])
  const otherTemplates = useMemo(() => templates.filter(t => !t.default), [templates, type])
  const searchResults = useMemo(
    () =>
      props.search.length
        ? templates.filter(t => t.name.toLowerCase().includes(props.search.toLowerCase()))
        : [],
    [props.search, type, templates]
  )

  if (!!!templates.length) return <>loading...</>

  return (
    <>
      <style>{`
        .make-default { visibility: hidden; }
        td:hover .make-default { visibility: visible; }
      `}</style>
      <div className='row'>
        <div className='span12'>
          <h2>{type} Templates</h2>
          <div className='button-group'>
            <button className='btn' onClick={() => navigate('new')}>
              Add New Template here
            </button>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className={reporting.hasReports() ? 'span8' : 'span12'}>
          {searchResults.length > 0 && (
            <TemplateList
              title={'Best matches'}
              templates={searchResults}
              reporting={reporting}
              onChangeDefaultTemplate={setDefaultTemplate}
            />
          )}
          {defaultTemplate && (
            <TemplateList
              title={'Default Template'}
              templates={[defaultTemplate]}
              reporting={reporting}
              onChangeDefaultTemplate={setDefaultTemplate}
            />
          )}
          {otherTemplates.length > 0 && (
            <TemplateList
              title={'Other Templates'}
              templates={otherTemplates}
              reporting={reporting}
              onChangeDefaultTemplate={setDefaultTemplate}
            />
          )}
        </div>
        {reporting.hasReports() && (
          <div
            className='span4'
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 60px)',
              overflowX: 'hidden',
              overflowY: 'auto',
            }}>
            <h4>{type} Report</h4>
            <TextEngineRenderings reporting={reporting} />
            <SendgridTemplateReporting reporting={reporting} />
            <CampaignReporting reporting={reporting} />
          </div>
        )}
      </div>
    </>
  )
}

function TemplateList(props: {
  title: string
  templates: TextTemplateInfo[]
  reporting: ReportingAPI
  onChangeDefaultTemplate: (t: TextTemplateInfo) => void
}): JSX.Element {
  return (
    <>
      <h4>{props.title}</h4>
      <table className='table table-hover table-striped' style={{ marginTop: '15px' }}>
        <tbody>
          {props.templates.map((i: TextTemplateInfo) => {
            return (
              <tr key={i.id}>
                <td>
                  {i.default && (
                    <span
                      className='label label-important'
                      style={{ marginRight: '1ex' }}
                      title='This template is going to be used unless there is a channel mapping'>
                      <i className='icon icon-star'></i>
                    </span>
                  )}
                  <Link to={`${i.id}/edit`}>{i.name}</Link>
                  {!i.default && (
                    <button
                      className='btn btn-small pull-right make-default'
                      onClick={() => props.onChangeDefaultTemplate(i)}>
                      Use as default
                    </button>
                  )}
                  <TemplateReport reporting={props.reporting} name={i.name} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
