import * as R from 'ramda'

import { consume, provideIf, formatDate, formatTime } from './utils'
import { createContext } from './context'

import Params from './params'

export const PromoCtx = createContext('PromoCtx')

export default {
  Available: provideIf(
    ['discount'],
    Params.Context,
    PromoCtx,
    ['discount'],
    R.compose(R.not, R.isNil)
  ),
  Discount: {
    Code: consume(['code'], PromoCtx),
    Badge: consume(['badge'], PromoCtx),
    Title: consume(['title'], PromoCtx),
    ExpirationDate: consume(['end_time'], PromoCtx, formatDate),
    ExpirationDateTime: consume(['end_time'], PromoCtx, formatTime),
  },
}
