import React, { useEffect, useState } from 'react'
import { TextTemplate, TextTemplateInfo } from './models'
import { useTextTemplatesSvc } from './svc'

import { getCompiledCode } from '../compile'

export default function Migration(): JSX.Element {
  const api = useTextTemplatesSvc()
  const [templates, setTemplates] = useState<TextTemplateInfo[]>([])

  useEffect(() => {
    api.searchTemplates().then(setTemplates)
  }, [])

  if (!!!templates.length) return <>loading...</>
  return <MigrateTemplates templates={templates} />
}

function MigrateTemplates(props: { templates: TextTemplateInfo[] }): JSX.Element {
  const api = useTextTemplatesSvc()

  const [running, setRunning] = useState(false)

  const [templates, setTemplates] = useState(
    props.templates.map(t => ({ ...t, completed: false, time: 0 }))
  )

  const compileAndSave = async (t: TextTemplate): Promise<number> => {
    // console.log('compileAndSave', t.id, t.name, t.type)
    const start = new Date().getTime()
    if (t.subject) {
      t.compiled_subject = getCompiledCode(t.subject)
    }
    if (t.content) {
      t.compiled_content = getCompiledCode(t.content)
    }
    if (t.short_content) {
      t.compiled_short_content = getCompiledCode(t.short_content)
    }
    if (t.receipt_content) {
      t.compiled_receipt_content = getCompiledCode(t.receipt_content)
    }
    if (t.print_content) {
      t.compiled_print_content = getCompiledCode(t.print_content)
    }
    // console.log('Compiled template', t)
    const duration = new Date().getTime() - start
    await api.saveTemplate(t)
    return await Promise.resolve(duration)
  }

  const startMigration = (index: number): void => {
    setRunning(true)
    if (index < templates.length) {
      api
        .loadTemplateDetails(templates[index])
        .then(compileAndSave)
        .then((duration: number) => {
          templates[index].completed = true
          templates[index].time = duration
          setTemplates([...templates])
          setTimeout(() => startMigration(index + 1), 10)
        })
    } else {
      setRunning(false)
    }
  }

  return (
    <div className='row-fluid'>
      <h1>Compile all templates</h1>
      <div className='btn-group'>
        <button onClick={() => startMigration(0)} className='btn btn-primary' disabled={running}>
          Start Migration
        </button>
      </div>
      <div></div>
      <ul className='list-inline'>
        {templates.map(cs => (
          <li key={`${cs.id}-${cs.completed}`}>
            {cs.completed ? <i className='icon-check' /> : <i className='icon-unchecked' />}{' '}
            {cs.completed && `${cs.time}ms`} {cs.type} {cs.type} / {cs.name}
          </li>
        ))}
      </ul>
    </div>
  )
}
