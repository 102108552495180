import { CartCharge, Maybe, Order, OrderItem } from './models'
import * as R from 'ramda'

type HItemAdjustments<T> = Partial<{
  parent_id: number | null
  id: number
  adjustments: Maybe<T[]>
}>

function addHierchyAdjustments<T>(all: HItemAdjustments<T>[]): T[] {
  return all.map(
    (i: HItemAdjustments<T>): HItemAdjustments<T> => {
      const children = addHierchyAdjustments(all.filter(x => x.parent_id == i.id)) as T[]
      if (children.length) i.adjustments = children
      return i
    }
  ) as T[]
}

// This makes an hierarchy of charges and items
export function applyOrderAdjustmentsHierarchy(order: Order): Order {
  const processAdjustments = {
    carts: {
      cartCharges: R.compose(
        R.filter((x: CartCharge) => !x.parent_id),
        (charges: CartCharge[]) => addHierchyAdjustments<CartCharge>(charges)
      ),
      items: R.compose(
        R.filter((x: OrderItem) => !x.parent_id),
        (items: OrderItem[]) => addHierchyAdjustments<OrderItem>(items)
      ),
    },
  }
  const newOrder = R.evolve(processAdjustments, order)
  return newOrder
}
