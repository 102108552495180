import React from 'react'
import * as R from 'ramda'

import {
  consume,
  consume2,
  provide,
  provideIf,
  toMoney,
  toCeil,
  toItemQuantity,
  toCount,
  formatTime2,
  formatCardInfo,
  toExtraFields,
  ExtraFields,
  toShortId,
  provideFromJSON,
  formatTime,
  consume3,
  addParamsToUrl,
} from './utils'

import Base64 from '../utils/base64'

import OrderPayments from './payments'
import PromoDiscount from './promo-discount'
import Restaurant from './restaurant'

import Params from './params'
import {
  Channel,
  EditOrderAction,
  Maybe,
  OrderAdjustment,
  OrderItem,
  TemplateParams,
} from '../data/models'
import { Invoice } from '../data/invoice'

import ChannelCtx from './channel'
import { Span } from './ui/primitives'
import { createContext, useContext } from './context'

export interface Order {
  uuid: string
  favorite: boolean
  ivrCode: string
  state: string
  createdAt: string
  carts: {
    channelId: number
    integrationId: string
    restaurantId: number
    integrationUrl: string
    customer: {
      name: string
      email: string
      phone: string
      marketing_opt_in: boolean
    }
    discounts: {
      id: number
    }
    instructions: string
    items: {
      id: number
      category: string
      comments: string
      name: string
      quantity: number
      item_total: number
      serving_qty: number
      serving_label: string
      modifiers: {
        name: string
        options: {
          id: number
          name: string
          quantity: number
          total: number
        }
      }
    }
    settings: {
      service: {
        id: number
        label: string
        address: {
          full: string
          delivery_instructions: string
        }
      }
      time: {
        id: number
        value: string
      }
      curbsideInfo: string
      restaurant_location_address: { full: string }
      tender: {
        id: number
        value: {
          card_holder_name: string
          card_last_digits: string
          type: string
        }
        label: string
      }
    }
    extra_fields: string
    cartCharges: {
      id: number
      name: string
      amount: number
      taxable: boolean
      parent_id: number | null
    }
  }
  payment_info: {
    authorization: string
    date_time: string
  }
  totals: {
    delivery: number
    discount: number
    tender_discount: number
    service: number
    subtotal: number
    tax: number
    included_tax: number
    tip: number
    total: number
    hidden: number
  }
  loyalty_rewards: LoyaltyInfo[]
  zuppler_loyalty_info: ZupplerLoyaltyInfo
}

export interface ZupplerLoyaltyInfo {
  current_tier: string | null
  points_earned: number | null
  points_spent: number | null
  reward_amount: number | null
}

interface LoyaltyInfo {
  label: string
  points: number
  provider: string
}

interface WorkflowLog {
  from_node: string
  to_node: string
  time: string
  transition: string
  transition_type: string
  initiator: string
}

interface WorkflowNode {
  id: string
  name: string
  description: string
}

export const OrderCtx = createContext<Order | null>('OrderCtx')

export const ShortenOrderCtx = createContext<string>('OrderCtx:ShortenUrl')
export const ShortenPromoOrderCtx = createContext<string>('OrderCtx:ShortenPromoUrl')
export const ShortenFeedbackOrderCtx = createContext<string>('OrderCtx:ShortenFeedbackUrl')

export const ShortenUnsubscribeOrderCtx = createContext<string>('OrderCtx:ShortenUnsubscribeUrl')
export const ShortenApplyPromoOrderCtx = createContext<string>('OrderCtx:ShortenApplyPromoUrl')
export const ShortenLoyaltyOrderCtx = createContext<string>('OrderCtx:ShortenLoyaltyUrl')
export const ShortenIntegrationOrderCtx = createContext<string>('OrderCtx:ShortenIntegrationUrl')
export const ShortenDashboardOrderCtx = createContext<string>('OrderCtx:ShortenDashboardUrl')
export const ShortenOrderFeedbackCtx = createContext<string>('OrderCtx:ShortenOrderFeedback')
export const ShortenOrderReceiptCtx = createContext<string>('OrderCtx:ShortenReceiptUrl')
export const ShortenCSUrlCtx = createContext<string>('OrderCtx:ShortenCSUrl')
export const ShortenInvoiceCtx = createContext<string>('OrderCtx:ShortenInvoiceUrl')

export const CartConfig = createContext('CartConfig', null)
export const AdjustmentsCtx = createContext<OrderAdjustment[]>('AdjustmentsCtx', [])
export const AdjustmentActionsCtx = createContext<EditOrderAction[]>('AdjustmentActionsCtx', [])

export const LoyaltyCtx = createContext('LoyaltyCtx')
export const UserCtx = createContext('UserCtx', null)
export const ItemCtx = createContext<OrderItem | null>('ItemCtx')
const ModifierCtx = createContext('ModifierCtx')
export const OptionCtx = createContext('OptionCtx')
const DeliveryCtx = createContext<null | { full: string; delivery_instructions: string }>(
  'DeliveryCtx'
)
const PickupCtx = createContext<null | { full: string }>('PickupCtx')
const DineinCtx = createContext<null | { full: string }>('DineinCtx')
const CurbsideCtx = createContext<null | { curbsideInfo: string }>('CurbsideCtx')
const WorkflowCtx = createContext<null | WorkflowLog[]>('WorkflowCtx')
export const ZLoyaltyCtx = createContext('ZLoyaltyCtx')
const PaidWithRewardCtx = createContext('PaidWithRewardCtx')

const ExtraFieldsCtx = createContext<ExtraFields | null>('ExtraFieldsCtx')
const ExtraFieldsGroupCtx = createContext('ExtraFieldsGroupCtx')

export const createRedirectUrl = (url: string, path: string, utm_campaign?: string): string => {
  // if (!utm_campaign) {
  //   throw `${url} ${path}`
  // }
  const res = addParamsToUrl(
    url,
    utm_campaign ? { zru: path, utm_campaign: utm_campaign } : { zru: path }
  )
  // url +
  // (url.indexOf('?') === -1 ? '?' : '&') +
  // 'zru=' +
  // encodeURIComponent(path) +
  // (utm_campaign ? '&utm_campaign=' + encodeURIComponent(utm_campaign) : '')
  // console.log('createRedirectUrl', res)
  return res
}

export const toDashboardUrl = (order: Order, templateType: string): string =>
  order
    ? createRedirectUrl(
        order.carts.integrationUrl,
        `/dashboard/pending/orders/${order.uuid}`,
        templateType
      )
    : '#'

export const toConfirmationUrl = (
  order: Order,
  params: TemplateParams,
  _shortenUrl: string
): string => {
  return order
    ? params.order_confirmation_url ||
        createRedirectUrl(
          order.carts.integrationUrl,
          `/receipt/${order.uuid}`,
          params.template_type
        )
    : '#'
}

export const toFeedbackUrl = (order: Order, template_type: string): string =>
  order
    ? createRedirectUrl(order.carts.integrationUrl, `/feedback/${order.uuid}`, template_type)
    : '#'

export const toUnsubscribeUrl = (order: Order, params: TemplateParams): string =>
  order && params.template_type
    ? createRedirectUrl(
        order.carts.integrationUrl,
        `/unsubscribe/${Base64.encode(
          JSON.stringify({
            uuid: order.uuid,
            template_type: params.template_type,
            template_name: params.template_name,
            template_category: params.template_category || 0,
          })
        )}`,
        params.template_type
      )
    : '#'

export const toApplyPromoDiscount = (
  order: Order,
  params: {
    discount: { code: string }
    promo_integration_url?: string
    channel: Channel
    template_type: string
  }
): string =>
  order
    ? params.promo_integration_url ||
      createRedirectUrl(
        order.carts.integrationUrl,
        `${toRestaurantPath(params.channel)}/promo/${params.discount.code}`,
        params.template_type
      )
    : '#'

export const toLoyaltyDashboardUrl = (
  order: Order,
  channel: Channel,
  template_type: string
): string =>
  order
    ? createRedirectUrl(
        order.carts.integrationUrl,
        `${toRestaurantPath(channel)}/account/loyalty`,
        template_type
      )
    : '#'

export const toIntegrationUrl = (order: Order, channel: Channel, templateType: string): string =>
  order
    ? createRedirectUrl(order.carts.integrationUrl, toRestaurantPath(channel), templateType)
    : '#'

export const toRestaurantPath = (channel: Channel) =>
  channel
    ? `/restaurants/${channel.integrations[0].remote_id}/${channel.integrations[0].restaurant_id}`
    : ''

export const toCustomerServiceUrl = (order: Order): string =>
  `https://customer-service.zuppler.com/#/orders/${R.prop('uuid', order)}`

export const toInvoiceUrl = (invoice: Invoice): string => {
  const id = R.prop('id', invoice)
  const type = R.prop('invoiceable_type', invoice)
  return `https://customer-service.zuppler.com/#/statements/${type}/${id}`
}

const nodeIdToNodeName = (nodeId: string, nodes: WorkflowNode[]): string => {
  const node = nodes.find(n => n.id == nodeId)
  return node ? node.name : nodeId
}
const nodeIdToNodeDescription = (nodeId: string, nodes: WorkflowNode[]): string => {
  const node = nodes.find(n => n.id == nodeId)
  return node ? node.description : nodeId
}

const orderServiceToPhone = (serviceId: string, services: any): string => {
  return R.compose(
    R.defaultTo('---'),
    R.path(['contact', 'phone']),
    R.find(R.propEq('id', serviceId))
  )(services) as string
}

const firstWord = R.compose(R.head, R.split(' ')) as (s: string) => string

const toAddMarkupMoney = (markupField: string[], nextFormatter = toMoney) => (
  value: number,
  markup: boolean,
  params: any,
  data: any,
  _data2: any
) => nextFormatter(markup ? value + (R.path<number>(markupField, data) || 0) : value, params, data)

const toSubMarkupMoney = (markupField: string[], nextFormatter = toMoney) => (
  value: number,
  markup: boolean,
  params: any,
  data: any,
  _data2: any
) => nextFormatter(markup ? value - (R.path<number>(markupField, data) || 0) : value, params, data)

const toComputeConvFee = (fields: string[], nextFormatter = toMoney) => (
  value: number, //hidden field value
  conv_fee: boolean,
  menuMarkupHidden: boolean,
  params: any,
  order: any,
  cartConfig: any,
  channel: any
) => {
  if (conv_fee) {
    return nextFormatter(value, params, order)
  } else {
    return nextFormatter(0, params, order)
  }
}

const computeOrderTotal = (nextFormatter = toMoney) => (
  value: number, //hidden field value
  conv_fee: boolean,
  menuMarkupHidden: boolean,
  params: any,
  order: any,
  cartConfig: any,
  channel: any
) => {
  if (menuMarkupHidden && !cartConfig.markup) {
    return nextFormatter(value - order.totals.total_markup, params, order)
  } else {
    return nextFormatter(value, params, order)
  }
}

const computeServiceTotal = (nextFormatter = toMoney) => (
  value: number, //hidden field value
  conv_fee: boolean,
  menuMarkupHidden: boolean,
  params: any,
  order: any,
  cartConfig: any,
  channel: any
) => {
  if (menuMarkupHidden || cartConfig.markup) {
    return nextFormatter(value - order.totals.total_markup, params, order)
  } else {
    return nextFormatter(value, params, order)
  }
}
function HItems(props: { children: JSX.Element }): JSX.Element {
  const order: Maybe<Order> = useContext(OrderCtx)
  if (!order) {
    return <></>
  }
  const items = R.path(['carts', 'items'], order) as OrderItem[]

  const HItem = (props: { item: OrderItem; children: JSX.Element }): JSX.Element => {
    return (
      <>
        <ItemCtx.ctx.Provider value={props.item as OrderItem}>
          {props.children}
        </ItemCtx.ctx.Provider>
        {props.item.adjustments &&
          props.item.adjustments
            .filter((ci: OrderItem) => ci.price >= 0)
            .map((ci: OrderItem) => (
              <ItemCtx.ctx.Provider key={ci.id} value={ci as OrderItem}>
                {props.children}
              </ItemCtx.ctx.Provider>
            ))}
      </>
    )
  }

  return (
    <>
      {items.map((i: OrderItem) => (
        <HItem key={i.id} item={i}>
          {props.children}
        </HItem>
      ))}
    </>
  )
}

function WithDeleted(props: { children: JSX.Element }): JSX.Element {
  const currentItem = useContext(ItemCtx)
  if (currentItem) {
    return (
      <Span
        style={{
          textDecoration:
            currentItem.adjustments &&
            currentItem.adjustments.find(x => x.price == -currentItem.price)
              ? 'line-through'
              : 'none',
        }}>
        {props.children}
      </Span>
    )
  } else {
    return <>{props.children}</>
  }
}

export default {
  Context: OrderCtx,
  ShortId: consume(['uuid'], OrderCtx, toShortId),
  ConfCode: consume(['ivrCode'], OrderCtx),
  DashboardUrl: consume([], OrderCtx, toDashboardUrl),
  IntegrationUrl: consume2([], OrderCtx, [], ChannelCtx.Context, toIntegrationUrl),
  FeedbackUrl: consume2([], OrderCtx, ['template_type'], Params.Context, toFeedbackUrl),
  UnsubscribeUrl: consume2([], OrderCtx, [], Params.Context, toUnsubscribeUrl),
  State: consume(['state'], OrderCtx),
  ConfirmationUrl: consume2([], OrderCtx, [], Params.Context, toConfirmationUrl),
  CustomerServiceUrl: consume([], OrderCtx, toCustomerServiceUrl),
  Customer: {
    Name: consume(['carts', 'customer', 'name'], OrderCtx),
    FirstName: consume(['carts', 'customer', 'name'], OrderCtx, firstWord),
    Email: consume(['carts', 'customer', 'email'], OrderCtx),
    Phone: consume(['carts', 'customer', 'phone'], OrderCtx),
    RemoteId: consume(['carts', 'customer', 'remote_id'], OrderCtx),
    IsLoggedIn: provideIf(
      ['carts', 'customer', 'user_id'],
      OrderCtx,
      UserCtx,
      ['carts', 'customer', 'user_id'],
      R.compose(R.not, R.isNil)
    ),
    IsLoggedOut: provideIf(
      ['carts', 'customer', 'user_id'],
      OrderCtx,
      UserCtx,
      ['carts', 'customer', 'user_id'],
      R.isNil
    ),
  },
  WF: {
    Logs: provide(['order_workflow', 'logs'], OrderCtx, WorkflowCtx, R.reverse),
    // Current: provide(['order_workflow', 'logs'], OrderCtx, WorkflowCtx, R.compose(R.of, R.head)),
    Id: consume(['to_node'], WorkflowCtx),
    Name: consume2(
      ['to_node'],
      WorkflowCtx,
      ['workflow', 'content', 'nodes'],
      Restaurant.Context,
      nodeIdToNodeName
    ),
    Description: consume2(
      ['to_node'],
      WorkflowCtx,
      ['workflow', 'content', 'nodes'],
      Restaurant.Context,
      nodeIdToNodeDescription
    ),
    Current: {
      Id: consume(['workflow_node_id'], OrderCtx),
      Name: consume2(
        ['workflow_node_id'],
        OrderCtx,
        ['workflow', 'content', 'nodes'],
        Restaurant.Context,
        nodeIdToNodeName
      ),
      Description: consume2(
        ['workflow_node_id'],
        OrderCtx,
        ['workflow', 'content', 'nodes'],
        Restaurant.Context,
        nodeIdToNodeDescription
      ),
    },
  },
  Loyalties: provide(['loyalty_rewards'], OrderCtx, LoyaltyCtx),
  Loyalty: {
    Label: consume(['label'], LoyaltyCtx),
    Points: consume(['points'], LoyaltyCtx),
    Provider: consume(['provider'], LoyaltyCtx),
  },
  Service: {
    Id: consume(['carts', 'settings', 'service', 'id'], OrderCtx),
    Label: consume(['carts', 'settings', 'service', 'label'], OrderCtx),
    Phone: consume2(
      ['carts', 'settings', 'service', 'id'],
      OrderCtx,
      ['services'],
      Restaurant.Context,
      orderServiceToPhone
    ),
    Delivery: provideIf(
      ['carts', 'settings', 'service', 'address'],
      OrderCtx,
      DeliveryCtx,
      ['carts', 'settings', 'service', 'id'],
      R.equals('DELIVERY')
    ),
    Pickup: provideIf(
      ['carts', 'settings', 'restaurant_location_address'],
      OrderCtx,
      PickupCtx,
      ['carts', 'settings', 'service', 'id'],
      R.equals('PICKUP')
    ),
    Dinein: provideIf(
      ['carts', 'settings', 'restaurant_location_address'],
      OrderCtx,
      DineinCtx,
      ['carts', 'settings', 'service', 'id'],
      R.equals('DINEIN')
    ),
    Curbside: provideIf(
      ['carts', 'settings'],
      OrderCtx,
      CurbsideCtx,
      ['carts', 'settings', 'service', 'id'],
      R.equals('CURBSIDE')
    ),
  },
  Delivery: {
    FullAddress: consume(['full'], DeliveryCtx),
    Instructions: consume(['delivery_instructions'], DeliveryCtx),
  },
  Pickup: {
    Address: consume(['full'], PickupCtx),
  },
  Dinein: {
    Address: consume(['full'], DineinCtx),
  },
  Curbside: {
    Address: consume(['restaurant_location_address', 'full'], CurbsideCtx),
    Info: consume(['curbsideInfo'], CurbsideCtx),
  },
  Time: {
    Id: consume(['carts', 'settings', 'time', 'id'], OrderCtx),
    Value: consume2(
      ['carts', 'settings', 'time', 'value'],
      OrderCtx,
      ['timezone'],
      Restaurant.Context,
      formatTime2
    ),
  },
  Tender: {
    Id: consume(['carts', 'settings', 'tender', 'id'], OrderCtx),
    Label: consume(['carts', 'settings', 'tender', 'label'], OrderCtx),
    CardInfo: consume(['carts', 'settings', 'tender', 'value'], OrderCtx, formatCardInfo),
  },
  EF: {
    Groups: provide(['carts', 'extra_fields'], OrderCtx, ExtraFieldsCtx, toExtraFields),
    Group: {
      Name: consume(['group'], ExtraFieldsCtx),
      Fields: provide(['fields'], ExtraFieldsCtx, ExtraFieldsGroupCtx),
    },
    Field: {
      Name: consume(['name'], ExtraFieldsGroupCtx),
      Label: consume(['label'], ExtraFieldsGroupCtx),
      Value: consume(['value'], ExtraFieldsGroupCtx),
    },
  },
  Items: HItems,
  ItemsQuantity: consume(['carts', 'items'], OrderCtx, toCount),
  ItemsCount: consume(['carts', 'items'], OrderCtx, toItemQuantity),
  Item: {
    Name: consume(['name'], ItemCtx),
    Alias: consume(['alias'], ItemCtx),
    Menu: consume(['menu'], ItemCtx),
    Category: consume(['category'], ItemCtx),
    Total: consume2(
      ['item_total'],
      ItemCtx,
      ['markup'],
      CartConfig,
      toAddMarkupMoney(['markup_for_item_total'])
    ),
    Quantity: consume(['quantity'], ItemCtx),
    Modifiers: provide(['modifiers'], ItemCtx, ModifierCtx),
    Comments: consume(['comments'], ItemCtx),
    WithDeleted: WithDeleted,
  },
  Modifier: {
    Name: consume(['name'], ModifierCtx),
    Options: provide(['options'], ModifierCtx, OptionCtx),
  },
  Option: {
    Name: consume(['name'], OptionCtx),
    Alias: consume(['alias'], OptionCtx),
    Quantity: consume(['quantity'], OptionCtx),
    Total: consume2(
      ['total'],
      OptionCtx,
      ['markup'],
      CartConfig,
      toAddMarkupMoney(['markup_for_total'])
    ),
  },
  Totals: {
    Total: consume3(
      ['totals', 'total'],
      OrderCtx,
      ['conv_fee'],
      CartConfig,
      ['menuMarkupHidden'],
      ChannelCtx.Context,
      computeOrderTotal()
    ),
    Delivery: consume(['totals', 'delivery'], OrderCtx, toMoney),
    Discount: consume(['totals', 'discount'], OrderCtx, toMoney),
    TenderDiscount: consume(['totals', 'tender_discount'], OrderCtx, toMoney),
    Hidden: consume(['totals', 'hidden'], OrderCtx, toMoney),
    //Service: consume(['totals', 'service'], OrderCtx, toMoney),
    // Service: consume2(
    //   ['totals', 'service'],
    //   OrderCtx,
    //   ['markup'],
    //   CartConfig,
    //   toSubMarkupMoney(['totals', 'total_markup'])
    // ),
    Service: consume3(
      ['totals', 'service'],
      OrderCtx,
      ['conv_fee'],
      CartConfig,
      ['menuMarkupHidden'],
      ChannelCtx.Context,
      computeServiceTotal()
    ),

    //Subtotal: consume(['totals', 'subtotal'], OrderCtx, toMoney),
    Subtotal: consume2(
      ['totals', 'subtotal'],
      OrderCtx,
      ['markup'],
      CartConfig,
      toAddMarkupMoney(['totals', 'total_markup'])
    ),
    Tax: consume(['totals', 'tax'], OrderCtx, toMoney),
    IncludedTax: consume(['totals', 'included_tax'], OrderCtx, toMoney),
    Tip: consume(['totals', 'tip'], OrderCtx, toMoney),
    ConvFee: consume3(
      ['totals', 'hidden'],
      OrderCtx,
      ['conv_fee'],
      CartConfig,
      ['menuMarkupHidden'],
      ChannelCtx.Context,
      toComputeConvFee(['totals', 'hidden'])
    ),
  },
  TotalsNC: {
    Total: consume(['totals', 'total'], OrderCtx, toCeil),
    Delivery: consume(['totals', 'delivery'], OrderCtx, toCeil),
    Discount: consume(['totals', 'discount'], OrderCtx, toCeil),
    TenderDiscount: consume(['totals', 'tender_discount'], OrderCtx, toCeil),
    Hidden: consume(['totals', 'hidden'], OrderCtx, toCeil),
    // Service: consume(['totals', 'service'], OrderCtx, toCeil),
    Service: consume2(
      ['totals', 'service'],
      OrderCtx,
      ['markup'],
      CartConfig,
      toSubMarkupMoney(['totals', 'total_markup'], toCeil)
    ),
    // Subtotal: consume(['totals', 'subtotal'], OrderCtx, toCeil),
    Subtotal: consume2(
      ['totals', 'subtotal'],
      OrderCtx,
      ['markup'],
      CartConfig,
      toAddMarkupMoney(['totals', 'total_markup'], toCeil)
    ),
    Tax: consume(['totals', 'tax'], OrderCtx, toCeil),
    IncludedTax: consume(['totals', 'included_tax'], OrderCtx, toCeil),
    Tip: consume(['totals', 'tip'], OrderCtx, toCeil),
  },
  OrderUpdates: {
    All: provide(['adjustments'], OrderCtx, AdjustmentsCtx),
    Last: provide(
      ['adjustments'],
      OrderCtx,
      AdjustmentsCtx,
      (adj: OrderAdjustment[]): OrderAdjustment[] => (adj.length > 0 ? [adj[adj.length - 1]] : [])
    ),
    User: {
      Name: consume(['user_name'], AdjustmentsCtx),
      Email: consume(['user_email'], AdjustmentsCtx),
    },
    State: consume(['state'], AdjustmentsCtx),
    Comments: consume(['comments'], AdjustmentsCtx),
    Actions: {
      Iterate: provideFromJSON(['actions'], AdjustmentsCtx, AdjustmentActionsCtx),
      Description: consume(['description'], AdjustmentActionsCtx),
    },
    CreatedAt: consume(['inserted_at'], AdjustmentsCtx, formatTime),
  },
  Payment: OrderPayments,
  Promo: PromoDiscount,
  ZupplerLoyalty: provideIf(
    ['zuppler_loyalty_info'],
    OrderCtx,
    ZLoyaltyCtx,
    ['zuppler_loyalty_info', 'current_tier'],
    R.compose(R.not, R.isNil)
  ),
  ZLoyalty: {
    CurrentTier: consume(['current_tier'], ZLoyaltyCtx),
    PointsEarned: consume(['points_earned'], ZLoyaltyCtx),
    PointsSpent: consume(['points_spent'], ZLoyaltyCtx),
    RewardAmount: consume(['reward_amount'], ZLoyaltyCtx, toMoney),
    PaidWithReward: provideIf(
      ['points_spent'],
      ZLoyaltyCtx,
      PaidWithRewardCtx,
      ['points_spent'],
      R.lt(0)
    ),
    DashboardUrl: consume3(
      [],
      OrderCtx,
      [],
      ChannelCtx.Context,
      ['template_type'],
      Params.Context,
      toLoyaltyDashboardUrl
    ),
  },
}

// current_tier: "Returning Customer"
// points_earned: 381
// points_spent: 0
// reward_amount: 0
