import * as R from 'ramda'
import fetch from 'node-fetch'

const transform = R.curry((transformer, data) => {
  const value = transformer(data)
  const error = R.head(data.errors || [])
  return !data.errors ? Promise.resolve(value) : Promise.reject(error)
})

const jsonHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
}

const post = params =>
  fetch(params.url, {
    method: 'POST',
    headers: jsonHeaders,
    body: JSON.stringify(R.dissoc('url', params)),
  })

const ordersUrl = path => process.env.ORDERS_SVC + path
const restaurantsUrl = path => process.env.RESTAURANTS_SVC + path
const channelUrl = path => process.env.RESTAURANTS_SVC + path
const paymentsUrl = path => process.env.PAYMENTS_SVC + path
const invoiceUrl = path => process.env.PAYMENTS_SVC + path

export const ordersApiGqlCall = (id, query, variables) => {
  const data = {
    url: ordersUrl('/graphql'),
    operationName: id,
    query: `query ${id}($order_uuid: ID!) { ${query} }`,
    variables: JSON.stringify(variables),
  }

  // console.log('Loading order', id, variables, data.url)

  return post(data)
    .then(res =>
      res.ok
        ? res.json()
        : Promise.reject({ message: `Cannot reach server: ${data.url} ${res.status}`, error: {} })
    )
    .then(transform(R.path(['data', 'order'])))
}

export const paymentsApiGqlCall = (id, query, variables) => {
  const data = {
    url: paymentsUrl('/graphql'),
    operationName: id,
    query: `query ${id}($order_uuid: ID!) { ${query} }`,
    variables: JSON.stringify(variables),
  }

  // console.log('Loading payments order', id, variables, data.url)

  return post(data)
    .then(res =>
      res.ok
        ? res.json()
        : Promise.reject({ message: `Cannot reach server: ${data.url} ${res.status}`, error: {} })
    )
    .then(transform(R.path(['data', 'order'])))
}

export const restaurantsApiGqlCall = (id, query, variables) => {
  const data = {
    url: restaurantsUrl('/graphql'),
    operationName: id,
    query: `query ${id}($restaurant_id: Int!) { ${query} }`,
    variables: JSON.stringify(variables),
  }

  // console.log('Loading restaurant', id, variables, data.url)

  return post(data)
    .then(res =>
      res.ok
        ? res.json()
        : Promise.reject({ message: `Cannot reach server: ${data.url} ${res.status}`, error: {} })
    )
    .then(transform(R.path(['data', 'restaurant'])))
}

export const menusApiGqlCall = (id, query, variables) => {
  const data = {
    url: restaurantsUrl('/graphql'),
    operationName: id,
    query: `query ${id}($restaurant_id: Int!) { ${query} }`,
    variables: JSON.stringify(variables),
  }

  // console.log('Loading restaurant', id, variables, data.url)

  return post(data)
    .then(res =>
      res.ok
        ? res.json()
        : Promise.reject({ message: `Cannot reach server: ${data.url} ${res.status}`, error: {} })
    )
    .then(transform(R.path(['data', 'menus'])))
}

export const channelApiGqlCall = (id, query, variables) => {
  const data = {
    url: channelUrl('/graphql'),
    operationName: id,
    query: `query ${id}($channel_id: ID!, $integration_id: ID!, $settingsType: String!) { ${query} }`,
    variables: JSON.stringify(variables),
  }

  // console.log('Loading channel', id, variables, data.url)

  return post(data)
    .then(res =>
      res.ok
        ? res.json()
        : Promise.reject({ message: `Cannot reach server: ${data.url} ${res.status}`, error: {} })
    )
    .then(transform(R.path(['data', 'channel'])))
}

export const channelByIDApiGqlCall = (id, query, variables) => {
  const data = {
    url: channelUrl('/graphql'),
    operationName: id,
    query: `query ${id}($channel_id: ID!, $settingsType: String!) { ${query} }`,
    variables: JSON.stringify(variables),
  }

  // console.log('Loading channel', id, variables, data.url)

  return post(data)
    .then(res =>
      res.ok
        ? res.json()
        : Promise.reject({ message: `Cannot reach server: ${data.url} ${res.status}`, error: {} })
    )
    .then(transform(R.path(['data', 'channel'])))
}

export const loadInvoiceByID = (invoiceId, authToken) => {
  const token = process.env.API_TOKEN
  const url = invoiceUrl(`/v5/invoices/${invoiceId}`)
  const headers = { 'content-type': 'application/json' }
  if (token) {
    headers['zuppler-authorization'] = token
  } else if (authToken) {
    headers['authorization'] = `Bearer ${authToken}`
  }
  return fetch(url, {
    method: 'GET',
    headers,
  }).then(res => res.json())
}
