import React, { useState, useReducer, useContext, useEffect } from 'react'
import * as R from 'ramda'

import Editor from '../editor'
import { TemplateParams } from '../data/models'
import ServerPreview from './server'
import {
  editorForType,
  TemplateEditorContext,
  createContext,
  TemplateEditorContextType,
  contextsToParams,
} from './contexts'
import { TemplateReducerParam, templateParamsReducer } from './params'
import { TextTemplate } from './models'
import { useTextTemplatesSvc } from './svc'

import usePersistentState from '../utils/use-persistent-state'

interface TemplateEditorProps {
  onClose: () => void
  template: TextTemplate
  templateTypes: string[]
}

import cx from 'classnames'
import { AppPropsCtx } from '../utils/request'
import {
  createScriptDiff,
  recordUpdate,
  auditObjDesc,
  AUDIT_RESOURCE_TYPE,
  AuditResource,
} from '../audit/svc'
import { LogPanels as AuditLogs } from '../audit/logs'

type Tabs =
  | 'settings'
  | 'subject'
  | 'content'
  | 'server'
  | 'print'
  | 'receipt'
  | 'message'
  | 'thermal'
  | 'audit'

export default function TemplateEditor(props: TemplateEditorProps): JSX.Element {
  const api = useTextTemplatesSvc()
  const appProps = useContext(AppPropsCtx)
  const [templateType, setTemplateType] = useState(props.template.type)
  const [system, setSystem] = useState(props.template.default)
  const [name, setName] = useState(props.template.name)
  const [subject, setSubject] = useState(props.template.subject || '')
  const [compiledSubject, setCompiledSubject] = useState(props.template.compiled_subject || '')
  const [content, setContent] = useState(props.template.content || '')
  const [compiledContent, setCompiledContent] = useState(props.template.compiled_content || '')
  // const [receiptContent, setReceiptContent] = useState(props.template.receipt_content || '')
  const [printContent, setPrintContent] = useState(props.template.print_content || '')
  const [compiledPrintContent, setCompiledPrintContent] = useState(
    props.template.compiled_print_content || ''
  )
  const [shortContent, setShortContent] = useState(props.template.short_content || '')
  const [compiledShortContent, setCompiledShortContent] = useState(
    props.template.compiled_short_content || ''
  )
  const [receiptContent, setReceiptContent] = useState(props.template.receipt_content || '')
  const [compiledReceiptContent, setCompiledReceiptContent] = useState(
    props.template.compiled_receipt_content || ''
  )
  const [contexts, setContexts] = usePersistentState<TemplateEditorContext[]>(
    'text-templates-contexts',
    []
  )
  const [currentTab, setCurrentTab] = useState<Tabs>('settings')

  const [templateParams, updateTemplateParams] = useReducer<
    React.Reducer<TemplateParams, TemplateReducerParam>
  >(templateParamsReducer, {})

  const addContext = (type: TemplateEditorContextType) => () =>
    setContexts([...contexts, createContext(type)])

  const updateContext = (context: TemplateEditorContext): void => {
    setContexts(contexts.map((c: TemplateEditorContext) => (c.id === context.id ? context : c)))
  }

  const removeContext = (id: number): void => {
    setContexts(contexts.filter((c: TemplateEditorContext) => c.id != id))
  }

  const renderContext = (context: TemplateEditorContext): JSX.Element => {
    const Editor = editorForType(context.type)
    return (
      <Editor
        key={context.id}
        templateParams={{ ...templateParams, template_type: templateType, template_name: name }}
        dispatch={updateTemplateParams}
        saveContext={updateContext}
        onRemoveContext={removeContext}
        {...context}
      />
    )
  }

  const extractTemplateData = (t: TextTemplate): Partial<TextTemplate> => {
    return { id: t.id, name: t.name, type: t.type, default: t.default }
  }

  const scriptDiff = (s1: string, s2: string): string => {
    if (s1 != s2) {
      return createScriptDiff(s1, s2)
    } else {
      return ''
    }
  }
  const makeAuditDesc = (newTextTemplate: TextTemplate): string => {
    const desc = auditObjDesc(
      extractTemplateData(props.template),
      extractTemplateData(newTextTemplate)
    )
    return [
      desc,
      scriptDiff(props.template.subject || '', newTextTemplate.subject || ''),
      scriptDiff(props.template.content || '', newTextTemplate.content || ''),
      scriptDiff(props.template.short_content || '', newTextTemplate.short_content || ''),
      scriptDiff(props.template.print_content || '', newTextTemplate.print_content || ''),
      // scriptDiff(props.template.thermal || '', newTextTemplate.thermal || ''),
    ].join('<br/>')
  }

  const auditIt = async (newTextTemplate: TextTemplate): Promise<TextTemplate> => {
    await recordUpdate(
      appProps?.auth_token as string,
      appProps?.agent_info as string,
      String(newTextTemplate.id),
      makeAuditDesc(newTextTemplate)
    )
    return Promise.resolve(newTextTemplate)
  }

  const createTemplate = () => {
    const payload = {
      ...props.template,
      name,
      type: templateType,
      default: system,
      subject,
      compiled_subject: compiledSubject,
      content,
      compiled_content: compiledContent,
      short_content: shortContent,
      compiled_short_content: compiledShortContent,
      print_content: printContent,
      compiled_print_content: compiledPrintContent,
      receipt_content: receiptContent,
      compiled_receipt_content: compiledReceiptContent,
    }
    return payload
  }

  const onSaveTemplate = (): void => {
    if (templateType.length && name.length) {
      const payload = createTemplate()
      api
        .saveTemplate(payload)
        .then(auditIt)
        .then(props.onClose)
        .catch(err => alert(`Failed to save text template. Error ${err}`))
    } else {
      alert('Please fill in all data and then save...')
    }
  }

  const renderSettings = (): JSX.Element => (
    <div className='tab-content'>
      <div className='control-group'>
        {/* <label className='checkbox'>
          <input type='checkbox' checked={system} onChange={ev => setSystem(ev.target.checked)} />{' '}
          Use this template for all channels and notifications as default for the given type
        </label> */}

        <label htmlFor='templateType'>Template Type</label>
        <div className='controls'>
          {system && (
            <input
              type='text'
              id='templateType'
              value={templateType}
              readOnly={!!props.template.id}
              placeholder='enter type name as it will be refered in order workflows'
              onChange={ev => setTemplateType(ev.target.value)}
            />
          )}
          {!system && (
            <select
              id='templateType'
              value={templateType}
              onChange={ev => setTemplateType(ev.target.value)}>
              <option>Select template type</option>
              {props.templateTypes.map((t: string) => (
                <option key={t} selected={t == templateType}>
                  {t}
                </option>
              ))}
            </select>
          )}
        </div>

        <label htmlFor='template_name'>Template Name:</label>
        <div className='controls'>
          <input
            type='text'
            id='template_name'
            value={name}
            placeholder='template name'
            onChange={ev => setName(ev.target.value)}
          />
        </div>
      </div>
    </div>
  )

  const renderAddContext = (): JSX.Element => {
    const usedContextTypes = contexts.map(R.prop('type'))
    const hasType = (t: TemplateEditorContextType): boolean => usedContextTypes.includes(t)

    return (
      <div className='btn-group'>
        <a className='btn dropdown-toggle' href='#addContext' data-toggle='dropdown'>
          <i className='icon-add'></i> Add Context <span className='caret'></span>
        </a>
        <ul className='dropdown-menu'>
          <li className={cx({ hidden: hasType('order') })}>
            <a onClick={addContext('order')}>Order</a>
          </li>
          <li className={cx({ hidden: hasType('restaurant') })}>
            <a onClick={addContext('restaurant')}>Restaurant</a>
          </li>
          <li className={cx({ hidden: hasType('channel') })}>
            <a onClick={addContext('channel')}>Channel</a>
          </li>
          <li className={cx({ hidden: hasType('invoice') })}>
            <a onClick={addContext('invoice')}>Invoice</a>
          </li>
          <li className={cx({ hidden: hasType('reports_restaurant') })}>
            <a onClick={addContext('reports_restaurant')}>Restaurant Report</a>
          </li>
          <li className={cx({ hidden: hasType('reports_channel') })}>
            <a onClick={addContext('reports_channel')}>Channel Report</a>
          </li>
          <li>
            <a onClick={addContext('string')}>Custom Param</a>
          </li>
          <li>
            <a onClick={addContext('promo-discount')}>Promo Discount</a>
          </li>
          <li>
            <a onClick={addContext('gift_cards')}>Gift Cards</a>
          </li>
          <li>
            <a onClick={addContext('user-signup')}>User Signup</a>
          </li>
          <li>
            <a onClick={addContext('loyalty_status')}>Campaigns: Loyalty Status</a>
          </li>
          <li>
            <a onClick={addContext('loyalty_rewards')}>Campaigns: Loyalty Rewards</a>
          </li>
          <li>
            <a onClick={addContext('loyalty_bonus')}>Campaigns: Loyalty Bonus</a>
          </li>
        </ul>
      </div>
    )
  }

  const renderContextManager = (): JSX.Element => (
    <div className='row-fluid'>
      <>{contexts.map(renderContext)}</>
      {renderAddContext()}
    </div>
  )

  const renderContentEditor = (
    value: string,
    onChangeValue: (s: string) => void,
    onChangeCompiledValue: (s: string) => void
  ): JSX.Element => (
    <div className='row-fluid'>
      {renderContextManager()}

      <Editor
        size='large'
        content={value}
        onChange={(a, b) => {
          onChangeValue(a)
          onChangeCompiledValue(b)
        }}
        templateParams={templateParams}
        templateType={templateType}
        contentType={currentTab}
      />
    </div>
  )

  const renderServerPreview = (): JSX.Element => {
    const filteredParts: string[] = R.filter((s: string) => !!s && s.length > 3, [
      subject,
      content,
      printContent,
      shortContent,
      printContent,
    ])
    const filteredPartsCompiled: string[] = R.filter((s: string) => !!s && s.length > 3, [
      compiledSubject,
      compiledContent,
      compiledPrintContent,
      compiledShortContent,
      compiledPrintContent,
    ])
    return (
      <ServerPreview
        params={{ ...contextsToParams(contexts), template_type: templateType, template_name: name }}
        parts={filteredParts}
        compiled_parts={filteredPartsCompiled}
        active={currentTab == 'server'}
      />
    )
  }

  const renderTab = (id: Tabs, label: string): JSX.Element => (
    <li>
      <a
        data-toggle='tab'
        className={currentTab == id ? 'active' : ''}
        onClick={() => setCurrentTab(id)}>
        {label}
      </a>
    </li>
  )

  const renderAudit = () => {
    return (
      <AuditResource resourceType={AUDIT_RESOURCE_TYPE} resourceId={String(props.template.id)}>
        <div className='tab-pane' id='auditLogs'>
          <AuditLogs />
        </div>
      </AuditResource>
    )
  }

  return (
    <div className='row-fluid'>
      <ul className='nav nav-tabs'>
        {renderTab('settings', 'Settings')}
        {renderTab('subject', 'Email Subject')}
        {renderTab('content', 'Email Body')}
        {renderTab('print', 'Fax Print')}
        {renderTab('thermal', 'Thermal')}
        {renderTab('message', 'Messages (SMS)')}
        {renderTab('server', 'Server Preview')}
        {renderTab('audit', 'Audit')}
      </ul>

      {currentTab == 'settings' && renderSettings()}
      {currentTab == 'subject' && renderContentEditor(subject, setSubject, setCompiledSubject)}
      {currentTab == 'content' && renderContentEditor(content, setContent, setCompiledContent)}
      {currentTab == 'print' &&
        renderContentEditor(printContent, setPrintContent, setCompiledPrintContent)}
      {currentTab == 'message' &&
        renderContentEditor(shortContent, setShortContent, setCompiledShortContent)}
      {currentTab == 'thermal' &&
        renderContentEditor(receiptContent, setReceiptContent, setCompiledReceiptContent)}
      {currentTab == 'server' && renderServerPreview()}
      {currentTab == 'audit' && renderAudit()}

      <div className='btn-toolbar'>
        <div className='btn-group'>
          <button className='btn btn-danger' onClick={props.onClose}>
            Cancel
          </button>
          <button className='btn btn-primary' onClick={onSaveTemplate}>
            Save Template & Return
          </button>
        </div>
      </div>
    </div>
  )
}
