import React, { useState } from 'react'
import { TextTemplateCategory, TextTemplateType } from '../models'

import { useTextTemplatesSvc } from '../svc'

export function EditTemplateCategories(props: { templateType: TextTemplateType }): JSX.Element {
  const [value, setValue] = useState<null | number>(props.templateType.category)
  const [busy, setBusy] = useState(false)
  const api = useTextTemplatesSvc()
  const updateCategory = (category: number | null) => {
    setBusy(true)
    api.searchTemplates(props.templateType.type).then(templates => {
      Promise.all(templates.map(t => api.saveTemplate({ ...t, category })))
        .then(() => {
          setBusy(false)
          setValue(category)
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(_err => {
          alert('Cannot update category')
          setBusy(false)
        })
    })
  }
  return (
    <div className='controls'>
      <select
        id='template_category'
        name='template_category'
        value={value || ''}
        disabled={busy}
        onChange={ev => updateCategory(ev.target.value ? parseInt(ev.target.value) : null)}>
        <option value=''>-- no category --</option>
        <option value={TextTemplateCategory.order}>Order Notification</option>
        <option value={TextTemplateCategory.marketing}>Marketing & Promos</option>
      </select>
    </div>
  )
}
export function ShowTemplateCategoriesDetails(props: {
  templateType?: TextTemplateType
}): JSX.Element {
  switch (props.templateType?.category || -1) {
    case TextTemplateCategory.order:
      return (
        <div className='form-group'>
          <label htmlFor='template-category'>
            Template Category:{' '}
            <ShowTemplateCategories category={props.templateType?.category as number} />{' '}
          </label>
          <input
            type='text'
            className='form-control'
            id='template-category'
            readOnly={true}
            value='Order Notifications'
          />
        </div>
      )
    case TextTemplateCategory.marketing:
      return (
        <div className='form-group'>
          <label htmlFor='template-category'>
            Template Category:{' '}
            <ShowTemplateCategories category={props.templateType?.category as number} />{' '}
          </label>
          <input
            type='text'
            className='form-control'
            id='template-category'
            readOnly={true}
            value='Marketing & Promos'
          />
        </div>
      )
    default:
      return <></>
  }
}

export function ShowTemplateCategories(props: { category: number | null }): JSX.Element {
  switch (props.category) {
    case TextTemplateCategory.order:
      return <i className='icon-list-alt' title='Order Notifications'></i> //<>Order Notifications</>
    case TextTemplateCategory.marketing:
      return <i className='icon-envelope-alt' title='Marketing & Promos'></i> //<>Marketing & Promos</>
    default:
      return <></>
  }
}
