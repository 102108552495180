import React from 'react'
import * as Acorn from 'acorn'
import ObjPath from 'object-path'
import { generate as generateJs } from 'escodegen'

function isReactNode(node) {
  const type = node.type //"ExpressionStatement"
  const obj = ObjPath.get(node, 'expression.callee.object.name')
  const func = ObjPath.get(node, 'expression.callee.property.name')
  return type === 'ExpressionStatement' && obj === 'React' && func === 'createElement'
}

function findReactNode(ast) {
  const { body } = ast
  const reactNode = body.find(isReactNode)
  return reactNode
}

export function getCompiledCode(code) {
  const tcode = Babel.transform(code, { presets: ['es2015', 'stage-2', 'react'] }).code

  const ast = Acorn.parse(tcode, { sourceType: 'module' })

  const rnode = findReactNode(ast)

  if (rnode) {
    const nodeIndex = ast.body.indexOf(rnode)
    const createElSrc = generateJs(rnode).slice(0, -1)
    const renderCallAst = Acorn.parse(`render(${createElSrc})`).body[0]

    ast.body[nodeIndex] = renderCallAst
  }

  return generateJs(ast)
}
