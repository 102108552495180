import * as R from 'ramda'
import moment from 'moment-timezone'

export const knownTimezones = {
  'Abu Dhabi': 'Asia/Dubai',
  'Alaska': 'America/Anchorage',
  'Melbourne': 'Australia/Melbourne',
  'Central Time (US & Canada)': 'America/Chicago',
  'Perth': 'Australia/Perth',
  'Mexico City': 'America/Mexico_City',
  'Abu Dhabi': 'Asia/Dubai',
  'Mountain Time (US & Canada)': 'America/Denver',
  'London': 'Europe/London',
  'America/New_York': 'America/New_York',
  'Sydney': 'Australia/Sydney',
  'Eastern Time (US & Canada)': 'America/New_York',
  'Hawaii': 'Pacific/Honolulu',
  'Brisbane': 'Australia/Brisbane',
  'Rome': 'Europe/Rome',
  'Auckland': 'Pacific/Auckland',
  // 'Singapore': '',
  // 'Edinburgh': '',
  // 'Wellington',
  'Amsterdam': 'Europe/Amsterdam',
  // 'Arizona': 'America/Phoenix',
  'Atlantic Time (Canada)': 'America/Halifax',
  // 'Bogota',
  // 'Saskatchewan',
  // 'Brasilia',
  'Dublin': 'Europe/Dublin',
  'La Paz': 'America/La_Paz',
  'Brussels': 'Europe/Brussels',
  'Hobart': 'Australia/Hobart',
  'Pacific Time (US & Canada)': 'America/Los_Angeles',
  'Kuwait': 'Asia/Kuwait',
  'Central America': 'US/Central',
}

R.toPairs(knownTimezones).forEach(([k, v]) => moment.tz.link(`${v}|${k}`))

export default moment
