import React from 'react'
import { consume, toImage, Formatter, provideIf } from './utils'
import { createContext, useContext } from './context'
import * as R from 'ramda'

import Order, { ItemCtx } from './order'
import { Menu, MenuItem, Order as OrderType, OrderItem } from '../data/models'

export const MenusCtx = createContext<Menu[]>('MenusCtx', [])
const MenuCtx = createContext('MenuCtx', null)
const TopItemCtx = createContext<OrderItem | null>('TopItemCtx')
const TopItemImageCtx = createContext('TipItemImageCtx')

const groupReducer = (acc: Partial<Menu>, curr: Menu) => {
  if (R.isEmpty(acc)) {
    return curr
  }
  return R.evolve(
    {
      items: R.concat(R.__, curr.items),
    },
    acc
  )
}

const reduceGroups = R.pipe(
  R.groupBy(R.prop('group') as (m: Menu) => string),
  R.toPairs,
  R.map(([group, menus_]) => {
    if (group === 'null' || group === 'undefined') {
      return menus_
    }
    return R.of(R.reduce(groupReducer, {}, menus_))
  }),
  R.flatten
)

const provideMenusWithItems = (menusCtx: any, orderCtx: any) => ({
  children,
}: {
  children: any
}) => {
  const menus = useContext<Menu[]>(menusCtx)
  const order = useContext<OrderType>(orderCtx)

  if (!menus || !order) return null

  const menusWithItems = R.compose(
    reduceGroups,
    R.filter(R.compose(R.lt(0), R.length, R.prop('items') as any)),
    R.map((menu: any) =>
      R.assoc(
        'items',
        R.filter(R.propEq('menu_id', menu.id), R.path(['carts', 'items'], order) as any[]),
        menu
      )
    )
  )(menus)

  if (R.is(Array, menusWithItems)) {
    return menusWithItems.map((v: any, i: any) => {
      return (
        <MenuCtx.ctx.Provider key={i} value={v}>
          {children}
        </MenuCtx.ctx.Provider>
      )
    })
  } else {
    return <></>
  }
}

const provideMenuItems = (menuCtx: any) => ({ children }: { children: any }) => {
  const menu = useContext<Menu>(menuCtx) as Menu
  if (R.is(Array, menu.items)) {
    return menu.items.map((v: any, i: any) => {
      return (
        <ItemCtx.ctx.Provider key={i} value={v}>
          {children}
        </ItemCtx.ctx.Provider>
      )
    })
  } else {
    return <></>
  }
}

const toImageOrName = (menu: Menu, params: any) =>
  menu.image.active ? <img src={menu.image.medium} {...params} /> : menu.name

const provideTopItemFromOrder = (menusCtx: any, orderCtx: any) => ({
  children,
}: {
  children: any
}) => {
  const menus = useContext<Menu[]>(menusCtx)
  const order = useContext<OrderType>(orderCtx)

  if (!order || !menus) return null

  const topItem = R.compose(
    R.last,
    R.sortBy(R.prop('price'))
  )(order.carts.items) as OrderItem | null

  if (!topItem) return null

  const allItems = R.pipe(
    R.map(R.prop('categories')),
    R.flatten,
    R.map(R.prop('items')),
    R.flatten
  )(menus) as MenuItem[]

  const itemWithImage = (R.compose(
    R.head,
    R.filter(R.propEq('id', topItem.item_id))
  )(allItems) as unknown) as MenuItem | null

  // console.log('itemWithImage', itemWithImage)

  if (itemWithImage) {
    topItem.image = itemWithImage.image
  }

  return <TopItemCtx.ctx.Provider value={topItem}>{children}</TopItemCtx.ctx.Provider>
}

export default {
  Context: MenusCtx,
  MenusWithItems: provideMenusWithItems(MenusCtx, Order.Context),
  TopItem: provideTopItemFromOrder(MenusCtx, Order.Context),
  TopItemName: consume(['name'], TopItemCtx),
  TopItemImage: consume(['image'], TopItemCtx, toImage),
  TopItemWithImage: provideIf(
    ['image'],
    TopItemCtx,
    TopItemImageCtx,
    ['image', 'active'],
    R.equals(true)
  ),
  TopItemWithoutImage: provideIf(
    ['image'],
    TopItemCtx,
    TopItemImageCtx,
    ['image'],
    R.either(R.isNil, R.propEq('active', false))
  ),
  Menu: {
    Id: consume(['id'], MenuCtx),
    Name: consume(['name'], MenuCtx),
    ImageOrName: consume([], MenuCtx, toImageOrName as Formatter),
    Items: provideMenuItems(MenuCtx),
  },
}
