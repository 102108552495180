import React from 'react'
import Order, { OrderCtx, OptionCtx } from '../order'
import { toApplyPromoDiscount } from '../order'
import Params from '../params'
import { useContext } from '../../text/context'

function toMarkup(output) {
  return props => <>{output}</>
}

function toMarkupContainer(start, reset) {
  return props => (
    <>
      {start} {props.children} {reset}
    </>
  )
}

function Column(props) {
  const kids = React.Children.toArray(props.children)
  const indent = props.indent ? `; indent ${props.indent}` : '; indent 0mm'

  switch (kids.length) {
    case 0:
      return <></>
    case 1:
      return (
        <>
          [column: left {kids[0]}; {indent}]{'\n'}
        </>
      )
    case 2:
      return (
        <>
          [column: left {kids[0]}; right \ {kids[1]}
          {indent}]{'\n'}
        </>
      )
    case 3:
      return (
        <>
          [column: left {kids[0]}; short \ {kids[2]}; right \ {kids[1]}
          {indent}]{'\n'}
        </>
      )
  }
}

function QrCode(props) {
  const kids = React.Children.toArray(props.children)
  if (kids.length == 1) {
    return <>[barcode: type qr; data {kids[0]}; error-correction M; cell 8; model 2]</>
  } else {
    return null
  }
}

function QrPromoCode(props) {
  const params = useContext(Params.Context)
  const order = useContext(OrderCtx)

  if (params.discount && params.discount.code) {
    return <QrCode {...props}>{toApplyPromoDiscount(order, params)}</QrCode>
  } else return null
}

function Size(props) {
  const { w, h } = props
  return (
    <>
      [mag: w {w || 1}; h {h || w || 1}]{props.children}[mag]
    </>
  )
}

function Image(props) {
  return (
    <>
      [image: url {props.url}; width {props.width || '100%'}]
    </>
  )
}

export function toThermalImage(value, params) {
  return (
    <>
      [image: url {value[params.size || 'medium']}; width {params.width || '100%'}]
    </>
  )
}

export const Thermal = {
  Left: toMarkupContainer('[align: left]', '[align: left]\n'),
  Right: toMarkupContainer('[align: right]', '[align: left]\n'),
  Center: toMarkupContainer('[align: center]', '[align: left]\n'),
  Bold: toMarkupContainer('[bold: on]', '[bold: off]'),
  Column,
  Cut: toMarkup('[cut]\n'),
  HalfCut: toMarkup('[cut: feed; partial]\n'),
  Feed: toMarkup('[feed]\n'),
  FontA: toMarkupContainer('[font: a]', '[font]'),
  FontB: toMarkupContainer('[font: b]', '[font]'),
  Size,
  Space: toMarkup('[space]\n'),
  Underline: toMarkupContainer('[underline: on]', '[underline: off]'),
  Upperline: toMarkupContainer('[upperline: on]', '[upperline: off]'),
  Text: toMarkupContainer('', ''),
  QrCode,
  QrPromoCode,
  Image,
  Cart,
  Invert: toMarkupContainer('[invert: on]', '[invert: off]'),
  Negative: toMarkupContainer('[negative: on]', '[negative: off]'),
}

export function escapeThermal(s) {
  return s.replaceAll(/([\[\]\\])/g, '\\$1')
}

function OrderItems({
  refund = false,
  hideCategory = false,
  hideMenu = false,
  hideComments = false,
  ...rest
}) {
  return (
    <>
      <Order.Items>
        <Column>
          <Thermal.Text>
            {!hideMenu && (
              <>
                <Order.Item.Menu /> -{' '}
              </>
            )}
            {!hideCategory && (
              <>
                <Order.Item.Category /> -{' '}
              </>
            )}
            <Order.Item.Name />
          </Thermal.Text>
          <Order.Item.Total refund={refund} />
          <Order.Item.Alias />
        </Column>
        {!hideComments && (
          <Order.Item.Comments>
            {val => val && val.length > 0 && <Thermal.Text>{val}</Thermal.Text>}
          </Order.Item.Comments>
        )}
        <Thermal.FontB>
          <Order.Item.Modifiers>
            <Order.Modifier.Options>
              <ModifierOption {...rest} />
            </Order.Modifier.Options>
          </Order.Item.Modifiers>
        </Thermal.FontB>
      </Order.Items>
    </>
  )
}

function ModifierOption({ hideOptionPrice = false, hideModifier = false }) {
  const option = useContext(OptionCtx)
  if (!option) return null

  return (
    <Column indent='0mm'>
      <Thermal.Text>
        {option.quantity > 1 && (
          <>
            <Order.Option.Quantity /> x{' '}
          </>
        )}
        {!hideModifier && (
          <>
            <Order.Modifier.Name /> -{' '}
          </>
        )}
        <Order.Option.Name />
      </Thermal.Text>
      <Thermal.Text>{option.total > 0 && !hideOptionPrice && <Order.Option.Total />}</Thermal.Text>
      <Thermal.Text>
        <Order.Option.Alias />
      </Thermal.Text>
    </Column>
  )
}

function OrderTotals({ showMainTotal = true, ...props }) {
  const orderCtx = useContext(OrderCtx)
  if (!orderCtx) return null
  const {
    delivery,
    discount,
    hidden,
    service,
    subtotal,
    tax,
    included_tax,
    tip,
    total,
  } = orderCtx.totals
  return (
    <>
      <Thermal.FontB>
        {!!subtotal && (
          <Column>
            <Thermal.Text>Subtotal:</Thermal.Text>
            <Order.Totals.Subtotal />
          </Column>
        )}
        {!!delivery && (
          <Column>
            <Thermal.Text>Delivery:</Thermal.Text>
            <Order.Totals.Delivery />
          </Column>
        )}
        {!!discount && (
          <Column>
            <Thermal.Text>Discount:</Thermal.Text>
            <Order.Totals.Discount />
          </Column>
        )}
        {!!service && (
          <Column>
            <Thermal.Text>Service:</Thermal.Text>
            <Order.Totals.Service />
          </Column>
        )}
        {!!tax && (
          <Column>
            <Thermal.Text>Tax:</Thermal.Text>
            <Order.Totals.Tax />
          </Column>
        )}
        {!!included_tax && (
          <Column>
            <Thermal.Text>VAT:</Thermal.Text>
            <Order.Totals.IncludedTax />
          </Column>
        )}
        {!!tip && (
          <Column>
            <Thermal.Text>Tip:</Thermal.Text>
            <Order.Totals.Tip />
          </Column>
        )}
      </Thermal.FontB>
      {!!total && showMainTotal && (
        <Thermal.Underline>
          <Column>
            <Thermal.Text>Total:</Thermal.Text>
            <Order.Totals.Total />
          </Column>
        </Thermal.Underline>
      )}
    </>
  )
}

function Cart(props) {
  return (
    <>
      <OrderItems {...props} />
      <Thermal.Space />
      <OrderTotals {...props} />
    </>
  )
}
