import * as R from 'ramda'
import {
  Restaurant,
  Menu,
  Order,
  Channel,
  TemplateParams,
  ThemeColors,
  Payment,
  LoyaltyBalance,
  LoyaltyStatus,
  LoyaltyRewards,
  LoyaltyBonus,
  UserSignup,
} from '../data/models'
import { Invoice } from '../data/invoice'
import { Report } from '../data/report'
import { applyOrderAdjustmentsHierarchy as applyOrderAdjustments } from '../data/edit-order'

export type TemplateReducerParam =
  | { type: 'save_order'; order: Order }
  | { type: 'save_restaurant'; restaurant: Restaurant }
  | { type: 'save_reports_restaurant'; report: Report }
  | { type: 'save_menus'; menus: Menu[] }
  | { type: 'save_channel'; channel: Channel }
  | { type: 'save_reports_channel'; report: Report }
  | { type: 'set_theme'; theme: ThemeColors }
  | { type: 'set_custom'; name: string; value: string }
  | { type: 'set_discount'; value: TemplateParams['discount'] }
  | { type: 'set_payment'; payments: Payment }
  | { type: 'save_invoice'; invoice: Invoice }
  | { type: 'set_loyalty_balance'; loyaltyBalance: null | LoyaltyBalance }
  | { type: 'set_gift_cards'; value: TemplateParams['gift_cards'] }
  | { type: 'set_campaign_loyalty_status'; value: LoyaltyStatus }
  | { type: 'set_campaign_loyalty_rewards'; value: LoyaltyRewards }
  | { type: 'set_campaign_loyalty_bonus'; value: LoyaltyBonus }
  | { type: 'set_user_signup'; value: UserSignup }

export function templateParamsReducer(
  state: TemplateParams,
  ev: TemplateReducerParam
): TemplateParams {
  switch (ev.type) {
    case 'save_order':
      return { ...state, order: applyOrderAdjustments(ev.order) }
    case 'save_channel':
      return { ...state, channel: ev.channel }
    case 'save_reports_channel':
      return { ...state, report: ev.report }
    case 'save_restaurant':
      return { ...state, restaurant: ev.restaurant }
    case 'save_reports_restaurant':
      return { ...state, report: ev.report }
    case 'save_menus':
      return { ...state, menus: ev.menus }
    case 'set_theme':
      return { ...state, theme: ev.theme }
    case 'set_custom':
      return R.assoc(ev.name, ev.value, state)
    case 'set_payment':
      return { ...state, payments: ev.payments }
    case 'save_invoice':
      return { ...state, invoice: ev.invoice }
    case 'set_discount':
      return { ...state, discount: ev.value }
    case 'set_loyalty_balance':
      return { ...state, loyaltyBalance: ev.loyaltyBalance }
    case 'set_gift_cards':
      return { ...state, gift_cards: ev.value }
    case 'set_campaign_loyalty_status':
      return { ...state, campaign: { loyalty_status: ev.value } }
    case 'set_campaign_loyalty_rewards':
      return { ...state, campaign: { loyalty_rewards: ev.value } }
    case 'set_campaign_loyalty_bonus':
      return { ...state, campaign: { loyalty_bonus: ev.value } }
    case 'set_user_signup':
      return {
        ...state,
        account: { name: ev.value.name, email: ev.value.email },
        confirmation_code: ev.value.confirmation_code,
        channel_id: ev.value.channel_id,
      }
  }
}
