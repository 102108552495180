import React from 'react'
import ReactDOM from 'react-dom'
import ObjPath from 'object-path'

import * as Acorn from 'acorn'
import * as R from 'ramda'

import { generate as generateJs } from 'escodegen'

import * as Theme from './text/ui/default-theme'
import * as Molecules from './text/ui/molecules'
import * as Primitives from './text/ui/primitives'
import * as Macros from './text/ui/macros'
import * as Grid from './text/ui/grid'
import * as Th from './text/ui/thermal'

const UI = { ...Primitives, ...Molecules, ...Macros, ...Theme, ...Grid, ...Th }

import Order from './text/order'
import Payments from './text/payments'
import Restaurant from './text/restaurant'
import Menus from './text/menus'
import Channel from './text/channel'
import Params from './text/params'
import Invoice from './text/invoice'
import Loyalty from './text/loyalty'
import Reports from './text/reports'
import GiftCards from './text/gift_card'
import { ContextManagerCtx } from './text/context'

import Campaign from './text/campaign'

const Data = {
  Order,
  Payments,
  Restaurant,
  Menus,
  Channel,
  Params,
  Invoice,
  Loyalty,
  Reports,
  GiftCards,
  Campaign,
}

export function isReactNode(node) {
  const type = node.type //"ExpressionStatement"
  const obj = ObjPath.get(node, 'expression.callee.object.name')
  const func = ObjPath.get(node, 'expression.callee.property.name')
  return type === 'ExpressionStatement' && obj === 'React' && func === 'createElement'
}

export function findReactNode(ast) {
  const { body } = ast
  const reactNode = body.find(isReactNode)
  return reactNode
}

export function SetupContext(props) {
  return (
    <ContextManagerCtx.Provider value={props.ctxManager}>
    <Params.Context.Provider value={R.dissoc('children', props)}>
      <Order.Context.Provider value={props.order}>
        <Payments.Context.Provider value={props.payments}>
          <Channel.Context.Provider value={props.channel}>
            <Restaurant.Context.Provider value={props.restaurant}>
              <Menus.Context.Provider value={props.menus}>
                <Invoice.Context.Provider value={props.invoice}>
                  <Loyalty.Context.Provider value={props.loyaltyBalance}>
                    <Reports.Context.Provider value={props.report}>
                      <Campaign.Context.Provider value={props.campaign}>
                        <Theme.Theme channelTheme={props.theme}>{props.children}</Theme.Theme>
                      </Campaign.Context.Provider>
                    </Reports.Context.Provider>
                  </Loyalty.Context.Provider>
                </Invoice.Context.Provider>
              </Menus.Context.Provider>
            </Restaurant.Context.Provider>
          </Channel.Context.Provider>
        </Payments.Context.Provider>
      </Order.Context.Provider>
    </Params.Context.Provider>
    </ContextManagerCtx.Provider>
  )
}

export function createEditor(domElement, moduleResolver) {
  function render(params) {
    return node => ReactDOM.render(<SetupContext {...params}>{node}</SetupContext>, domElement)
  }

  function require(moduleName) {
    return moduleResolver ? moduleResolver(moduleName) : null
  }

  function getWrapperFunction(code, params) {
    try {
      // 1. transform code
      const tcode = Babel.transform(code, { presets: ['es2015', 'stage-2', 'react'] }).code

      // 2. get AST
      const ast = Acorn.parse(tcode, {
        sourceType: 'module',
      })

      // 3. find React.createElement expression in the body of program
      const rnode = findReactNode(ast)

      if (rnode) {
        // console.log('Found root node', rnode)
        const nodeIndex = ast.body.indexOf(rnode)
        // 4. convert the React.createElement invocation to source and remove the trailing semicolon
        const createElSrc = generateJs(rnode).slice(0, -1)
        // 5. transform React.createElement(...) to render(React.createElement(...)),
        // where render is a callback passed from outside
        // const renderCallAst = Acorn.parse(`render(${wrappedCreateElSrc})`).body[0]
        const renderCallAst = Acorn.parse(`render(${createElSrc})`).body[0]

        ast.body[nodeIndex] = renderCallAst
      }

      // 6. create a new wrapper function with all dependency as parameters
      // const result = new Function(
      //   'React',
      //   'render',
      //   'require',
      //   'params',
      //   'Data',
      //   'UI',
      //   generateJs(ast)
      // )
      // console.log('Result function', generateJs(ast))
      //return result.toString()
      return generateJs(ast)
    } catch (ex) {
      // in case of exception render the exception message
      // render(params)(<pre style={{ color: 'red' }}>{ex.message}</pre>)
      return `console.error("Error creating template: ${ex.message}")`
    }
  }

  return {
    // returns transpiled code in a wrapper function which can be invoked later
    compile(code, params) {
      console.time('compile')
      const result = getWrapperFunction(code, params)
      console.timeEnd('compile')
      return result
    },

    // compiles and invokes the wrapper function
    run(code, params) {
      return execute(compile(code), params)
    },
    execute(compiled_code, params) {
      console.time('run')
      let fx = new Function('React', 'render', 'require', 'params', 'Data', 'UI', compiled_code)
      const result = fx(React, render(params), require, params, Data, UI)
      console.timeEnd('run')
      return result
    },

    // just compiles and returns the stringified wrapper function
    getCompiledCode(code, params) {
      return getWrapperFunction(code, params).toString()
    },
  }
}
