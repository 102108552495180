import React from 'react'

export interface ExContext<T> {
  id: string
  ctx: React.Context<T>
  Provider: React.Provider<T | null>
  Consumer: React.Consumer<T | null>
}

export type Context<T> = ExContext<T>

export function createContext<T>(id: string, val?: T): ExContext<T | null> {
  const ctx = React.createContext(val || null)
  return { id, ctx, Provider: ctx.Provider, Consumer: ctx.Consumer }
}

export const ContextManagerCtx = createContext<Set<string>>('ContextManagerCtx')

export function useContext<T>(exCtx: ExContext<T>): T | null {
  const ctxManager = React.useContext(ContextManagerCtx.ctx)
  if (exCtx.id) {
    if (ctxManager) ctxManager.add(exCtx.id)
    return React.useContext(exCtx.ctx)
  } else {
    return React.useContext(exCtx)
  }
}
