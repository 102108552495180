import { createContext } from 'react'
import { AppProps } from '../templates/models'

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

export function authHeaders(auth: string): { 'Content-Type': string; 'Authorization': string } {
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${auth}`,
  }
}

export const makeAuthRequest = (auth_token: string) => (
  method: HttpMethod,
  payload?: any
): any => ({
  method,
  headers: authHeaders(auth_token),
  body: payload ? JSON.stringify(payload) : null,
})

export const AppPropsCtx = createContext<AppProps | null>(null)
