import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { useParams, useNavigate } from 'react-router-dom'
import { TextTemplateInfo, TextTemplate } from '../models'
import TemplateEditor from '../editor'

import { useTextTemplatesSvc } from '../svc'

export function EditPage(props: { refresh: () => void }): JSX.Element {
  const api = useTextTemplatesSvc()
  const [template, setTemplate] = useState<TextTemplate | null>(null)
  const [templateTypes, setTemplateTypes] = useState<string[]>()
  const [error, setError] = useState<string | null>(null)
  const { id, type } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    api.searchTemplates(type as string).then(templates => {
      const matched = templates.find(i => i.id == id)
      if (!matched) {
        setError('Invalid URL')
        return
      }
      setTemplateTypes(R.uniq(templates.map((t: TextTemplateInfo) => t.type)))
      api
        .loadTemplateDetails(matched)
        .then(setTemplate)
        .catch(err => setError(err))
    })
  }, [])

  if (error) return <div className='text-error'>{'Error in loading template: ' + error}</div>
  if (!template) return <>loading...</>
  return (
    <TemplateEditor
      template={template}
      onClose={() => {
        props.refresh()
        navigate(`/${type}`)
      }}
      templateTypes={templateTypes || []}
    />
  )
}
