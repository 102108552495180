import { Channel, LoyaltyBalance, LoyaltyTier, LoyaltyReward } from '../data/models'
import * as R from 'ramda'
import React from 'react'
import ChannelComponents from './channel'
import { createRedirectUrl, Order, OrderCtx, toRestaurantPath } from './order'
import Params from './params'
import { consume, consume2, consume3, provide, provideIf, toMoney } from './utils'
import { createContext, useContext } from './context'

export const LoyaltyCtx = createContext<null | LoyaltyBalance>('LoyaltyCtx')
export const LoyaltyDataCtx = createContext<any>('LoyaltyDataCtx')
const ConditionCtx = createContext('ConditionCtx')

export const toLoyaltyDashboardUrl = (
  order: Order,
  channel: Channel,
  template_type: string,
  tab = ''
): string =>
  order
    ? createRedirectUrl(
        order.carts.integrationUrl,
        `${toRestaurantPath(channel)}/account/loyalty${tab}`,
        template_type
      )
    : '#'

const ProcessedLoyalty: React.FC = ({ children }) => {
  const balance = useContext(LoyaltyCtx) as LoyaltyBalance
  const order = (useContext(OrderCtx) as unknown) as Order

  if (!balance || !order) return null

  const isAvailable =
    R.path(['zuppler_loyalty_info', 'current_tier'], order) !== null &&
    R.path(['plan'], balance) !== null

  if (!isAvailable) return null

  const { plan, user_data } = balance

  const currentTier = R.findLast(
    ({ amount }) => user_data.stamina >= amount,
    plan.tiers
  ) as LoyaltyTier

  const prevTier = R.findLast(
    ({ amount }) => user_data.stamina - order.zuppler_loyalty_info.points_earned! >= amount,
    plan.tiers
  ) as LoyaltyTier

  const nextTier = R.find(({ amount }) => user_data.stamina < amount, plan.tiers) as LoyaltyTier

  const approachindNextTier = nextTier && (user_data.stamina * 100) / nextTier.amount > 75

  const pointsToNextTier = approachindNextTier ? nextTier.amount - user_data.stamina : 0

  const rewards = !plan.spending_rules
    ? []
    : plan.spending_rules.map(r => r.cheques).reduce((acc, v) => acc.concat(v), [])

  const availableRewards = rewards.filter(r => r.points <= user_data.points.available)

  const hasAvailableRewards = availableRewards.length > 0

  const unlockedReward = availableRewards
    .filter(r => r.points > user_data.points.available - order.zuppler_loyalty_info.points_earned!)
    .sort((a, b) => b.amount - a.amount)[0]

  const nextRewards = rewards.filter(
    r => r.points > user_data.points.available && (user_data.points.available * 100) / r.points > 75
  )

  const hasNextReward = nextRewards.length > 0

  const pointsToNextReward = hasNextReward ? nextRewards[0].points - user_data.points.available : 0

  const rewardApplied =
    !!order.zuppler_loyalty_info.points_spent && order.zuppler_loyalty_info.points_spent > 0

  const hasUnlockedTier = !!prevTier && prevTier.id !== currentTier.id

  const justLoyaltyMember =
    !unlockedReward && !hasUnlockedTier && !hasNextReward && !approachindNextTier && !rewardApplied

  const conditions = [
    { id: 'NEW_REWARD_AVAILABLE', active: !!unlockedReward },
    { id: 'NEW_TIER', active: hasUnlockedTier },
    { id: 'APPROACHING_REWARD', active: hasNextReward },
    { id: 'APPROACHING_TIER', active: approachindNextTier },
    { id: 'REWARD_APPLIED', active: rewardApplied },
    { id: 'JUST_LOYALTY_MEMBER', active: justLoyaltyMember },
    { id: 'ALWAYS', active: true },
  ]
    .filter(c => c.active)
    .map(c => c.id)

  const topCondition = conditions[0]

  const api = {
    currentTier,
    nextTier,
    pointsToNextTier,
    availableRewards,
    unlockedReward,
    nextReward: nextRewards[0],
    pointsToNextReward,
    rewardApplied,
    conditions,
    topCondition,
    hasAvailableRewards,
  }

  return <LoyaltyDataCtx.ctx.Provider value={api}>{children}</LoyaltyDataCtx.ctx.Provider>
}

const RewardCtx = createContext('RewardCtx')
const NextTierCtx = createContext('NextTierCtx')

export default {
  Context: LoyaltyCtx,
  Available: ProcessedLoyalty,

  NotAvailable: provideIf(
    [],
    OrderCtx,
    ConditionCtx,
    ['zuppler_loyalty_info', 'current_tier'],
    R.compose(R.not, R.is(String))
  ),

  IfNewReward: provideIf(
    ['unlockedReward'],
    LoyaltyDataCtx,
    RewardCtx,
    ['conditions'],
    R.includes('NEW_REWARD_AVAILABLE')
  ),
  JustNewReward: provideIf(
    ['unlockedReward'],
    LoyaltyDataCtx,
    RewardCtx,
    ['topCondition'],
    R.equals('NEW_REWARD_AVAILABLE')
  ),

  IfApproachingReward: provideIf(
    ['nextReward'],
    LoyaltyDataCtx,
    RewardCtx,
    ['conditions'],
    R.includes('APPROACHING_REWARD')
  ),
  JustApproachingReward: provideIf(
    ['nextReward'],
    LoyaltyDataCtx,
    RewardCtx,
    ['topCondition'],
    R.equals('APPROACHING_REWARD')
  ),

  IfAvailableRewards: provideIf(
    [],
    LoyaltyDataCtx,
    ConditionCtx,
    ['hasAvailableRewards'],
    R.identity
  ),
  AvailableRewards: provide(['availableRewards'], LoyaltyDataCtx, RewardCtx),

  Reward: {
    Value: consume(['amount'], RewardCtx, toMoney),
    Points: consume(['points'], RewardCtx),
  },

  PointsToNextReward: consume(['pointsToNextReward'], LoyaltyDataCtx),

  IfNewTier: provideIf([], LoyaltyDataCtx, ConditionCtx, ['conditions'], R.includes('NEW_TIER')),
  JustNewTier: provideIf([], LoyaltyDataCtx, ConditionCtx, ['topCondition'], R.equals('NEW_TIER')),

  IfApproachingTier: provideIf(
    ['nextTier'],
    LoyaltyDataCtx,
    NextTierCtx,
    ['conditions'],
    R.includes('APPROACHING_TIER')
  ),
  JustApproachingTier: provideIf(
    ['nextTier'],
    LoyaltyDataCtx,
    NextTierCtx,
    ['topCondition'],
    R.equals('APPROACHING_TIER')
  ),

  NextTier: consume(['name'], NextTierCtx),
  PointsToNextTier: consume(['pointsToNextTier'], LoyaltyDataCtx),

  CurrentTier: consume(['currentTier', 'name'], LoyaltyDataCtx),

  IfRewardApplied: provideIf(
    [],
    LoyaltyDataCtx,
    ConditionCtx,
    ['conditions'],
    R.includes('REWARD_APPLIED')
  ),
  JustRewardApplied: provideIf(
    [],
    LoyaltyDataCtx,
    ConditionCtx,
    ['topCondition'],
    R.equals('REWARD_APPLIED')
  ),

  JustLoyaltyMember: provideIf(
    [],
    LoyaltyDataCtx,
    ConditionCtx,
    ['topCondition'],
    R.equals('JUST_LOYALTY_MEMBER')
  ),

  PointsEarned: consume(['zuppler_loyalty_info', 'points_earned'], OrderCtx),
  PointsSpent: consume(['zuppler_loyalty_info', 'points_spent'], OrderCtx),
  PaidAmount: consume(['zuppler_loyalty_info', 'reward_amount'], OrderCtx, toMoney),

  PointsToSpend: consume(['user_data', 'points', 'available'], LoyaltyCtx),

  TierExpiration: consume(['plan', 'tier_expiration'], LoyaltyCtx),
  DashboardUrl: consume3(
    [],
    OrderCtx,
    [],
    ChannelComponents.Context,
    ['template_type'],
    Params.Context,
    toLoyaltyDashboardUrl
  ),
}
