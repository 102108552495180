import { consume, provide, toAllMoney, toImage, toLink } from './utils'
import { TemplateParams } from '../data/models'
import { createContext } from './context'

export const CampaignCtx = createContext<TemplateParams['campaign'] | null>('CampaignCtx')
const LoyaltyRewardCtx = createContext('CampaignCtx:LoyaltyRewardCtx')

const userCommon = (start: string): any => ({
  Name: consume([start, 'user', 'name'], CampaignCtx),
  Email: consume([start, 'user', 'email'], CampaignCtx),
})

export default {
  Context: CampaignCtx,
  Status: {
    Logo: consume(['loyalty_status', 'logo_url'], CampaignCtx, toImage),
    Message: consume(['loyalty_status', 'message'], CampaignCtx),
    User: {
      ...userCommon('loyalty_status'),
      LifetimeSavings: consume(
        ['loyalty_status', 'user', 'lifetime_savings'],
        CampaignCtx,
        toAllMoney
      ),
      TotalOrdersLastMonth: consume(
        ['loyalty_status', 'user', 'total_orders_last_month'],
        CampaignCtx
      ),
      TotalPointsEarnedLastMonth: consume(
        ['loyalty_status', 'user', 'total_points_earned_last_month'],
        CampaignCtx
      ),
      TotalPointsSpentLastMonth: consume(
        ['loyalty_status', 'user', 'total_points_spent_last_month'],
        CampaignCtx
      ),
      TotalPointsToSpend: consume(['loyalty_status', 'user', 'total_points_to_spend'], CampaignCtx),
      DashboardLink: consume(['loyalty_status', 'user', 'dashboard_url'], CampaignCtx, toLink),
    },
  },
  Rewards: {
    Logo: consume(['loyalty_rewards', 'logo_url'], CampaignCtx, toImage),
    Message: consume(['loyalty_rewards', 'message'], CampaignCtx),
    User: {
      ...userCommon('loyalty_rewards'),
      HighestValueReward: {
        Amount: consume(
          ['loyalty_rewards', 'user', 'highest_value_reward', 'amount'],
          CampaignCtx,
          toAllMoney
        ),
        Points: consume(['loyalty_rewards', 'user', 'highest_value_reward', 'points'], CampaignCtx),
      },
      Rewards: provide(['loyalty_rewards', 'user', 'rewards'], CampaignCtx, LoyaltyRewardCtx),
      Reward: {
        Amount: consume(['amount'], LoyaltyRewardCtx, toAllMoney),
        Points: consume(['points'], LoyaltyRewardCtx),
      },
      IntegrationLink: consume(['loyalty_rewards', 'user', 'integration_url'], CampaignCtx, toLink),
    },
  },
  Bonus: {
    Logo: consume(['loyalty_bonus', 'logo_url'], CampaignCtx, toImage),
    Message: consume(['loyalty_bonus', 'message'], CampaignCtx),
    User: {
      ...userCommon('loyalty_bonus'),
      Bonus: consume(['loyalty_bonus', 'user', 'bonus'], CampaignCtx),
      IntegrationLink: consume(['loyalty_bonus', 'user', 'integration_url'], CampaignCtx, toLink),
    },
  },
}
