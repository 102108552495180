import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { diff_match_patch } from 'diff-match-patch'
import rdiff, { rdiffResult } from 'recursive-diff'

export interface AuditAPI {
  createLog: (
    resourceType: string,
    resourceId: string,
    action: string,
    description: string
  ) => Promise<LogEntry>
  getAllLogs: (
    resourceType: string,
    resourceId: string,
    page?: number,
    perPage?: number
  ) => Promise<GetLogs>
}

interface GetLogs {
  audit_logs: LogEntry[]
  pagination: Pagination
}

export interface ServiceConfig {
  app: string
  audit_svc: string
  agent: string
}

export interface Pagination {
  page: number
  pages: number
  per_page: number
  total: number
}

export interface AuditResourceAPI {
  createLog: (action: string, description: string) => Promise<LogEntry>
  getAllLogs: (page?: number, perPage?: number) => Promise<GetLogs>
}

export interface LogEntry {
  id: number
  app: string
  resource: string
  resource_id: string
  action: string
  description: string
  agent: string
  inserted_at: string
  updated_at: string
}

const AuditCtx = createContext<AuditAPI | null>(null)
const AuditResourceCtx = createContext<AuditResourceAPI | null>(null)

export function processResponse<T = any>(r: Response): Promise<T> {
  return r.ok
    ? r.json()
    : r
        .json()
        .then(Promise.reject.bind(Promise))
        .catch((error: any) =>
          Promise.reject({
            success: false,
            errors: error.errors,
            response: `${r.status}: ${r.statusText}`,
          })
        )
}

const authHeaders = (token: string): { 'Content-Type': string; 'Authorization': string } => {
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  }
}

export function AuditService(
  props: PropsWithChildren<{ auth_token: string; svc: string; appId: string; agent: string }>
): JSX.Element {
  const createLog = (
    resourceType: string,
    resourceId: string,
    action: string,
    description: string
  ): Promise<LogEntry> => {
    const payload = {
      audit_log: {
        app: props.appId,
        resource: resourceType,
        resource_id: resourceId,
        action: action,
        description: description,
        agent: props.agent,
      },
    }
    return fetch(props.svc, {
      method: 'POST',
      headers: authHeaders(props.auth_token),
      body: JSON.stringify(payload),
    }).then(processResponse)
  }

  const getAllLogs = (
    resourceType: string,
    resourceId: string,
    page = 1,
    perPage = 20
  ): Promise<GetLogs> => {
    const query = `?page=${page}&per_page=${perPage}&app=${encodeURIComponent(
      props.appId
    )}&resource=${encodeURIComponent(resourceType)}&resource_id=${encodeURIComponent(resourceId)}`
    return fetch(props.svc + query, {
      method: 'GET',
      headers: authHeaders(props.auth_token),
    })
      .then(processResponse)
      .catch(_res => Promise.reject('Failed to load logs from the server'))
  }

  const api: AuditAPI = {
    createLog,
    getAllLogs,
  }
  return <AuditCtx.Provider value={api}>{props.children}</AuditCtx.Provider>
}

export function auditObjDesc<T>(a: T, b: T): string {
  return rdiff
    .getDiff(a, b, true)
    .map((c: rdiffResult): string => {
      switch (c.op) {
        case 'add':
          return `+${c.path.join('.').replace(/_/g, ' ')} to ${
            typeof c.val == 'object' ? JSON.stringify(c.val) : c.val
          }`
        case 'update':
          return `~${c.path.join('.').replace(/_/g, ' ')} to ${
            typeof c.val == 'object' ? JSON.stringify(c.val) : c.val
          } from ${c.oldVal}`
        case 'delete':
          return `-${c.path.join('.').replace(/_/g, ' ')} is removed`
      }
    })
    .join(', ')
}

export function useAuditService(): AuditAPI {
  const auditAPI = useContext(AuditCtx)
  return auditAPI as AuditAPI
}

export function AuditResource(
  props: PropsWithChildren<{ resourceType: string; resourceId: string }>
): JSX.Element {
  const auditAPI = useAuditService()
  const createLog = (action: string, description: string): Promise<LogEntry> => {
    return auditAPI.createLog(props.resourceType, props.resourceId, action, description)
  }
  const getAllLogs = (page = 1, perPage = 20): Promise<GetLogs> => {
    return auditAPI.getAllLogs(props.resourceType, props.resourceId, page, perPage)
  }
  const api: AuditResourceAPI = {
    createLog,
    getAllLogs,
  }
  return <AuditResourceCtx.Provider value={api}>{props.children}</AuditResourceCtx.Provider>
}

export function useAuditResourceService(): AuditResourceAPI {
  const auditAPI = useContext(AuditResourceCtx)
  return auditAPI as AuditResourceAPI
}

interface AuditLogsAPI {
  logs: LogEntry[]
  error: string | null
  loadMore: () => void
  pagination: Pagination
  nextPage: () => void
  prevPage: () => void
  refresh: () => void
}

export function useAuditLogs(limit = 20): AuditLogsAPI {
  const resourceApi = useAuditResourceService()
  const [logs, setLogs] = useState<LogEntry[]>([])
  const [error, setError] = useState<string | null>(null)
  const [page, setPage] = useState<number>(1)
  const [refresh, setRefresh] = useState(1)
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pages: 0,
    per_page: 0,
    total: 0,
  })

  useEffect(() => {
    resourceApi
      .getAllLogs(page, limit)
      .then((res: GetLogs) => {
        setLogs([...logs, ...res.audit_logs])
        setPagination(res.pagination)
      })
      .catch(setError)
  }, [page, refresh])

  const loadMore = (): void => {
    if (pagination.pages > page) {
      setPage(pagination.page + 1)
    }
  }

  const reload = (): void => {
    setLogs([])
    setPage(1)
    setRefresh(refresh + 1)
  }

  const nextPage = (): void => {
    if (pagination.pages > pagination.page) {
      resourceApi
        .getAllLogs(pagination.page + 1, limit)
        .then((res: GetLogs) => {
          setLogs(res.audit_logs)
          setPagination(res.pagination)
        })
        .catch(setError)
    }
  }

  const prevPage = (): void => {
    if (pagination.page > 1) {
      resourceApi
        .getAllLogs(pagination.page - 1, limit)
        .then((res: GetLogs) => {
          setLogs(res.audit_logs)
          setPagination(res.pagination)
        })
        .catch(setError)
    }
  }

  return { logs, error, loadMore, pagination, nextPage, prevPage, refresh: reload }
}

export const AUDIT_RESOURCE_TYPE = 'text-template'
export const AUDIT_APP_ID = 'text-engine'
export const AUDIT_SVC = `${process.env.RESTAURANTS_SVC}/v5/audit_logs`

export async function recordUpdate(
  auth_token: string,
  agent_info: string,
  resourceId: string,
  description: string
): Promise<void> {
  const payload = {
    audit_log: {
      app: AUDIT_APP_ID,
      resource: AUDIT_RESOURCE_TYPE,
      resource_id: resourceId,
      action: 'update',
      description: description,
      agent: agent_info,
    },
  }

  const res = await fetch(AUDIT_SVC, {
    headers: authHeaders(auth_token),
    method: 'POST',
    body: JSON.stringify(payload),
  })
  return await res.json()
}

export function createScriptDiff(s1: string, s2: string): string {
  const dmp = new diff_match_patch()
  const diff = dmp.diff_main(s1, s2)
  dmp.diff_cleanupSemantic(diff)
  return dmp.diff_prettyHtml(diff)
}
