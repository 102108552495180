import moment, { Moment } from 'moment'
import fetch from 'node-fetch'
import { loadChannelByID } from './channel'
import { loadRestaurantByID } from './restaurant'
import * as R from 'ramda'

export interface Report {
  last: MonthReport
  current: MonthReport
}
interface MonthReport {
  order:
    | {
        restaurant: number
        count: number
        total: number
        new_users: number
        registered_users: number
      }
    | undefined
  discounts:
    | {
        restaurant: number
        count: number
        order_total: number
        discount_total: number
      }
    | undefined
  loyalty_order:
    | {
        count: number
        points: number
        total: number
      }
    | undefined
  loyalty_redeem:
    | {
        count: number
        points: number
        total: number
        order_total: number
      }
    | undefined
  carts_created: { count: number } | undefined
  carts_ordering: { count: number } | undefined
  carts_checkout: { count: number } | undefined
  carts_closed: { count: number } | undefined
  reviews: { score: number; count: number } | number | undefined
}

const restaurantKey = 'reports_restaurant_id'
const channelKey = 'reports_channel_id'
type ResourceType = 'restaurant' | 'channel'

export default (req: any, _res: any, next: (error?: string) => void) => {
  let resourceId: string | undefined = undefined
  let resourceType: ResourceType = 'restaurant'
  let resourceGetter: Function
  let date = moment(req.body.date)
    .startOf('month')
    .subtract(1, 'month')
    .format('YYYY-MM-DD')
  if (req.body[restaurantKey]) {
    resourceId = req.body[restaurantKey]
  } else if (req.body[channelKey]) {
    resourceId = req.body[channelKey]
    resourceType = 'channel'
  }

  resourceGetter = resourceType == 'restaurant' ? loadRestaurantByID : loadChannelByID

  if (resourceId) {
    console.info(`Loading ${resourceType} ${resourceId}...`)
    const integerId = parseInt(resourceId)
    Promise.all([loadReport(integerId, resourceType, date), resourceGetter(integerId)])
      .then(([data, resource]) => {
        req.templateParams.report = data
        req.templateParams[resourceType] = resource
        next()
      })
      .catch((error: string) => {
        console.error('Load invoice error', error)
        next(JSON.stringify(error, null, 2))
      })
  } else {
    next()
  }
}
const reportUrl = (path: string): string => process.env.REPORTS_SVC + path

const isSameMonth = R.curry((m: Moment, entry: any) => m.isSame(moment(entry.month), 'month'))

export const loadReport = (resourceId: number, type: string, date: string): Promise<Report> => {
  const reports = [
    { bucket: 'order', paths: ['basic'] },
    { bucket: 'discounts2_promos_commits', paths: ['discounts2_summary'] },
    { bucket: 'cart_created', paths: ['cart_created'] },
    { bucket: 'cart_ordering', paths: ['cart_ordering'] },
    { bucket: 'cart_checkout', paths: ['cart_checkout'] },
    { bucket: 'cart_closed', paths: ['cart_closed'] },
    { bucket: 'loyalty_order', paths: ['loyalty_basic'] },
    { bucket: 'loyalty_redeem', paths: ['redeem'] },
    { bucket: 'feedback_session', paths: ['reviews'] },
  ]

  const thisMonth = moment(date)
  const lastMonth = moment(date).subtract(1, 'month')
  const lastMonthDate = lastMonth.format('YYYY-MM-DD')

  const toReportResult = ([
    order,
    discounts,
    carts_created,
    carts_ordering,
    carts_checkout,
    carts_closed,
    loyalty_order,
    loyalty_redeem,
    reviews,
  ]: any): Promise<Report> => {
    const lastMonthReviews = R.find(isSameMonth(lastMonth), reviews)
    const thisMonthReviews = R.find(isSameMonth(thisMonth), reviews)
    return Promise.resolve({
      last: {
        order: R.find(isSameMonth(lastMonth), order),
        discounts: R.find(isSameMonth(lastMonth), discounts),
        carts_created: R.find(isSameMonth(lastMonth), carts_created),
        carts_ordering: R.find(isSameMonth(lastMonth), carts_ordering),
        carts_checkout: R.find(isSameMonth(lastMonth), carts_checkout),
        carts_closed: R.find(isSameMonth(lastMonth), carts_closed),
        loyalty_order: R.find(isSameMonth(lastMonth), loyalty_order),
        loyalty_redeem: R.find(isSameMonth(lastMonth), loyalty_redeem),
        reviews: lastMonthReviews
          ? lastMonthReviews.score / (20 * lastMonthReviews.count)
          : undefined,
      },
      current: {
        order: R.find(isSameMonth(thisMonth), order),
        discounts: R.find(isSameMonth(thisMonth), discounts),
        carts_created: R.find(isSameMonth(thisMonth), carts_created),
        carts_ordering: R.find(isSameMonth(thisMonth), carts_ordering),
        carts_checkout: R.find(isSameMonth(thisMonth), carts_checkout),
        carts_closed: R.find(isSameMonth(thisMonth), carts_closed),
        loyalty_order: R.find(isSameMonth(thisMonth), loyalty_order),
        loyalty_redeem: R.find(isSameMonth(thisMonth), loyalty_redeem),
        reviews: thisMonthReviews
          ? thisMonthReviews.score / (20 * thisMonthReviews.count)
          : undefined,
      },
    })
  }

  const body = {
    filters: [
      {
        key: 'month',
        op: 'in.date',
        value: [lastMonthDate, date],
      },
      {
        key: type,
        op: 'in',
        value: [resourceId],
      },
    ],
  }

  const requests = reports.reduce((acc: Promise<any>[], report) => {
    const paths: Promise<any>[] = report.paths.map(query =>
      fetch(reportUrl(`/rquery/${report.bucket}/${query}`), {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(body),
      }).then(res => res.json())
    )
    return acc.concat(paths)
  }, [])

  return Promise.all(requests).then(toReportResult)
}

function getHeaders(): any {
  return {
    'content-type': 'application/json',
    'zuppler-authorization': process.env.REPORTS_ACCESS_TOKEN,
  }
}
