export interface AppProps {
  svcUrl: string
  auth_token: string
  agent_info: string
}

export interface TextTemplateInfo {
  id?: number
  name: string
  type: string
  default: boolean
  url: string
  category: null | number
}

export interface TextTemplate extends TextTemplateInfo {
  subject: string | null
  compiled_subject: string | null
  content: string | null
  compiled_content: string | null
  print_content: string | null
  compiled_print_content: string | null
  short_content: string | null
  compiled_short_content: string | null
  receipt_content: string | null
  compiled_receipt_content: string | null
}

export type TextTemplateType = Pick<TextTemplateInfo, 'type' | 'category'>

export enum TextTemplateCategory {
  order = 1,
  marketing = 2,
}
