import React from 'react'
import {
  consume,
  provideIf,
  provide,
  toImage,
  toMoney,
  formatDate,
  consumeAsTag,
  toLink,
} from './utils'
import { createContext } from './context'
import Params from './params'
import * as R from 'ramda'

export const GiftCardsCtx = Params.Context
export const GiftCardCtx = createContext('GiftCardCtx')
export const GiftCardsChannelsCtx = createContext('GiftCardsChannelsCtx')
const ConditionCtx = createContext('GiftCardCtx:ConditionCtx')

const giftCardsUrl = (path: string): string => process.env.GIFTCARDS_SVC + '/' + path

const toAttachmentImage = (value: any, params: any) => {
  const src = typeof value === 'string' ? value : value[params.size || 'medium']
  const url = `${giftCardsUrl('generate_qrcode')}/${src}.png`
  return <img src={url} {...params} />
}

export default {
  Context: GiftCardsCtx,
  DefinitionDebug: consume([], GiftCardsCtx, data => JSON.stringify(data, null, 2)),
  Definition: {
    Name: consume(['gift_cards', 'definition', 'name'], GiftCardsCtx),
    Description: consume(['gift_cards', 'definition', 'description'], GiftCardsCtx),
    Logo: consume(['gift_cards', 'definition', 'logo_image'], GiftCardsCtx, toImage),
  },
  Channels: {
    Iterate: provide(['gift_cards', 'definition', 'channels'], GiftCardsCtx, GiftCardsChannelsCtx),
    Name: consume(['name'], GiftCardsChannelsCtx),
    Url: consumeAsTag(['url'], GiftCardsChannelsCtx, toLink),
    UrlAsText: consume(['url'], GiftCardsChannelsCtx),
  },
  Cards: {
    Iterate: provide(['gift_cards', 'cards'], GiftCardsCtx, GiftCardCtx),
    Name: consume(['name'], GiftCardCtx),
    Description: consume(['description'], GiftCardCtx),
    LogoImage: consume(['logo_image'], GiftCardCtx, toImage),
    UniqueCode: consume(['unique_code'], GiftCardCtx),
    RedeemQRCode: consume(['unique_code'], GiftCardCtx, toAttachmentImage),
    Price: consume(['price'], GiftCardCtx, toMoney),
    Balance: consume(['balance'], GiftCardCtx, toMoney),
    ExpireAt: consume(['expires_at'], GiftCardCtx, formatDate),
    IfCustomerDetails: provideIf(
      [],
      GiftCardCtx,
      ConditionCtx,
      ['customer_name'],
      R.compose(R.not, R.isNil)
    ),
    CustomerName: consume(['customer_name'], GiftCardCtx),
    CustomerEmail: consume(['customer_email'], GiftCardCtx),
    CustomerPhone: consume(['customer_phone'], GiftCardCtx),
  },
}
