import channelDetails from './channel-info'
import { channelByIDApiGqlCall, channelApiGqlCall } from './utils'
import { themeFromChannelConfig } from './theme'

export const loadChannelByIDAndPermalink = (channel_id, integration_id) =>
  channelApiGqlCall('channel', channelDetails.byIDAndIntegration, {
    channel_id,
    integration_id,
    settingsType: 'web5',
  })

export async function loadChannelFromRequest(req, _deps) {
  const channel_id = req.body['channel_id']
  if (channel_id) {
    // console.info(`Loading channel ${channel_id}...`)
    req.templateParams.channel = await loadChannelByID(channel_id)
    req.templateParams.theme = themeFromChannelConfig(req.templateParams.channel)
    // console.info(`...loaded channel ${req.templateParams.channel.name}`)
    return req.templateParams.channel
  }
}

export default (req, _res, next) => {
  const channel_id = req.body['channel_id']
  if (channel_id) {
    console.info(`Loading channel ${channel_id}...`)
    loadChannelByIDAndPermalink(channel_id, 0)
      .then(data => {
        console.info(`...loaded channel ${data.name} ${data.permalink}`)
        req.templateParams.channel = data
        req.templateParams.theme = themeFromChannelConfig(data)
        next()
      })
      .catch(error => {
        console.error(`Load channel ${channel_id} error`, error)
        next(JSON.stringify(error, null, 2))
      })
  } else {
    next()
  }
}

export const loadChannelByID = channel_id =>
  channelByIDApiGqlCall('channel', channelDetails.byID, {
    channel_id: Number(channel_id),
    settingsType: 'web5',
  })
