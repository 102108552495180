import React, { useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ShowTemplateCategoriesDetails } from './categories'
import { TextTemplateType } from '../models'
import { useTextTemplatesSvc } from '../svc'

export function AddNewTemplateForm(props: {
  refresh: () => Promise<number>
  templateTypes: TextTemplateType[]
}): JSX.Element {
  const api = useTextTemplatesSvc()
  const [name, setName] = useState<string>('')
  const [error, setError] = useState<null | string>(null)
  const [category, setCategory] = useState<null | number>(null)
  const { type } = useParams()
  const navigate = useNavigate()
  const onCancel = (): void => navigate(`/${type}`)
  const regex = /\S/ //regex for non-empty strings

  useMemo(async () => {
    const res = await api.searchTemplates(type as string)
    if (!res.length) setError('Invalid Url')
    else setCategory(res[0].category)
  }, [type])

  if (!!error) return <div className='text-error'>{error}</div>

  const onSave = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    api
      .saveTemplate({
        name: name.trim(),
        type: type?.trim(),
        default: false,
        category: category,
      })
      .then(props.refresh)
      .then(() => navigate(`/${type}`))
      .catch(err => alert(JSON.stringify(err)))
  }

  return (
    <>
      <h4>Add new Template:</h4>
      <ul className='breadcrumb'>
        <li>
          <Link to={'/'}>All Types</Link>
          <span className='divider'>/</span>
        </li>
        <li className='active'>{type}</li>
      </ul>
      <form onSubmit={onSave}>
        <div className='row-fluid'>
          <label htmlFor='template_type'>Template Type:</label>
          <input type='text' id='template_type' value={type} readOnly={true} />
          <label htmlFor='template_name'>Template Name:</label>
          <input
            type='text'
            id='template_name'
            placeholder={'template name...'}
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />
        </div>
        <ShowTemplateCategoriesDetails
          templateType={props.templateTypes.find(i => i.type == type)}
        />
        <div className='btn-group'>
          <button className='btn btn-success btn-small' disabled={!regex.test(name)}>
            Save
          </button>
          <button className='btn btn-small' onClick={onCancel}>
            Cancel
          </button>
        </div>
      </form>
    </>
  )
}

export function CreateTemplateTypeForm(props: { refresh: () => Promise<number> }): JSX.Element {
  const api = useTextTemplatesSvc()
  const [type, setType] = useState('')
  const [name, setName] = useState('')
  const navigate = useNavigate()
  const onClose = (): void => navigate(`/`)
  const regex = /\S/ //regex for non-empty strings
  const onSave = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    api
      .saveTemplate({
        name: name.trim(),
        type: type.trim(),
        default: true,
      })
      .then(props.refresh)
      .then(() => navigate(`/${type}`))
      .catch(err => alert(JSON.stringify(err)))
  }
  return (
    <form onSubmit={onSave}>
      <div className='row-fluid'>
        <h4>Create Template Type:</h4>
        <label htmlFor='template_type'>Template Type:</label>
        <input
          type='text'
          id='template_type'
          value={type}
          placeholder={'template type...'}
          onChange={e => setType(e.target.value)}
          required
        />
        <label htmlFor='template_name'>
          Default Template Name:
          <p className='text-info'>
            <em>IMP: This Template will the default template for the new type</em>
          </p>
        </label>
        <input
          type='text'
          id='template_name'
          placeholder={'template name...'}
          value={name}
          onChange={e => setName(e.target.value)}
          required
        />
      </div>

      <div className='btn-group'>
        <button
          className='btn btn-success btn-small'
          type='submit'
          disabled={!regex.test(name) || !regex.test(type)}>
          Save
        </button>
        <button className='btn btn-small' onClick={onClose}>
          Cancel
        </button>
      </div>
    </form>
  )
}
